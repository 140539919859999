import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, tap} from 'rxjs/operators';
import {ApplicantsFacade} from '../../../../modules/admin-features/components/applicants/applicants.facade';
import {BehaviorSubject, of, Subscription} from 'rxjs';
import {State} from '../../../services/states';

export interface County {
  countyFIPS: string;
  countyName: string;
  state: {
    stateName: string;
    abbreviation: string;
  };
}

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: [
    // This is a hack to get the dropdown to be styled correctly,
    // in the future we should implement our own dropdown component
    '../../boomer-forms/boomer-text-input/boomer-text-input.component.scss'
  ]
})
export class AddressFormComponent implements OnInit, OnDestroy {

  @Input()
  public form: UntypedFormGroup;

  public counties: County[] = [];
  public states: State[] = [];

  private subscriptions = new Subscription();

  public isLoadingCounties = false;
  public loadingCountiesAndStatesEmitter$ = new BehaviorSubject<boolean>(this.isLoadingCounties);

  @Input()
  label: string;

  constructor(private applicantsFacade: ApplicantsFacade) {
  }

  ngOnInit(): void {
    this.registerZipCodeHandler();
    this.registerFipsNameHandler();
    this.registerCountyHandler();
  }

  ngOnDestroy(): void {
  }

  setZipControlErrors(errors) {
    const zipControl = this.form.get('zipCode');
    zipControl.setErrors(errors);
    zipControl.markAsTouched();
    this.form.updateValueAndValidity();
  }

  registerCountyHandler() {
    const stateControl = this.form.get('state');
    const subscription = this.form.get('countyName').valueChanges.pipe(
      tap(v => {
        if (!v) {
          this.states = [];
          stateControl.patchValue(null);
        }
      }),
      filter(v => v),
    )
      .subscribe(countyName => {
        const states = [];
        for (const county of this.counties) {
          if (county.countyName === countyName) {
            states.push({abbreviation: county.state.abbreviation, stateName: county.state.stateName});
          }
        }
        this.states = states;

        if (states.length === 1) {
          stateControl.patchValue(states[0].stateName);
        }
      });
  }

  registerZipCodeHandler() {
    const zipControl = this.form.get('zipCode');
    const countyNameControl = this.form.get('countyName');
    const countyFipsControl = this.form.get('fipsCode');
    const subscription = zipControl.valueChanges
      .pipe(
        debounceTime(500),
        tap(v => {
          if (!v) {
            countyNameControl.patchValue(null);
            countyFipsControl.patchValue(null);
          }
        }),
        filter(v => v),
        distinctUntilChanged(),
        tap(() => {
          this.setZipControlErrors(null);
          this.counties = [];
          this.isLoadingCounties = true;
          this.loadingCountiesAndStatesEmitter$.next(this.isLoadingCounties);
        }),
        switchMap(zip => this.applicantsFacade.getCountyByZipCode(zip)
          .pipe(
            catchError(() => {
              this.setZipControlErrors({invalidZipCode: true});
              return of(null);
            })
          )),
      )
      .subscribe(counties => {
        this.isLoadingCounties = false;
        this.loadingCountiesAndStatesEmitter$.next(this.isLoadingCounties);
        if (counties === null) {
          return;
        }

        this.counties = counties;
        const currentCountyName = countyNameControl.value;
        const currentCountyFips = countyFipsControl.value;

        const currentCounty =
          counties.find(({countyFIPS, countyName}) =>
            countyName === currentCountyName &&
            countyFIPS === currentCountyFips
          );

        console.log('curr', {currentCounty, currentCountyFips, currentCountyName});

        if (counties.length === 1) {
          countyNameControl.patchValue(counties[0].countyName);

          return;
        } else if (currentCounty) {
          countyNameControl.patchValue(currentCounty.countyName);
          return;
        }

        countyNameControl.patchValue('');
      });

    this.subscriptions.add(subscription);
  }

  registerFipsNameHandler() {
    const fipsControl = this.form.get('fipsCode');
    const subscription = this.form.get('countyName').valueChanges
      .pipe(
        filter(() => !this.isLoadingCounties),
        map(name => this.counties.find(county => county.countyName === name)),
        filter(value => !!value)
      )
      .subscribe(county => {
        const fips = county
          ? county.countyFIPS
          : '';
        fipsControl.patchValue(fips);
      });

    this.subscriptions.add(subscription);
  }
}
