import { Component } from '@angular/core';
import {SharedModule} from '../../../../shared/shared.module';

@Component({
  selector: 'app-applicant-soa-form',
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: './applicant-soa-form.component.html',
  styleUrl: './applicant-soa-form.component.scss'
})
export class ApplicantSoaFormComponent {

}
