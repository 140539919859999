<span [formGroup]="form">
  <mat-form-field appearance="outline">
    <mat-label [class.disabled]="isDisabled">{{ label }}</mat-label>
    <input matInput
           [matDatepicker]="picker"
           [formControlName]="formControlName"
           [matDatepickerFilter]="filter || null">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-error>
      <app-boomer-forms-errors [control]="formControl"></app-boomer-forms-errors>
    </mat-error>
  </mat-form-field>
</span>
