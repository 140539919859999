import { Component } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { map, mergeMap, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-referral-page',
  templateUrl: './referral-page.component.html',
  styleUrls: ['../entry-forms/entry-forms.scss']
})
export class ReferralPageComponent {

  referralToken: string = null;
  referralCompanyName$;

  constructor(private auth: AuthService,
              private route: ActivatedRoute) {
      this.referralCompanyName$ = this.route.paramMap.pipe(
        map((paramMap: ParamMap) => {
          this.referralToken = paramMap.get('id');
          return this.referralToken;
        }),
        switchMap((referralToken: string) => this.auth.getReferringCompanyName(referralToken)),
      );
  }

}
