import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {of, Subscription, timer} from 'rxjs';
import {FormBuilder, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {debounce, filter, map, switchMap} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {PlansFacade} from '../selected-plans/plans.facade';
import {Moment} from 'moment';
import {BoomerFormsModule} from '../../../../../shared/components/boomer-forms/boomer-forms.module';
import {coverageStartDateFilter} from 'src/app/shared/date-filters';
import {
  SelectedPlansWillClearWarningDialogComponent
} from '../../../../../shared/components/selected-plans-will-clear-warning-dialog/selected-plans-will-clear-warning-dialog.component';
import {ConnectureQuestionsService} from '../../../services/connecture-questions.service';

@Component({
  selector: 'app-applicant-coverage-start-field',
  templateUrl: './applicant-coverage-start-field.component.html',
  styleUrls: ['./applicant-coverage-start-field.component.scss'],
  imports: [
    BoomerFormsModule,
    ReactiveFormsModule
  ],
  standalone: true
})
export class ApplicantCoverageStartFieldComponent implements OnInit, OnDestroy {
  private readonly formBuilder = inject(FormBuilder);
  private readonly dialog = inject(MatDialog);
  private readonly plansFacade = inject(PlansFacade);
  private readonly connectureQuestionsService = inject(ConnectureQuestionsService);

  private subscription = new Subscription();

  coverageStartForm: FormGroup;

  coverageStartDateFilter = coverageStartDateFilter;

  /**
   * Used to reset the field to the previous value if the user clicks cancel in the confirmation dialog
   * @private
   */
  private initialDate: Moment | null = null;

  constructor() {
    this.coverageStartForm = this.formBuilder.group({
      coverageStart: [null]
    });
  }

  ngOnInit(): void {
    this.subscription.add(
      this.connectureQuestionsService.getConnectureAnswers()
        .subscribe(answers => {
            const control = this.coverageStartForm.get('coverageStart');
            if (control.value === null) {
              control.patchValue(answers.effectiveDate, {emitEvent: false});
              this.initialDate = answers.effectiveDate;
            }
          }
        )
    );
    this.subscription.add(
      this.coverageStartForm.get('coverageStart').valueChanges
        .pipe(
          filter((date) => date && coverageStartDateFilter(date)),
          switchMap((date) => {
              if (!this.initialDate || this.initialDate.year() === date.year()) {
                this.initialDate = date;
                return of(date);
              }

              return this.dialog.open(SelectedPlansWillClearWarningDialogComponent)
                .afterClosed()
                .pipe(
                  switchMap((res) => {
                    if (res === true) {
                      this.initialDate = date;
                      return this.plansFacade.removeSelectedPlans()
                        .pipe(
                          switchMap(() =>
                            of(date)
                          )
                        );
                    }
                    this.coverageStartForm.get('coverageStart').reset(this.initialDate, {emitEvent: false});
                    return of(null);
                  }),
                );
            }
          ),
          debounce(() => timer(100)),
          filter(date => date !== null),
          map(date => date.format('yyyy-MM-DD')),
          switchMap(answer => this.connectureQuestionsService.submitConnectureQuestions([{
            questionName: 'COVERAGE_BEGIN',
            answer
          }]))
        )
        .subscribe(() => {
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


}
