import {SortDirection as AngularSortDirection} from '@angular/material/sort';

export type SortDirection = 'ASC' | 'DESC';

export const sortDirectionFromAngularSortDirection = (sortDirection: AngularSortDirection): SortDirection =>
  sortDirection === 'asc' ? 'ASC' : 'DESC';

export interface IPagination<SortField> {
  page: number;
  pageSize: number;
  sortField?: SortField;
  sortType?: SortDirection;
}
