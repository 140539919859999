import {Injectable} from '@angular/core';
import {Address, Agency, User} from './agency.service';
import {Sort} from '@angular/material/sort';
import {FilterType} from '../../../shared/components/boomer-table/filter-menu/filter-menu.component';
import { PageEvent} from '@angular/material/paginator';
import {BehaviorSubject} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {sortDirectionFromAngularSortDirection} from '../../../shared/types/IPagination';
import {BoomerHttpClient} from '../../../shared/services/boomer-http-client';

export type Agent = {
  uuid: string,
  agencyUUID: string,
  firstName: string,
  lastName: string,
  status: 'ACTIVE' | 'INACTIVE',
  email: string,
  npn: string,
  connectureAgentId: string,
  calendarUrl: string,
  californiaLicenseNo: string,
  mainPhone: string,
  mainFax: string,
  address: Address,
  mailingAddress: Address,
  updatedBy: User,
  updatedAt: string,
  createdBy: User,
  createdAt: string
};

export type AgentListItem = Agent & {
  agencyUUID: never;
  agency: Agency
};

type CreateAgentParams = {
  agencyUUID: string,
  applicantUserUUID: string,
  firstName: string,
  lastName: string,
  email: string,
  npn: string,
  mainFax: string,
};

type UpdateAgentParams = {
  applicantUserUUID: string,
  firstName: string,
  lastName: string,
  email: string,
  status: 'ACTIVE' | 'INACTIVE',
  npn: string,
  mainFax: string,
  californiaLicenseNo: string,
  address: Address,
  mailingAddress: Address,

};

@Injectable({
  providedIn: 'root'
})
export class AgentService {

  refreshAgents = new BehaviorSubject<boolean>(false);

  constructor(private http: BoomerHttpClient) {
  }

  getAgents(sort: Sort, filters: Map<string, FilterType>, page: PageEvent) {
    const sortField = (() => {
      switch (sort.active) {
        case 'agency':
          return 'AGENCY_NAME';
        case 'npn':
          return 'NPN';
        case 'updatedAt':
          return 'UPDATED_AT';
        case 'email':
          return 'EMAIL';
        case 'lastName':
          return 'LAST_NAME';
        case 'firstName':
          return 'FIRST_NAME';
        case 'status':
          return 'STATUS';
        case '':
          return '';
        default:
          throw new Error(`Unknown sort field: ${sort.active}`);
      }
    })();

    const filtersQuery = (() => {
      const query = {};

      for (let [key, value] of filters) {
        if (key === 'agency') {
          key = 'agencyName';
        }
        switch (value.__type) {
          case 'text':
            query[key] = value.value;
            break;
          case 'date':
            query[`${key}From`] = value.from.toISOString();
            query[`${key}To`] = value.to.toISOString();
            break;
          case 'select':
            query[key] = value.value.toUpperCase();
            break;
        }
      }

      return query;
    })();

    return this.refreshAgents
      .pipe(
        switchMap(() =>
          this.http
            .get<AgentListItem[]>(
              `/agent`,
              {
                params: {
                  page: String(page.pageIndex),
                  pageSize: String(page.pageSize),

                  ...filtersQuery,

                  sortField,
                  sortType: sortDirectionFromAngularSortDirection(sort.direction),
                },
                observe: 'response'
              }
            )),
        map(res => {
          const totalCount = +res.headers.get('x-total-elements');
          return {items: res.body, totalCount};
        })
      );
  }

  createAgent(params: CreateAgentParams) {
    return this.http.post(`/agent`, params)
      .pipe(
        tap(() => this.refreshAgents.next(false))
      );
  }

  updateAgent(agentId: string, params: UpdateAgentParams) {
    console.log('params', params);
    return this.http.put(`/agent/${agentId}`, params)
      .pipe(
        tap(() => this.refreshAgents.next(false))
      );
  }

  getAgent(id: string) {
    return this.http.get<Agent>(`/agent/${id}`);
  }

  searchAgents(query: string, agencyId: string = null) {
    const filters = new Map<string, FilterType>();
    if (agencyId) {
      filters.set('agencyUUID', {__type: 'text', value: agencyId});
    }
    filters.set('firstName', {__type: 'text', value: query});
    filters.set('status', {__type: 'select', value: 'ACTIVE'});
    return this.getAgents(
      {active: '', direction: ''},
      filters,
      {pageSize: 10, pageIndex: 0, length: 10}
    )
      .pipe(
        map(res => res.items)
      );
  }

  delete(agentId: string) {
    return this.http.delete(`/agent/${agentId}`)
      .pipe(
        tap(() => this.refreshAgents.next(false))
      );
  }

  getConnectureInfo(id: string) {
    return this.http.get(`/agent/${id}/connecture`);
  }

  getConnectureSellingPermissions(id: string) {
    return this.http.get(`/agent/${id}/connecture/selling-permissions`);
  }
}
