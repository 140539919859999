import {Component, Input, OnInit} from '@angular/core';
import {ceil} from 'lodash';
import {MatIconModule} from '@angular/material/icon';
import {NgForOf, NgIf} from '@angular/common';

@Component({
  selector: 'app-five-star-rating[rating]',
  templateUrl: './five-star-rating.component.html',
  styleUrls: ['./five-star-rating.component.scss'],
  imports: [
    MatIconModule,
    NgIf,
    NgForOf
  ],
  standalone: true
})
export class FiveStarRatingComponent implements OnInit {

  @Input()
  rating: number | null;

  constructor() {
  }

  ngOnInit(): void {
  }

  isHalfStar(planRating, value) {
    let halfStar = false;
    if (!Number.isInteger(planRating)) {
      halfStar = true;
    }
    return ceil(planRating) === value && halfStar;
  }
}
