import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {WizardQuestion} from '../components/connecture-questions/connecture-questions.component';
import {finalize, map, switchMap, tap} from 'rxjs/operators';
import {BoomerHttpClient} from '../../../shared/services/boomer-http-client';
import * as moment from 'moment/moment';
import {Moment} from 'moment/moment';
import {AbstractApplicantProfileService} from './abstract-applicant-profile.service';

export interface InterviewAnswers {
  isSmoker: boolean;
  isSigningSpouse: boolean;
  effectiveDate: Moment;
  partAEffectiveDate: string;
  partBEffectiveDate: string;
  healthStatus: number;
}

export interface SubmitWizardQuestionsResponse {
  additionalQuestions: {
    answerList?: string[];
    answerType: string;
    name: string;
    questionId: number;
    text: string;
  }[];
  connectureSessionId: string;
}

@Injectable()
export class ConnectureQuestionsService {
  private readonly http = inject(BoomerHttpClient);
  private readonly applicantProfileService = inject(AbstractApplicantProfileService);

  private readonly refreshAnswers = new BehaviorSubject(false);
  readonly refreshAnswers$ = this.refreshAnswers.asObservable();


  getConnectureQuestions(): Observable<WizardQuestion[]> {
    return this.applicantProfileService.getApplicantId()
      .pipe(
        switchMap(id => this.http.get<WizardQuestion[]>(`/questions/${id}`))
      );
  }

  submitConnectureQuestions(body): Observable<SubmitWizardQuestionsResponse> {
    return this.applicantProfileService.getApplicantId()
      .pipe(
        switchMap(id => this.http.post<SubmitWizardQuestionsResponse>(
          `/questions/${id}/answer`,
          body
        )),
        finalize(() => {
          this.applicantProfileService.refreshPlans.next(true);
          this.refreshAnswers.next(false);
        })
      );
  }

  getConnectureAnswers(): Observable<InterviewAnswers> {
    return this.refreshAnswers$.pipe(
      switchMap(() => this.applicantProfileService.getApplicantId()),
      switchMap(id => this.http.get<InterviewAnswers>(`/interview-info/applicant/${id}`)),
      map((res) => {
        if (res && res.effectiveDate) {
          return {...res, effectiveDate: moment(res.effectiveDate).local()};
        }
      })
    );
  }
}
