<h2 mat-dialog-title>This Action Will Clear The Cart!</h2>
<mat-dialog-content>
  <p>
    <b>WARNING:</b> this change will clear the Selected Plans. Click OK to continue. Click Cancel to cancel the data
    update.
  </p>
  <div class="flex flex-row !justify-between gap-x-2" mat-dialog-actions>
    <button mat-flat-button (click)="cancel()">Cancel</button>
    <button mat-flat-button color="primary" type="submit" (click)="ok()">Ok</button>
  </div>
</mat-dialog-content>
