import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {inject} from '@angular/core';
import {map} from 'rxjs/operators';
import {AuthRoles} from './auth.roles';
import {applyRuleToApplicationUser, parseRule} from './roleRuleEngine';
import {ApplicationUserService} from '../shared/services/application-user.service';

export const roleRedirectGuard = (route: ActivatedRouteSnapshot,
                                  state: RouterStateSnapshot): Observable<boolean> => {
  const applicationUserService = inject(ApplicationUserService);
  const router = inject(Router);
  return applicationUserService.currentUserProfile$
    .pipe(
      map(profile => {
        if (applyRuleToApplicationUser(parseRule(AuthRoles.ROLE_APPLICANT), profile)) {
          router.navigate(['/applicant']);
        }
        if (applyRuleToApplicationUser(parseRule(AuthRoles.ANY_REFERRING_COMPANY_USER), profile)) {
          router.navigate(['/referring-company']);
        }
        if (applyRuleToApplicationUser(parseRule(AuthRoles.AT_LEAST_BOOMER_EMPLOYEE), profile)) {
          router.navigate(['/admin']);
        }
        if (applyRuleToApplicationUser(parseRule(AuthRoles.ROLE_BOOMER_RESTRICTED), profile)) {
          router.navigate(['/admin/applicants']);
        }
        throw new Error('No role found');
      })
    );
};
