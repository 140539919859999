import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidationErrors, AbstractControl } from '@angular/forms';
import { AuthService } from '../../auth/auth.service';
import { AuthState } from '../../auth/auth.state';
import { Router, ActivatedRoute } from '@angular/router';
import { MustMatch } from '../../helpers/validators/must-match.validator';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['../entry-forms/entry-forms.scss']
})
export class InvitationComponent implements OnInit {

  invitationForm: UntypedFormGroup;
  loading = false;
  invitationId: string = null;
  routeSub: Subscription;

  readonly formErrors = {
    email: () => 'Invalidate email',
    required: () => 'This field is required',
    mustMatch: () => 'Passwords do not match',
    minlength: () => 'Password must be between 6 and 32 characters',
    maxlength: () => 'Password must be between 6 and 32 characters'
};

  constructor(private auth: AuthService,
              private formBuilder: UntypedFormBuilder,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.invitationForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(32)]],
      confirmPassword: ['', Validators.required],
    });

    this.invitationForm.setValidators(MustMatch('password', 'confirmPassword'));
    this.routeSub = this.route.paramMap.subscribe((paramMap) => {
      this.invitationId = paramMap.get('id');
    });
  }

  ngOnDestroy() {
    this.routeSub && this.routeSub.unsubscribe();
  }

  onSubmit(): void {
    if (this.invitationForm.valid) {
      this.loading = true;
      const userData = this.invitationForm.value;
      userData.invitationToken = this.invitationId;
      delete userData.confirmPassword;

      this.auth.createUserFromInvitation(userData).subscribe((res) => {
        this.router.navigate(['/login']);
      }, (err) => {
        this.invitationForm.setErrors({
          serverError: err.error.message
        });
        this.loading = false;
      });
    }
  }

  getErrorMsg(formField: AbstractControl): string {
    const control: AbstractControl = formField;

    if (control.valid || !control.errors) {
        return;
    }

    const controlErrors: ValidationErrors | null = control.errors;
    const firstKey: string = Object.keys(controlErrors)[0];
    const error = this.formErrors[firstKey];

    return error !== undefined ? error(controlErrors[firstKey]) : '';
  }
}
