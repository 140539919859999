import {BehaviorSubject, combineLatest, forkJoin, Observable, Subject, throwError} from 'rxjs';
import {inject, Injectable} from '@angular/core';
import {filter, finalize, map, shareReplay, switchMap, take, tap} from 'rxjs/operators';
import {ApplicationUserService} from '../../../shared/services/application-user.service';
import {BoomerHttpClient} from '../../../shared/services/boomer-http-client';
import {ApplicantProfile} from '../../../auth/auth.service';
import * as moment from 'moment/moment';
import {Moment} from 'moment/moment';
import {HttpHeaders, HttpRequest} from '@angular/common/http';
import {
  ADVANTAGE_PLAN,
  DRUG_PLAN,
  SUPPLEMENT_PLAN
} from '../components/todo/medical-plans-details/medical-plans-details.facade';
import {
  SelectedPlansWillClearWarningDialogComponent
} from '../../../shared/components/selected-plans-will-clear-warning-dialog/selected-plans-will-clear-warning-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {applyRuleToApplicationUser, parseRule} from '../../../auth/roleRuleEngine';
import {AuthRoles} from '../../../auth/auth.roles';

@Injectable()
export abstract class AbstractApplicantProfileService {
  protected readonly http = inject(BoomerHttpClient);
  protected readonly dialog = inject(MatDialog);
  protected readonly applicationUserService = inject(ApplicationUserService);

  // Observable which returns the current applicant Id, should emit a new value when the applicant changes
  // Should be used for data fetching based on the current applicant
  // do not use it for mutations as it will cause them to run every time the applicant changes
  // which can lead to overwriting data
  abstract applicantId$: Subject<string>;

  private readonly loadApplicantProfile: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly applicantProfile$ = this.loadApplicantProfile
    .asObservable()
    .pipe(
      switchMap(() => this.applicantId$),
      switchMap((id) => this.getApplicantProfile(id)),
      shareReplay(1)
    );

  public readonly isReadOnly$: Observable<boolean> = combineLatest([
    this.applicationUserService.currentUserProfile$,
    this.applicantProfile$
  ])
    .pipe(
      map(([applicationUser, currentProfile]) => {
        if (applyRuleToApplicationUser(parseRule(AuthRoles.NOT_APPLICANT), applicationUser)) {
          return false;
        }
        const profile = applicationUser.profiles
          .find(profile => profile.profileUUID === currentProfile.uuid);
        return profile?.accessLevel === 'READ';
      }),
      shareReplay(1)
    );


  public readonly zipCode$ = this.applicantProfile$
    .pipe(
      filter(profile => !!profile.address?.zipCode),
      map(profile => profile.address.zipCode)
    );

  // TODO: Move to it's own service
  refreshPlans = new BehaviorSubject(false);
  refreshPlans$ = this.refreshPlans.asObservable();

  public getApplicantId(): Observable<string> {
    return this.applicantId$
      .pipe(
        take(1)
      );
  }

  private getApplicantProfile(id?: string): Observable<ApplicantProfile> {
    return this.http.get<ApplicantProfile>(`/applicant-user/${id}`)
      .pipe(
        map(applicantData => {
          if (applicantData.birthDate) {
            // honestly this is just bad
            // @ts-ignore
            applicantData.birthDate = moment.utc(applicantData.birthDate);
          }
          applicantData.servicingAgentUuid = applicantData.servicingAgent?.uuid;

          return applicantData;
        })
      );
  }

  updateApplicantProfile(applicantData: ApplicantProfile) {
    if (applicantData.birthDate) {
      const date = moment.isMoment(applicantData.birthDate)
        ? applicantData.birthDate as unknown as Moment
        : moment(applicantData.birthDate);

      applicantData.birthDate = date.format('yyyy-MM-DD');
    }

    const updateProfile$ = this.getApplicantId()
      .pipe(
        switchMap(id => {
          if (applicantData.uuid && id !== applicantData.uuid) {
            throw new Error('Applicant ID mismatch');
          }
          return this.http.put<string>(
            `/applicant-user/${id}`,
            applicantData
          );
        }),
        tap(() => this.refreshProfile()),
        finalize(() => this.refreshPlans.next(true))
      );

    // TODO: Remove this once auth service, applicant service and plans service are refactored
    const removeSelectedPlan = (applicantId: string, planType: number) => this.http.request(new HttpRequest(
        'delete',
        `/applicant/${applicantId}/applicant-selected-plan`,
        planType,
        {headers: new HttpHeaders({'Content-Type': 'application/json'})}
      )
    );

    const updateProfileAndClearPlans$ = updateProfile$
      .pipe(
        switchMap(() => {
          return this.getApplicantId().pipe(
            switchMap(id =>
              forkJoin([
                removeSelectedPlan(id, SUPPLEMENT_PLAN),
                removeSelectedPlan(id, DRUG_PLAN),
                removeSelectedPlan(id, ADVANTAGE_PLAN)
              ])
            ),
          );
        })
      );

    return this.applicantProfile$
      .pipe(
        take(1),
        switchMap(profile => {
          if (!profile.address?.zipCode || profile.address?.zipCode === applicantData.address?.zipCode) {
            return updateProfile$;
          }
          return this.dialog.open<SelectedPlansWillClearWarningDialogComponent, void, boolean>(SelectedPlansWillClearWarningDialogComponent)
            .afterClosed()
            .pipe(switchMap(value => value ? updateProfileAndClearPlans$ : throwError('CANCEL')));
        })
      );
  }


  completeDrugsStep() {
    return this.getApplicantId()
      .pipe(
        switchMap(id => this.http.patch(`/applicant-user/${id}/completed-steps`, {
          drugs: true
        })),
        tap(() => this.refreshProfile())
      );
  }

  public refreshProfile() {
    this.loadApplicantProfile.next(false);
  }

  callMe(content: string) {
    return this.http.post(`/contact-email`, {content});
  }

}
