import {Component, inject, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {map, switchMap} from 'rxjs/operators';
import {AnonymousSignFormRequestResponse, SignFormRequestResponse, SoaService} from '../applicant-soas/soa.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MustMatch} from '../../../helpers/validators/must-match.validator';
import {UrlServiceService} from '../../url-service.service';

const mapSignFormResponseToFormFields = (data: SignFormRequestResponse) => ({
  userInfo: ({
    firstName: data.beneficiaryFirstName,
    lastName: data.beneficiaryLastName,
    email: data.beneficiaryEmail,
    phoneNumber: data.beneficiaryPhone,
    medicareId: data.beneficiaryMedicareId,
    address: data.beneficiaryAddress,
    city: data.beneficiaryCity,
    state: data.beneficiaryState,
    zip: data.beneficiaryZipCode
  }),
  interestedIn: ({
    prescriptionDrugs: true,
    dentalVisionHearingProducts: true,
    hospitalIndemnityProducts: true,
    medicareAdvantage: true,
    medicareSupplement: true
  }),
  signature: ({
    name: '',
    retypeName: ''
  })
});

// I don't like having any here but since this is an internal mapper method
// I think it's ok...
const mapFormFieldsToSignFormRequest = (data: any): SignFormRequestResponse => ({
  beneficiaryAddress: data.userInfo.address,
  beneficiaryCity: data.userInfo.city,
  beneficiaryEmail: data.userInfo.email,
  beneficiaryFirstName: data.userInfo.firstName,
  beneficiaryLastName: data.userInfo.lastName,
  beneficiaryMedicareId: data.userInfo.medicareId,
  beneficiaryPhone: data.userInfo.phoneNumber,
  beneficiaryState: data.userInfo.state,
  beneficiaryZipCode: data.userInfo.zip,
  dentalVisionHearingProducts: data.interestedIn.dentalVisionHearingProducts,
  hospitalIndemnityProducts: data.interestedIn.hospitalIndemnityProducts,
  medicareAdvantage: data.interestedIn.medicareAdvantage,
  medicareSupplement: data.interestedIn.medicareSupplement,
  prescriptionDrugs: data.interestedIn.prescriptionDrugs,
  signature: data.signature.name,
  representativeFirstName: data.userInfo.representativeFirstName,
  representativeLastName: data.userInfo.representativeLastName,
  representativeRelationship: data.userInfo.relationshipStatus
});

// I don't like having any here but since this is a internal mapper method
// I think it's ok...
const mapAnonymousFormFieldsToSignFormRequest = (data: any): AnonymousSignFormRequestResponse => ({
  prescriptionDrugs: data.interestedIn.prescriptionDrugs,
  dentalVisionHearingProducts: data.interestedIn.dentalVisionHearingProducts,
  hospitalIndemnityProducts: data.interestedIn.hospitalIndemnityProducts,
  medicareAdvantage: data.interestedIn.medicareAdvantage,
  medicareSupplement: data.interestedIn.medicareSupplement,

  firstName: data.userInfo.firstName,
  lastName: data.userInfo.lastName,
  email: data.userInfo.email,
  phone: data.userInfo.phoneNumber,

  address: {
    addressLine1: data.userInfo.address.addressLine1,
    addressLine2: data.userInfo.address.addressLine2,
    city: data.userInfo.address.city,
    countyName: data.userInfo.address.countyName,
    fipsCode: data.userInfo.address.fipsCode,
    state: data.userInfo.address.state,
    zipCode: data.userInfo.address.zipCode
  },
  captchaToken: data.signature.captcha,
  signature: data.signature.name,
  representativeFirstName: data.userInfo.representativeFirstName,
  representativeLastName: data.userInfo.representativeLastName,
  representativeRelationship: data.userInfo.relationshipStatus
});

const deduplicateSpaces = (text: string) =>
  text.replace(/\s+/g, ' ');

const removePunctuation = (text: string) =>
  text.replace(/[.,\-]/g, '');

// Sometimes users accidentally enter multiple consecutive spaces
// this function trims the string and removes consecutive spaces
const sanitizeName = (name: string | null) => {
  if (!name) {
    return '';
  }

  name = name.trim();
  name = deduplicateSpaces(name);
  name = removePunctuation(name);

  return name;
};

@Component({
  selector: 'app-soa-form',
  templateUrl: './soa-form.component.html',
  styleUrls: ['./soa-form.component.scss', '../../../components/entry-forms/entry-forms.scss']
})
export class SoaFormComponent implements OnInit {
  private router = inject(Router);
  private formBuilder = inject(UntypedFormBuilder);
  private soaService = inject(SoaService);
  private route = inject(ActivatedRoute);
  private urlService = inject(UrlServiceService);

  @Input()
  isAnonymous: boolean;
  @Input()
  isApplicant: boolean;

  form: UntypedFormGroup;
  signingSucceeded = false;
  isLoading = true;
  isSigning = false;
  soaIsAlreadySigned = false;

  ngOnInit(): void {
    const signatureGroup = this.isAnonymous
      ? this.formBuilder.group({
        name: [null, Validators.required],
        retypeName: [null, Validators.required],
        captcha: [null, Validators.required]
      })
      : this.formBuilder.group({
        name: [null, Validators.required],
        retypeName: [null, Validators.required],
      });
    this.form = this.formBuilder.group({
      userInfo: this.formBuilder.group({
        firstName: [null, Validators.required],
        lastName: [null, Validators.required],
        email: [null, [Validators.required, Validators.required]],
        phoneNumber: [null, Validators.required],
        medicareId: [null],
        address: this.formBuilder.group({
          addressLine1: [null, Validators.required],
          addressLine2: [null],
          city: [null, Validators.required],
          countyName: [null, Validators.required],
          fipsCode: [null, Validators.required],
          state: [null, Validators.required],
          zipCode: [null, Validators.required],
        }),

        isRepresentative: [false],
        representativeFirstName: [null],
        representativeLastName: [null],
        relationshipStatus: [null],
      }),
      interestedIn: this.formBuilder.group({
        prescriptionDrugs: true,
        dentalVisionHearingProducts: true,
        hospitalIndemnityProducts: true,
        medicareAdvantage: true,
        medicareSupplement: true
      }),
      signature: signatureGroup
    });

    this.form.get('signature').setValidators(MustMatch('name', 'retypeName', true));

    this.form.get('userInfo.isRepresentative').valueChanges.subscribe(value => {
      const representativeFirstName = this.form.get('userInfo.representativeFirstName');
      const representativeLastName = this.form.get('userInfo.representativeLastName');
      const relationshipStatus = this.form.get('userInfo.relationshipStatus');
      if (value) {
        representativeFirstName.setValidators([Validators.required]);
        representativeLastName.setValidators([Validators.required]);
        relationshipStatus.setValidators([Validators.required]);
      } else {
        representativeFirstName.clearValidators();
        representativeLastName.clearValidators();
        relationshipStatus.clearValidators();

        representativeFirstName.setValue(null);
        representativeLastName.setValue(null);
        relationshipStatus.setValue(null);
      }
    });
    if (!this.isAnonymous) {
      this.route.params
        .pipe(
          map(params => params.id),
          switchMap(id =>
            this.isApplicant
              ? this.soaService.getApplicantSoaFormData(id)
              : this.soaService.getSignFormData(id)
          ),
          map(mapSignFormResponseToFormFields)
        )
        .subscribe(
          data => {
            this.form.patchValue(data);
            this.isLoading = false;
          },
          () => {
            this.soaIsAlreadySigned = true;
            this.isLoading = false;
          }
        );
    } else {
      this.isLoading = false;
    }
  }

  onSubmit($event) {
    $event.preventDefault();
    this.form.markAllAsTouched();

    if (this.form.value.userInfo.isRepresentative) {
      const representativeFirstName = sanitizeName(this.form.value.userInfo.representativeFirstName);
      const representativeLastName = sanitizeName(this.form.value.userInfo.representativeLastName);
      const representativeName = `${representativeFirstName} ${representativeLastName}`;

      const signature = this.form.value.signature.name;

      if (sanitizeName(signature).toLowerCase() !== sanitizeName(representativeName).toLowerCase()) {
        this.form.get('signature.name').setErrors({signatureNameMismatch: true});
      }
    } else {
      const applicantFirstName = sanitizeName(this.form.value.userInfo.firstName);
      const applicantLastName = sanitizeName(this.form.value.userInfo.lastName);
      const applicantName = `${applicantFirstName} ${applicantLastName}`;

      const signature = this.form.value.signature.name;


      if (sanitizeName(signature).toLowerCase() !== sanitizeName(applicantName).toLowerCase()) {
        this.form.get('signature.name').setErrors({signatureNameMismatch: true});
      }
    }

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      window.scroll(0, 0);
      return;
    }

    if (this.isAnonymous) {
      const data = mapAnonymousFormFieldsToSignFormRequest(this.form.value);
      this.isSigning = true;
      this.soaService.anonymousSign(data).subscribe(({userExists}) => {
        this.router.navigate(
          [this.urlService.anonymousSoaReceived],
          {
            queryParams: {
              userExists,
              data: btoa(JSON.stringify({
                firstName: this.form.value.userInfo.firstName,
                lastName: this.form.value.userInfo.lastName,
                email: this.form.value.userInfo.email,
                phoneNumber: this.form.value.userInfo.phoneNumber,
              }))
            }
          }
        );
      });
    } else {
      const uuid = this.route.snapshot.paramMap.get('id');
      const data = mapFormFieldsToSignFormRequest(this.form.value);
      this.isSigning = true;
      let handler;
      if (uuid === 'current') {
        handler = this.soaService.signCurrentApplicantForm(data);
      } else if (this.isApplicant) {
        handler = this.soaService.signApplicantSoa(uuid, data);
      } else {
        handler = this.soaService.signForm(uuid, data);
      }

      handler
        .subscribe(() => {
          window.scroll(0, 0);
          this.isSigning = false;
          this.signingSucceeded = true;
        });
    }
  }

}
