import { Component } from '@angular/core';

/**
 * In theory users should never see this component rendered,
 * but I had to add it after upgrading to angular v16,
 * otherwise the AuthGuard and roleRedirectionGuard did not trigger
 *
 */
@Component({
  selector: 'app-pre-login-wait',
  templateUrl: './pre-login-wait.component.html',
  styleUrls: ['./pre-login-wait.component.scss']
})
export class PreLoginWaitComponent {

}
