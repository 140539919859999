import {Injectable} from '@angular/core';
import {HttpClient, HttpContext, HttpHeaders, HttpParams, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, switchMap, take} from 'rxjs/operators';

@Injectable()
export class BoomerHttpClient extends HttpClient {
  apiUrl = new BehaviorSubject<string | null>(null);
  apiUrl$ = this.apiUrl.asObservable();

  setApiUrl(apiUrl: string) {
    this.apiUrl.next(apiUrl);
  }

  checkForApiUrl(first: string | HttpRequest<any>, url?: string) {
    const reqUrl = typeof first === 'string' ? url : first.url;
    if (reqUrl.includes('boomerbaby.com')) {
      throw new Error('service is still appending api url manually');
    }
  }

  request(
    first: string | HttpRequest<any>,
    url?: string,
    options: {
      body?: any,
      headers?: HttpHeaders | { [header: string]: string | string[] },
      context?: HttpContext,
      observe?: 'body' | 'events' | 'response',
      params?: HttpParams |
        { [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean> },
      reportProgress?: boolean,
      responseType?: 'arraybuffer' | 'blob' | 'json' | 'text',
      withCredentials?: boolean,
      transferCache?: { includeHeaders?: string[] } | boolean
    } = {}
  ): Observable<any> {
    this.checkForApiUrl(first, url);

    const apiUrl$ = this.apiUrl$
      .pipe(
        filter(apiUrl => apiUrl !== null),
        take(1)
      );

    if (typeof first === 'string') {
      return apiUrl$.pipe(
        switchMap(apiUrl => {
          const urlWithDomain = `${apiUrl}/api${url}`;
          return super.request(first, urlWithDomain, options);
        })
      );
    }

    return apiUrl$.pipe(
      switchMap(apiUrl => {
        const urlWithDomain = `${apiUrl}/api${first.url}`;
        return super.request(first.clone({url: urlWithDomain}));
      })
    );
  }
}
