import {Component, forwardRef, Host, Input, SkipSelf} from '@angular/core';
import {AbstractControl, ControlContainer, ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-boomer-text-input[form]',
  templateUrl: './boomer-text-input.component.html',
  styleUrls: ['./boomer-text-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(
        () => BoomerTextInputComponent
      ),
      multi: true
    }
  ]
})
export class BoomerTextInputComponent implements ControlValueAccessor {

  @Input()
  form: UntypedFormGroup;

  @Input()
  label: string;

  @Input()
  mask: string;

  get required() {
    if (this.formControl && this.formControl.validator) {
      const validator = this.formControl.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }

    return false;
  }

  @Input()
  formControlName: string;

  @Input()
  readonly: boolean;

  get formControl(): UntypedFormControl {
    const path = [...this.parent.path, this.formControlName].join('.');
    return this.form.get(path) as UntypedFormControl;
  }

  public value: string;

  public changed: (value: string) => void;

  public touched: () => void;

  public isDisabled: boolean;

  private parent: ControlContainer;

  @Input()
  icon: string;

  @Input()
  hint: string;

  constructor(@Host() @SkipSelf() parent: ControlContainer) {
    this.parent = parent;
  }

  public writeValue(value: string): void {
    this.value = value;
  }

  public onChange(event: Event): void {
    const value = (event.target as HTMLInputElement).value;

    this.changed(value);
  }

  public registerOnChange(fn: any): void {
    this.changed = fn;
  }

  public registerOnTouched(fn: any): void {
    this.touched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

}
