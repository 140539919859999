import {Moment} from 'moment/moment';
import * as moment from 'moment/moment';

export const coverageStartDateFilter = (date: Moment | null): boolean =>
  date ? firstDayOfMonthFilter(date) && afterNow(date) : true;

export const afterNow = (date: Moment | null): boolean =>
  date ? date.isAfter(moment()) : true;

export const firstDayOfMonthFilter = (date: Moment | null): boolean =>
  date ? date.date() === 1 : true;

