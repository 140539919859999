import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';

import * as Sentry from '@sentry/angular-ivy';
import {BrowserTracing} from '@sentry/angular-ivy';
import {environment} from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if (environment.sentry) {
  Sentry.init({
    dsn: 'https://821953e0ade24ff4907a96435e6f2c84@o1035400.ingest.sentry.io/6002271',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
