<div class="relative">
  <div class="overflow-x-auto">
    <table mat-table [dataSource]="dataSource" matSort>
      <tr *matNoDataRow>
        <td class="h-96" [attr.colspan]="displayedColumns.length">
          @if (dataSource.state$ | async; as state) {
            @switch (state.kind) {
              @case ('loading') {
                <div class="loading-container" *ngIf="dataSource.state$ | async">
                  <mat-spinner [diameter]="50"></mat-spinner>
                </div>
              }
              @case ('loaded') {
                <div class="no-data-cell">
                  Looks like there's nothing here
                </div>
              }
              @case ('error') {
                <div>
                  An error occurred
                  <pre *roleFlag="AuthRoles.ROLE_BOOMER_ADMIN">
                      {{ state.error | json }}
                    </pre>
                </div>
              }
            }
          }
        </td>
      </tr>
      <ng-container *ngFor="let column of columns">

        <ng-container
          [matColumnDef]="column.type === 'compound' ? compoundColumnId(column) : column.key">
          <ng-container *matHeaderCellDef>
            <ng-container *ngFor="let innerColumn of column.type === 'compound' ? column.columns : [column]">
              <ng-container *ngIf="innerColumn.isSortable; else notSortable">
                <th mat-header-cell [mat-sort-header]="innerColumn.key" class="whitespace-nowrap">
                  {{ innerColumn.name }}
                  <ng-container *ngIf="innerColumn.isFilterable">
                    <app-filter-menu [column]="innerColumn"
                                     (filter)="filter(innerColumn.key, $event)"></app-filter-menu>
                  </ng-container>
                </th>
              </ng-container>
              <ng-template #notSortable>
                <th mat-header-cell class="whitespace-nowrap">
                  <div class="flex flex-row items-center">
                    {{ innerColumn.name }}
                    <ng-container *ngIf="innerColumn.isFilterable">
                      <app-filter-menu [column]="innerColumn"
                                       (filter)="filter(innerColumn.key, $event)"></app-filter-menu>
                    </ng-container>
                  </div>
                </th>
              </ng-template>
            </ng-container>
          </ng-container>

          <td mat-cell *matCellDef="let row" [colSpan]="column.type === 'compound' ? column.columns.length : 1">
            <ng-container *ngIf="column.link; else notLink">
              <a [routerLink]="column.link(row)">{{ displayColumn(column, row) }}</a>
            </ng-container>
            <ng-template #notLink>
                <span (click)="onClick(column, row)" [class.clickable]="!!column.clickEvent" class="whitespace-nowrap">
                  {{ displayColumn(column, row) }}
                </span>
            </ng-template>
          </td>
        </ng-container>

      </ng-container>

      <ng-container matColumnDef="select" *ngIf="allowSelection">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions" *ngIf="actions" stickyEnd>
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row" class="whitespace-nowrap">
          <div class="flex flex-row justify-end">
            <ng-container *ngFor="let action of actions">
              <button mat-icon-button
                      (click)="action.action(row)"
                      [matTooltip]="action.name"
                      [disabled]="action.disabled ? action.disabled(row) : false">
                <mat-icon>{{ action.icon }}</mat-icon>
              </button>
            </ng-container>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [style]="rowColorProvider(row)"></tr>
    </table>
  </div>

  <div class="flex flex-row justify-between items-center">
    <mat-paginator [length]="25" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 50]"></mat-paginator>
    <div *ngIf="allowSelection && selection.selected.length !== 0">
      {{ selection.selected.length }}/{{ dataSource.dataSubject.value.length }} selected
    </div>
  </div>
</div>
