<span [formGroup]="form">
  <mat-form-field appearance="outline">
  <mat-label [class.disabled]="isDisabled">{{ label }}</mat-label>
    <input [mask]="mask" matInput type="text" [formControl]="formControl" [readonly]="readonly">
    <mat-error>
      <app-boomer-forms-errors [control]="formControl"></app-boomer-forms-errors>
    </mat-error>
    <mat-hint *ngIf="hint">{{hint}}</mat-hint>
    <mat-icon [class.disabled]="isDisabled" matSuffix *ngIf="icon">{{icon}}</mat-icon>
    <mat-icon [class.disabled]="isDisabled" *ngIf="readonly !== undefined" matSuffix>lock</mat-icon>
  </mat-form-field>
</span>
