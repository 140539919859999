import {Component, forwardRef, Host, Input, SkipSelf} from '@angular/core';
import {AbstractControl, ControlContainer, ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-boomer-forms-checkbox',
  templateUrl: './boomer-forms-checkbox.component.html',
  styleUrls: ['../boomer-text-input/boomer-text-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(
        () => BoomerFormsCheckboxComponent
      ),
      multi: true
    }
  ]
})
export class BoomerFormsCheckboxComponent implements ControlValueAccessor {

  @Input()
  form: UntypedFormGroup;

  @Input()
  label: string;

  get required() {
    if (this.formControl) {
      const validator = this.formControl.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }

    return false;
  }

  @Input()
  formControlName: string;

  get formControl(): UntypedFormControl {
    const path = [...this.parent.path, this.formControlName].join('.');
    return this.form.get(path) as UntypedFormControl;
  }

  public checked: boolean;

  public changed: (checked: boolean) => void;

  public touched: () => void;

  public isDisabled: boolean;

  private parent: ControlContainer;

  constructor(@Host() @SkipSelf() parent: ControlContainer) {
    this.parent = parent;
  }

  public writeValue(value: string): void {
    this.checked = value as unknown as boolean;
  }

  public onChange(event: Event): void {
    const value = (event.target as HTMLInputElement).value;

    this.changed(value as unknown as boolean);
  }

  public registerOnChange(fn: any): void {
    this.changed = fn;
  }

  public registerOnTouched(fn: any): void {
    this.touched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
