import {AfterViewInit, Component, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BoomerAutocompleteFieldComponent} from '../boomer-autocomplete-field/boomer-autocomplete-field.component';
import {Agent, AgentService} from '../../../../modules/admin-features/services/agent.service';
import {Observable} from 'rxjs';
import {debounceTime, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-boomer-agent-field',
  templateUrl: './boomer-agent-field.component.html',
  styleUrls: ['./boomer-agent-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BoomerAgentFieldComponent),
      multi: true
    }
  ]
})
export class BoomerAgentFieldComponent implements ControlValueAccessor {

  constructor(private agentService: AgentService) {
  }

  @Input()
  agencyId: string;

  @Input()
  form: UntypedFormGroup;

  @Input()
  formControlName: string;

  @Input()
  label: string;

  @ViewChild(BoomerAutocompleteFieldComponent, { static: true })
  field: BoomerAutocompleteFieldComponent<string, Agent>;

  private value: any;

  findIdentifierInOption = (option: Agent): string => option.uuid;
  displayOption = (option: Agent) => `${option.firstName} ${option.lastName}(${option.email})`;
  findValueInOptions = (ident: string, options: Agent[]): Agent | null =>
    options.find(option => option.uuid === ident) ?? null
  findValue = (identifier: string): Observable<Agent> =>
    this.agentService.getAgent(identifier)
  onInputChange = (source: Observable<string>): Observable<Agent[]> =>
    source.pipe(
      debounceTime(500),
      switchMap((query) => this.agentService.searchAgents(query, this.agencyId))
    )

  registerOnChange = (fn: any): void => {
    this.field.registerOnChange(fn);
  }

  registerOnTouched = (fn: any): void => {
    this.field.registerOnTouched(fn);
  }

  writeValue = (obj: any): void => {
    this.value = obj;
  }

  setDisabledState = (isDisabled: boolean) => {
    if (!this.field) {
      return;
    }

    this.field.setDisabledState(isDisabled);
  }
}
