import {inject, Injectable} from '@angular/core';
import {addMonths, getMonth} from 'date-fns';
import {differenceInMonths, endOfYear, parseISO} from 'date-fns/esm';
import {DrugFrequency, DrugsService,} from 'src/app/modules/applicant-features/services/drugs.service';
import {PharmaciesService} from 'src/app/modules/applicant-features/services/pharmacies.service';
import {PharmacyType} from '../plan-summary/plan-summary.facade';
import {switchMap, take} from 'rxjs/operators';
import {AbstractApplicantProfileService} from '../../../services/abstract-applicant-profile.service';
import {BoomerHttpClient} from '../../../../../shared/services/boomer-http-client';

@Injectable()
export class PlanDetailsFacade {
  private readonly drugService = inject(DrugsService);
  private readonly pharmaciesService = inject(PharmaciesService);
  private readonly applicantProfileService = inject(AbstractApplicantProfileService);
  private readonly http = inject(BoomerHttpClient);

  async prepareData(
    data: any
  ): Promise<{
    drugSummary: DrugSummary;
    planCostSummary: PlanCostSummary;
    pharmacyDetails: PharmacyDetails;
  }> {
    return {
      drugSummary: await this.prepareDrugSummary(data),
      planCostSummary: this.preparePlanCostSummary(data),
      pharmacyDetails: await this.preparePharmacyDetails(data),
    };
  }

  async prepareDrugSummary(data: any): Promise<DrugSummary> {
    const drugs = await this.drugService.getUserDrugs().pipe(take(1)).toPromise();

    if (data.planDrugCoverage) {
      const parsed = data.planDrugCoverage.map((d) => {
        const {
          hasPriorAuthorization,
          hasQuantityLimit,
          hasStepTherapy,
          tierDescription,
          labelName,
          quantityLimitDescription,
          quantityLimitDays,
        } = d;
        const covered = tierDescription ? true : false;

        return {
          hasPriorAuthorization,
          hasQuantityLimit,
          hasStepTherapy,
          tierDescription,
          covered,
          labelName,
          quantityLimitDescription,
          quantityLimitDays,
        };
      });

      const dataSource = parsed.map((d) => {
        const filteredDrug = drugs.filter(
          (drug) => drug.labelName === d.labelName
        )[0];
        let qty;
        let frequency;
        if (filteredDrug) {
          qty = filteredDrug.quantity;
          frequency = filteredDrug.frequency;
        }
        let parsedFrequency = '';
        if (frequency === DrugFrequency.EVERY_MONTH) {
          parsedFrequency = 'Monthly';
        } else if (frequency === DrugFrequency.EVERY_2_MONTHS) {
          parsedFrequency = 'Every 2 Months';
        } else if (frequency === DrugFrequency.EVERY_3_MONTHS) {
          parsedFrequency = 'Every 3 Months';
        }
        return {
          ...d,
          qty,
          frequency: parsedFrequency,
        };
      });
      return {dataSource};
    }
    return {dataSource: []};
  }

  preparePlanCostSummary(data: any): PlanCostSummary {
    const dataSource = [];
    if (data && data.formularyTiers) {
      data.formularyTiers.map((tier) => {
        const {tierDescription} = tier;
        if (tier.copayPrices) {
          let preferredRetailPharmacy30: { value: string; type: CostType } = {
            value: '',
            type: null,
          };
          let standardRetailPharmacy30: { value: string; type: CostType } = {
            value: '',
            type: null,
          };
          let preferredMailOrderPharmacy90: {
            value: string;
            type: CostType;
          } = {value: '', type: null};
          let standardMailOrderPharmacy90: { value: string; type: CostType } = {
            value: '',
            type: null,
          };
          tier.copayPrices.map((copay) => {
            if (
              copay.isPreferredPharmacy &&
              !copay.isMailOrder &&
              copay.daysOfSupply === 30
            ) {
              preferredRetailPharmacy30 = {
                value: copay.cost,
                type: copay.costType,
              };
            } else if (
              !copay.isPreferredPharmacy &&
              !copay.isMailOrder &&
              copay.daysOfSupply === 30
            ) {
              standardRetailPharmacy30 = {
                value: copay.cost,
                type: copay.costType,
              };
            } else if (
              copay.isPreferredPharmacy &&
              copay.isMailOrder &&
              copay.daysOfSupply === 90
            ) {
              preferredMailOrderPharmacy90 = {
                value: copay.cost,
                type: copay.costType,
              };
            } else if (
              !copay.isPreferredPharmacy &&
              copay.isMailOrder &&
              copay.daysOfSupply === 90
            ) {
              standardMailOrderPharmacy90 = {
                value: copay.cost,
                type: copay.costType,
              };
            }
          });
          dataSource.push({
            tierDescription,
            preferredRetailPharmacy30,
            standardRetailPharmacy30,
            preferredMailOrderPharmacy90,
            standardMailOrderPharmacy90,
          });
        }
      });
      return {
        dataSource,
      };
    }
    return {
      dataSource,
    };
  }

  async preparePharmacyDetails(data) {
    if (data.pharmacyCosts) {
      const pharmacyCosts = data.pharmacyCosts as PharmacyCosts[];
      const pharmacyDetails: PharmacyDetails = {pharmacies: []};

      const drugPremium = data.drugPremium;

      let allWithoutCheapest = [];
      let cheapestPharmacy: PharmacyCosts;
      let parsedCoverageStart = parseISO(data.coverageStart);

      if (isNaN(parsedCoverageStart.getTime())) {
        parsedCoverageStart = data.coverageStart;
      }
      const coverageEnd = endOfYear(parsedCoverageStart);
      const months = differenceInMonths(coverageEnd, parsedCoverageStart);

      let totalCost = 0;
      await Promise.all(
        pharmacyCosts.map(async (pC, index) => {
          const filtered = pC.monthlyCosts.filter(
            (month) => month.monthID <= months
          );

          let coveragePeriodTotal = 0;
          let pharmacyCost = 0;
          let mothlyBreakdowns: EstimatedBreakdown[] = [];
          mothlyBreakdowns = filtered
            .map((monthlyCost) => {
              const oneMonth = this.parseToMonthAndCost(
                parseISO(data.coverageStart),
                monthlyCost.monthID,
                monthlyCost.totalMonthlyCost
              );
              return oneMonth;
            })
            .sort((a, b) => {
              return a.month - b.month;
            })
            .map((m) => {
              return {
                cost: m.cost,
                month: this.parseMonthToString(m.month),
              };
            });
          filtered.map((month) => {
            coveragePeriodTotal += month.totalMonthlyCost;
            if (month.costDetail) {
              month.costDetail.map((c) => {
                pharmacyCost += c.memberCost;
              });
            }
          });
          if (index === 0) {
            totalCost = pharmacyCost;
            cheapestPharmacy = pC;
          }

          if (pharmacyCost < totalCost) {
            totalCost = pharmacyCost;
            cheapestPharmacy = pC;
          }

          let parsedPharmacyType = '';
          const {
            isPreferred,
            isNetwork,
            pharmacyID,
            drugCosts,
            pharmacyType,
          } = pC;
          if (pharmacyType === PharmacyType.RETAIL) {
            parsedPharmacyType = 'Retail';
          } else if (pharmacyType === PharmacyType.MAIL_ORDER) {
            parsedPharmacyType = 'Mail Order';
          } else {
            parsedPharmacyType = pharmacyType;
          }
          const pharmacyData = await this.getPharmacyData(pC.pharmacyID);
          let hitDeductible = '--|--';
          let enterGap = '--|--';
          let exitGap = '--|--';
          filtered.map((m) => {
            if (
              hitDeductible === '--|--' &&
              m.costPhases.includes('DEDUCTIBLE')
            ) {
              const month = getMonth(
                addMonths(parseISO(data.coverageStart), m.monthID)
              );
              hitDeductible = this.parseMonthToString(month);
            } else if (enterGap === '--|--' && m.costPhases.includes('GAP')) {
              const month = getMonth(
                addMonths(parseISO(data.coverageStart), m.monthID)
              );
              enterGap = this.parseMonthToString(month);
            } else if (
              exitGap === '--|--' &&
              m.costPhases.includes('CATASTROPHIC')
            ) {
              const month = getMonth(
                addMonths(parseISO(data.coverageStart), m.monthID)
              );
              exitGap = this.parseMonthToString(month);
            }
          });
          if (pharmacyData) {
            const {address, state, zipCode, city, name} = pharmacyData;
            pharmacyDetails.pharmacies.push({
              address,
              state,
              zipCode,
              city,
              name,
              isPreferred,
              isNetwork,
              pharmacyID,
              drugCosts,
              drugPremium,
              coveragePeriodTotal,
              mothlyBreakdowns,
              hitDeductible,
              enterGap,
              exitGap,
              pharmacyType: parsedPharmacyType,
            });
          } else {
            pharmacyDetails.pharmacies.push({
              address: '',
              state: '',
              zipCode: '',
              city: '',
              name: '',
              isPreferred,
              isNetwork,
              pharmacyID,
              drugCosts,
              drugPremium,
              coveragePeriodTotal,
              mothlyBreakdowns,
              hitDeductible,
              enterGap,
              exitGap,
              pharmacyType: parsedPharmacyType,
            });
          }
        })
      );
      allWithoutCheapest = pharmacyDetails.pharmacies.filter(
        (pharmacy) => pharmacy.pharmacyID !== cheapestPharmacy.pharmacyID
      );

      const cheapest = pharmacyDetails.pharmacies.filter(
        (pharmacy) => pharmacy.pharmacyID === cheapestPharmacy.pharmacyID
      )[0];

      allWithoutCheapest.unshift(cheapest);

      return {pharmacies: allWithoutCheapest};
    }
    return {
      pharmacies: [],
    };
  }

  async getPharmacyData(pharmacyID) {
    const pharmacies = (await this.pharmaciesService
      .getUserPharmacies()
      .toPromise()) as any[];

    const pharmacyData = pharmacies.filter((pharmacy) => {
      return pharmacy.connectureId === pharmacyID;
    })[0];

    return pharmacyData;
  }

  private parseToMonthAndCost(
    coverageStart: Date,
    monthID: number,
    cost: number
  ) {
    const month = getMonth(addMonths(coverageStart, monthID));
    return {month, cost};
  }

  private parseMonthToString(month: number) {
    switch (month) {
      case 0:
        return 'January';
      case 1:
        return 'February';
      case 2:
        return 'March';
      case 3:
        return 'April';
      case 4:
        return 'May';
      case 5:
        return 'June';
      case 6:
        return 'July';
      case 7:
        return 'August';
      case 8:
        return 'September';
      case 9:
        return 'October';
      case 10:
        return 'November';
      case 11:
        return 'December';
    }
  }

  getPlanDetails(planID: string, planType: number) {
    return this.applicantProfileService.getApplicantId().pipe(
      switchMap(id => this.http.get(`/insurance-application/recommended-plans/${id}/plan/${planID}?planType=${planType}`))
    );
  }
}

export interface DrugSummary {
  dataSource: {
    labelName: string;
    covered: boolean;
    tierDescription: string;
    qty: number;
    frequency: string;
    hasPriorAuthorization: boolean;
    hasQuantityLimit: boolean;
    hasStepTherapy: boolean;
    quantityLimitDescription: string;
    quantityLimitDays: string;
  }[];
}

export interface PlanCostSummary {
  dataSource: {
    tierDescription: string;
    preferredRetailPharmacy30: { value: string; type: CostType };
    standardRetailPharmacy30: { value: string; type: CostType };
    preferredMailOrderPharmacy90: { value: string; type: CostType };
    standardMailOrderPharmacy90: { value: string; type: CostType };
  }[];
}

export interface PharmacyDetails {
  pharmacies: PharmacyDetail[];
}

export interface PharmacyDetail {
  name: string;
  address: string;
  state: string;
  zipCode: string;
  city: string;
  isPreferred: boolean;
  isNetwork: boolean;
  pharmacyID: string;
  drugCosts: DrugCost[];
  coveragePeriodTotal: number;
  drugPremium: number;
  mothlyBreakdowns: EstimatedBreakdown[];
  hitDeductible: string;
  enterGap: string;
  exitGap: string;
  pharmacyType: string;
}

export interface PharmacyCosts {
  drugCosts: DrugCost[];
  isPreferred: boolean;
  isNetwork: boolean;
  monthlyCosts: MonthlyCosts[];
  pharmacyType: PharmacyType;
  pharmacyID: string;
  hasCeilingPrice: boolean;
}

export interface DrugCost {
  labelName: string;
  quantity: number;
  fullCost: number;
  deductible: number;
  beforeGap: number;
  gap: number;
  afterGap: number;
}

export interface MonthlyCosts {
  monthID: number;
  costDetail: CostDetail[];
  costPhases: string;
  totalMonthlyCost: number;
}

export interface CostDetail {
  fullCost: number;
  labelName: string;
  memberCost: number;
  phase: string;
}

export interface EstimatedBreakdown {
  month: string;
  cost: number;
}

export enum CostType {
  ONE = 1,
  TWO = 2,
}
