import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { MustMatch } from 'src/app/helpers/validators/must-match.validator';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['../entry-forms.scss']
})
export class ActivateAccountComponent implements OnInit {
  activateForm: UntypedFormGroup;
  subscription: Subscription
  loading = false;
  finished = false;

  readonly formErrors = {
    email: () => 'Invalid email',
    required: () => 'This field is required',
    mustMatch: () => 'Passwords do not match'
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    private snackbar: MatSnackBar,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.subscription = new Subscription();

    this.activateForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      repeatPassword: ['', Validators.required],
    });

    this.subscription.add(
      this.route.queryParamMap
        .pipe(
          map((query: any) =>
            query.params.email
          )
        )
        .subscribe(email =>
          this.activateForm.get('email').patchValue(email)
        )
    );

    this.activateForm.setValidators(MustMatch('password', 'repeatPassword'));

    this.subscription.add( this.activateForm
      .valueChanges
      .pipe(
        map(value => value.userEmail),
        filter((email: string) => /\s/g.test(email))
      )
      .subscribe((notTrimmed: string) => {
        this.activateForm.get('userEmail').setValue(notTrimmed.trim());
        this.activateForm.updateValueAndValidity();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onSubmit(): void {
    if (this.activateForm.valid) {
      this.loading = true;

      this.auth
        .activate(
          this.activateForm.get('email').value,
          this.activateForm.get('password').value
        )
        .subscribe(
          (_) => {
            this.finished = true;
            this.loading = false;
          },
          (err) => {
            if (err?.error) {
              const message =
                err.error.error_description ||
                'An error occurred while activating your account. Please try again';

              const snackbar = this.snackbar.open(`${message}.`, 'OK', {
                duration: 999999,
              });

              this.subscription.add(
                snackbar.onAction()
                  .subscribe(() => {
                    snackbar.dismiss();
                  })
              )
            }

            this.loading = false;
          }
        );
    }
  }

  getErrorMsg(formField: AbstractControl): string {
    const control: AbstractControl = formField;

    if (control.valid || !control.errors) {
      return;
    }

    const controlErrors: ValidationErrors | null = control.errors;
    const firstKey: string = Object.keys(controlErrors)[0];
    const error = this.formErrors[firstKey];

    return error !== undefined ? error(controlErrors[firstKey]) : '';
  }
}
