import {UntypedFormGroup, ValidatorFn, ValidationErrors} from '@angular/forms';

const deduplicateSpaces = (text: string) =>
  text.replace(/\s+/g, ' ');

const removePunctuation = (text: string) =>
  text.replace(/[.,\-]/g, '');

const laxString = (text: string) => {
  text = text.trim();
  text = deduplicateSpaces(text);
  text = removePunctuation(text);

  return text;
};

/**
 * Form controls values must match validator
 * @param controlName first control name
 * @param matchingControlName second control name
 * @param lax if true, case will be ignored
 *            spaces will be collapsed and ',', '.', '-' are ignored
 *            e.g. 'Test' === 't-e.s,t'
 */
export function MustMatch(controlName: string, matchingControlName: string, lax: boolean = false): ValidatorFn {
  return (formGroup: UntypedFormGroup): ValidationErrors => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl && matchingControl.errors && !matchingControl.errors.mustMatch) {
      return;
    }

    if (control && matchingControl) {
      const control1Value = lax
        ? laxString((control.value ?? '').toLowerCase())
        : control.value;
      const control2Value = lax
        ? laxString((matchingControl.value ?? '').toLowerCase())
        : matchingControl.value;
      if (control1Value !== control2Value) {
        matchingControl.setErrors({mustMatch: true});
      } else {
        matchingControl.setErrors(null);
      }
    }
  };
}
