import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-selected-plans-will-clear-warning-dialog',
  templateUrl: './selected-plans-will-clear-warning-dialog.component.html',
  styleUrls: ['./selected-plans-will-clear-warning-dialog.component.scss']
})
export class SelectedPlansWillClearWarningDialogComponent {

  constructor(private dialog: MatDialogRef<SelectedPlansWillClearWarningDialogComponent>) {
  }

  cancel() {
    this.dialog.close(false);
  }

  ok() {
    this.dialog.close(true);
  }
}
