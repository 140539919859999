import { HttpParams } from '@angular/common/http';

/**
 * Converts a given object to http query string
 * @param filters object which should be used to build the query
 */
export const buildQuery = (filters: any): HttpParams => {
  let params = new HttpParams();

  if (!filters || !Object.keys(filters).length) {
    return params;
  }

  for (const [k, v] of Object.entries(filters)) {
    if (Array.isArray(v)) {
      for (const _v of v) {
        params = params.append(k, _v.toString());
      }
    } else if (v instanceof Date) {
      const offset = v.getTimezoneOffset();
      const correctDate = new Date(v.getTime() - offset * 60 * 1000);
      params = params.append(k, correctDate.toISOString().split('T')[0]);
    } else {
      if (v !== null && v !== undefined) {
        params = params.append(k, v.toString());
      }
    }
  }

  return params;
};

export const toFormData = <T>(formValue: T) => {
  const formData = new FormData();

  for (const [k, v] of Object.entries(formValue)) {
    formData.append(k, v);
  }

  return formData;
};

export const chunkArray = (arr: any[], size: number = 2): any[][] => {
  return Array.from(
    { length: Math.ceil(arr.length / size) },
    (_, i) => arr.slice(i * size, i * size + size)
  );
};

// TODO(Patryk Romańczuk): This is like a blackbox, I have no idea how this function works
//                         It'd be good to document/refactor it in the future
export const getSimpleDate = (date: Date | string): string => {
  if (!date) {
    return null;
  }

  if (typeof date === 'string') {
    date = new Date(new Date(date).setHours(0, 0, 0, 0))
  }

  const offset = date.getTimezoneOffset();
  const correctDate = new Date(date.getTime() - offset * 60 * 1000);
  return correctDate.toISOString().split('T')[0];
}
