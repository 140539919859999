import {inject, Injectable} from '@angular/core';
import {forkJoin, Observable, of} from 'rxjs';
import {map, switchMap, withLatestFrom} from 'rxjs/operators';
import {Deductibles, PlansFacade, SelectedMedicalPlanProvider} from '../selected-plans/plans.facade';
import * as moment from 'moment';
import {Moment} from 'moment';
import {getDeductibleFor256} from '../applicant-plans-util';
import {ConnectureQuestionsService} from '../../../services/connecture-questions.service';
import {PlanDetailsFacade} from '../plan-details/plan-details.facade';
import {Deductible} from '../../../../../shared/types/recommended-plans';

export const DRUG_PLAN = 1;
export const ADVANTAGE_PLAN = 2;
export const SUPPLEMENT_PLAN = 256;

export const mapResponseToPlanDetails = ([[res, coverageStart], deductibles]: [[{
  plan: MedicalPlansDetails;
  providers: SelectedMedicalPlanProvider[];
}, Moment], Deductibles]) => {
  const plan = res.plan;
  const coverageEnd = moment(coverageStart).endOf('year');
  const months = coverageEnd.diff(coverageStart, 'months') + 1;

  const providers = res.providers
    ? res.providers.map((provider) => {
      return {
        presentationName: provider.provider.presentationName,
        inNetwork: provider.isInPlan,
      };
    })
    : [];
  const details: PlanDataField[] = plan.planDataFields.map(
    (dataField) => {
      return {
        name: dataField.name.replace('(In Network)', ''),
        description: (dataField.description || '')
          .replace(/<.+?>/g, '')
          .replace(/&+.{4,5};{1}/g, ''),
      };
    }
  );

  // Appends choosing a medigap policy entry to supplement plans if it doesn't already exist
  const brochures: MedicalPlansDetailsBrochures = {
    text: 'More information about the details of the plan and its benefit can be found in these documents:',
    documents: [
      ...(plan.planType === SUPPLEMENT_PLAN &&
      plan.documents.find(document => document.linkName.includes('Choosing a Medigap Policy')) === undefined
        ? [{
          name: 'Choosing a Medigap Policy',
          linkName: 'Choosing a Medigap Policy',
          url: 'https://www.medicare.gov/Pubs/pdf/02110-medicare-medigap-guide.pdf'
        }]
        : []),
      ...(plan.documents || [])
    ],
  };

  let header: MedicalPlansDetailsHeader;

  const {planName, logoURL, planSubType} = plan;

  let logoUrl;
  if (logoURL && logoURL.includes('http')) {
    logoUrl = logoURL;
  } else {
    logoUrl = `https://content.destinationrx.com/ContentServer/DRxProductContent/PlanLogo/${logoURL}`;
  }

  let providerOfficeVisit;
  let providerSpecialtyVisit;
  if (plan.planDataFields && plan.planDataFields.length) {
    const filteredDocumentsOfficeVisit = plan.planDataFields.filter(
      (document) => document.name.includes('Provider Office Visit')
    );

    if (filteredDocumentsOfficeVisit.length === 1) {
      providerOfficeVisit = (
        filteredDocumentsOfficeVisit[0].description || ''
      )
        .replace(/<.+?>/g, '')
        .replace(/&+.{4,5};{1}/g, '');
    } else {
      providerOfficeVisit = 'See brochure';
    }

    const filteredDocumentsSpecialtyVisit = plan.planDataFields.filter(
      (document) => document.name.includes('Provider Specialty Visit')
    );

    if (filteredDocumentsSpecialtyVisit.length === 1) {
      providerSpecialtyVisit = (
        filteredDocumentsSpecialtyVisit[0].description || ''
      )
        .replace(/<.+?>/g, '')
        .replace(/&+.{4,5};{1}/g, '');
    } else {
      providerSpecialtyVisit = 'See brochure';
    }
  }

  let deductible: Deductible;
  if (plan.planType === ADVANTAGE_PLAN) {
    deductible = plan.medicalDeductible;
  } else if (plan.planType === SUPPLEMENT_PLAN) {
    deductible = getDeductibleFor256(plan, coverageStart, deductibles);
  }

  let maximumOutOfPocket;
  let planRating;
  if (plan.planType === ADVANTAGE_PLAN) {
    maximumOutOfPocket = plan.maximumOutOfPocketCost;
    planRating = plan.planRating;
  }

  let planType;
  if (plan.planType === ADVANTAGE_PLAN) {
    planType = 'Medicare Advantage';
  } else if (plan.planType === SUPPLEMENT_PLAN) {
    planType = 'Medicare Supplement';
  }

  let monthlyPremium;
  if (plan.planType === ADVANTAGE_PLAN) {
    monthlyPremium = plan.medicalPremium;
  } else if (plan.planType === SUPPLEMENT_PLAN) {
    monthlyPremium = plan.calculatedMedicalPremium;
  }

  // @ts-ignore
  const drugMonthlyPremium = plan.drugPremium;

  // @ts-ignore
  const drugDeductible = plan.drugDeductible;

  let totalEstimatedCostForCoveragePeriod;

  if (plan.planType === ADVANTAGE_PLAN) {
    totalEstimatedCostForCoveragePeriod =
      plan.estimatedAnnualMedicalCostPartialYear;
  } else if (plan.planType === SUPPLEMENT_PLAN) {
    const premiumForCoveragePeriod =
      plan.calculatedMedicalPremium * months;
    const estimatedOutOfPocketMedicalCost =
      ((plan.estimatedAnnualMedicalCost -
          plan.annualCalculatedPlanPremium) /
        12) *
      months;

    totalEstimatedCostForCoveragePeriod =
      premiumForCoveragePeriod + estimatedOutOfPocketMedicalCost;
  }

  header = {
    planName,
    planType,
    planSubType,
    logoUrl,
    deductible,
    maximumOutOfPocket,
    planRating,
    providerOfficeVisit,
    providerSpecialtyVisit,
    monthlyPremium,
    drugMonthlyPremium,
    drugDeductible,
    totalEstimatedCostForCoveragePeriod,
    coverageStart,
    coverageEnd,
    months,
  };

  return {
    header,
    providers,
    details,
    brochures,
  };
};

@Injectable()
export class MedicalPlansDetailsFacade {
  private readonly planDetailsFacade = inject(PlanDetailsFacade);
  private readonly connectureQuestionsService = inject(ConnectureQuestionsService);
  private readonly plansFacade = inject(PlansFacade);

  getPlanDetails(planId, planType): Observable<MedicalPlansDetailsDto> {
    return this.connectureQuestionsService.getConnectureAnswers().pipe(
      switchMap((answers) => {
        return forkJoin([
          this.planDetailsFacade.getPlanDetails(planId, planType) as Observable<{
            plan: MedicalPlansDetails;
            providers: SelectedMedicalPlanProvider[];
          }>,
          of(answers.effectiveDate),
        ]);
      }),
      withLatestFrom(this.plansFacade.deductibles$),
      map(mapResponseToPlanDetails)
    );
  }
}

export interface MedicalPlansDetailsDto {
  header: MedicalPlansDetailsHeader;
  providers: { presentationName: string; inNetwork: boolean }[];
  // TODO figure how to solve some embedded html
  details: PlanDataField[];
  brochures: MedicalPlansDetailsBrochures;
}

export interface MedicalPlansDetailsHeader {
  planName: string;
  // planType + planSubType
  planType: string;
  planSubType: string;
  providerOfficeVisit: string;
  providerSpecialtyVisit: string;
  deductible: Deductible;
  maximumOutOfPocket: number;
  logoUrl: string;
  planRating: number;
  monthlyPremium: number;
  drugMonthlyPremium: number;
  totalEstimatedCostForCoveragePeriod: number;
  drugDeductible: number;
  coverageStart: Moment;
  coverageEnd: Moment;
  months: number;
}

export interface PlanDataField {
  description: string;
  name: string;
}

export interface MedicalPlansDetailsBrochures {
  text: string;
  // TODO some url can be pdf -> handle
  documents: { linkName: string; name?: string; url: string }[];
}

export interface MedicalPlansDetails {
  planYear: string;
  planDataFields: PlanDataField[];
  logoURL: string;
  planRating: number;
  monthlyPremium: number;
  planName: string;
  planType: number;
  planSubType: string;
  documents: { name?: string; linkName: string; url: string }[];
  medicalDeductible: Deductible;
  maximumOutOfPocketCost: number;
  medicalPremium: number;
  calculatedMedicalPremium: number;
  estimatedAnnualMedicalCostPartialYear: number;
  annualCalculatedPlanPremium: number;
  estimatedAnnualMedicalCost: number;
}
