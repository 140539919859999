import {Component, forwardRef, Input, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormGroup} from '@angular/forms';
import {BoomrAdminService} from '../../../../modules/boomr-admin/boomr-admin.service';
import {BoomerAutocompleteFieldComponent} from '../boomer-autocomplete-field/boomer-autocomplete-field.component';
import {Observable} from 'rxjs';
import {debounceTime, switchMap} from 'rxjs/operators';
import {ApplicationUser} from '../../../services/application-user.service';

@Component({
  selector: 'app-boomer-boomer-user-field',
  templateUrl: './boomer-boomer-user-field.component.html',
  styleUrls: ['./boomer-boomer-user-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BoomerBoomerUserFieldComponent),
      multi: true
    }
  ]
})
export class BoomerBoomerUserFieldComponent implements ControlValueAccessor {

  constructor(private boomerAdminService: BoomrAdminService) {
  }

  @Input()
  form: UntypedFormGroup;

  @Input()
  formControlName: string;

  @Input()
  label: string;

  @ViewChild(BoomerAutocompleteFieldComponent)
  field: BoomerAutocompleteFieldComponent<string, ApplicationUser>;

  private value: any;

  findIdentifierInOption = (option: ApplicationUser): string => option.uuid;
  displayOption = (option: ApplicationUser) => `${option.firstName} ${option.lastName}(${option.email})`;
  findValueInOptions = (ident: string, options: ApplicationUser[]): ApplicationUser | null =>
    options.find(option => option.uuid === ident) ?? null
  findValue = (identifier: string): Observable<ApplicationUser> =>
    this.boomerAdminService.getBoomerUserDetails(identifier)
  onInputChange = (source: Observable<string>): Observable<ApplicationUser[]> =>
    source.pipe(
      debounceTime(500),
      switchMap((query) => this.boomerAdminService.searchBoomerUsers(query))
    )

  registerOnChange = (fn: any): void => {
    this.field.registerOnChange(fn);
  }

  registerOnTouched = (fn: any): void => {
    this.field.registerOnTouched(fn);
  }

  writeValue = (obj: any): void => {
    this.value = obj;
  }

  setDisabledState = (isDisabled: boolean) => {
    this.field.setDisabledState(isDisabled);
  }

}
