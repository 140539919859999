import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../auth/auth.service';
import {  MatCheckboxChange } from '@angular/material/checkbox';
import {  MatRadioChange } from '@angular/material/radio';

interface City {
    value: string;
    viewValue: string;
}


@Component({
  selector: 'app-get-started',
  templateUrl: './get-started.component.html',
  styleUrls: ['./get-started.component.scss']
})

export class GetStartedComponent implements OnInit {

    companyForm: UntypedFormGroup;
    licenseCheckbox = true;

    selectedValue: string;

    cities: City[] = [
      {value: 'Katowice', viewValue: 'Katowice'},
      {value: 'Cracow', viewValue: 'Cracow'},
      {value: 'New York', viewValue: 'New York'}
    ];

    constructor(private auth: AuthService,
                private formBuilder: UntypedFormBuilder) {
    }


    ngOnInit() {
      this.companyForm = this.formBuilder.group({
        companyName: ['', [Validators.required]],
        companyTaxId: ['', [Validators.required]],
        ssn: ['', [Validators.required]],
        licensed: [false, [Validators.required]],
        brokerLicenseNumber: [{value: '', disabled: true}, [Validators.required]],
        enrollmentNotificationEmail: ['', [Validators.required, Validators.email]],
        primaryContactFirstName: ['', Validators.required],
        primaryContactLastName: ['', Validators.required],
        mainPhoneNumber: ['', Validators.required],
        address: ['', Validators.required],
        city: ['', Validators.required],
        state: ['', Validators.required],
        zip: ['', Validators.required]
      });

      this.checkLicenseNumber({source: null, checked: false});
      this.checkNumberOptions({ source: null, value: 'taxID'});
    }

    checkLicenseNumber(event: MatCheckboxChange): void  {
      if (event.checked) {
        this.companyForm.get('brokerLicenseNumber').enable();
      } else {
        this.companyForm.get('brokerLicenseNumber').disable();
      }
    }

    checkNumberOptions(event: MatRadioChange): void {
      if (event.value === 'SSN') {
        this.companyForm.get('ssn').enable();
        this.companyForm.get('companyTaxId').disable();
      }
      else if (event.value === 'taxID') {
        this.companyForm.get('companyTaxId').enable();
        this.companyForm.get('ssn').disable();
      }

    }

    onSubmit(): void {
      console.log('test', this.companyForm.value);
      if (this.companyForm.valid) {

        return;
      }
    }
}
