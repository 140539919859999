<ng-container [formGroup]="form">
  <app-boomer-autocomplete-field [label]="label"
                                 [formControlName]="formControlName"
                                 [onInputChange]="onInputChange"
                                 [findValue]="findValue"
                                 [findValueInOptions]="findValueInOptions"
                                 [findIdentifierInOption]="findIdentifierInOption"
                                 [displayOption]="displayOption"
  ></app-boomer-autocomplete-field>
</ng-container>
