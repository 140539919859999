import {Component, OnInit} from '@angular/core';
import {SoaService} from '../../shared/components/applicant-soas/soa.service';
import {ActivatedRoute, Router} from '@angular/router';
import {filter, map, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-soa-pdf-redirect',
  templateUrl: './soa-pdf-redirect.component.html',
  styleUrls: ['./soa-pdf-redirect.component.scss']
})
export class SoaPdfRedirectComponent implements OnInit {

  constructor(private soaService: SoaService, private router: Router, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap
      .pipe(
        map(params => params.get('id')),
        filter(id => !!id),
        switchMap(id => this.soaService.getPDF(id))
      )
      .subscribe(blob => {
          const fileUrl = URL.createObjectURL(blob);
          window.location.href = fileUrl;
        }
      );
  }

}
