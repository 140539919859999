import {Injectable} from '@angular/core';
import {Soa} from './components/applicant-soas/soa.service';

/**
 * Append planIds to the url if they are present,
 * otherwise return the url as is.
 * planIds might be undefined, to make it easier to use in the component,
 * as we can just do [firstPlanId, secondPlanId] instead of checking if they are defined.
 */
const appendPlanIds = (url: string, planIds?: Array<string | null>) => {
  if (planIds) {
    const params = new URLSearchParams();
    planIds
      .filter(planId => planId !== null)
      .forEach(planId => params.append('planIds', planId));
    return `${url}?${params.toString()}`;
  }
  return url;
};

@Injectable({
  providedIn: 'root'
})
export class UrlServiceService {
  anonymousSoaReceived = '/anonymous-applicant/soa-received';
  getAdminAdvantagePlansTab = (applicantId: string, planIds?: Array<string>) =>
    appendPlanIds(`/admin/applicants/${applicantId}/advantage-plans`, planIds)
  getAdminSupplementPlansTab = (applicantId: string, planIds?: Array<string>) =>
    appendPlanIds(`/admin/applicants/${applicantId}/supplement-plans`, planIds)
  getAdminDrugPlansTab = (applicantId: string, planIds?: Array<string>) =>
    appendPlanIds(`/admin/applicants/${applicantId}/drug-plans`, planIds)
  getSoaSignatureLink = (soa: Soa) => `${location.origin}/soa-request/${soa.uuid}`;
  getApplicantStatusQuestions = () => `/applicant/update-questions/pricing`;
  getApplicantSupplementPlans = (planIds?: Array<string>) =>
    appendPlanIds('/applicant/supplement-plans', planIds)
}
