<span>
  <mat-form-field appearance="outline">
  <mat-label [class.disabled]="isDisabled">{{ label }}</mat-label>
    <input matInput type="text" placeholder="Start Typing to Search" (focus)="onFocus()" [readonly]="readonly" [formControl]="control" [matAutocomplete]="auto"
           [disabled]="isDisabled" [matAutocompleteDisabled]="isDisabled">
    <mat-hint class="mat-mdc-form-field-error">
      <app-boomer-forms-errors [control]="formControl"></app-boomer-forms-errors>
    </mat-hint>
    <mat-hint *ngIf="hint">{{hint}}</mat-hint>
    <mat-icon class="dropdown-icon" matSuffix *ngIf="readonly === undefined">keyboard_arrow_down</mat-icon>
    <mat-icon class="dropdown-icon" matSuffix *ngIf="readonly !== undefined">lock</mat-icon>
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
      <mat-option *ngFor="let option of options$ | async" [value]="option">
        {{displayOption(option)}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
</span>
