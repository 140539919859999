<span [formGroup]="form">
  <mat-form-field appearance="outline">
  <mat-label [class.disabled]="isDisabled">{{ label }}</mat-label>
    <input matInput type="number" [formControl]="formControl" [readonly]="readonly">
    <mat-error>
      <app-boomer-forms-errors [control]="formControl"></app-boomer-forms-errors>
    </mat-error>
    <mat-icon [class.disabled]="isDisabled" matSuffix *ngIf="icon">{{icon}}</mat-icon>
  </mat-form-field>
</span>
