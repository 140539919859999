import {inject, Injectable} from '@angular/core';
import {catchError, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {
  ADVANTAGE_PLAN,
  DRUG_PLAN,
  MedicalPlansDetails,
  SUPPLEMENT_PLAN
} from '../medical-plans-details/medical-plans-details.facade';
import * as moment from 'moment';
import {Moment} from 'moment';
import {BehaviorSubject, forkJoin, of, throwError} from 'rxjs';
import {HttpHeaders, HttpRequest} from '@angular/common/http';
import {getDeductibleFor256} from '../applicant-plans-util';
import {BoomerHttpClient} from '../../../../../shared/services/boomer-http-client';
import {ConnectureQuestionsService} from '../../../services/connecture-questions.service';
import {AbstractApplicantProfileService} from '../../../services/abstract-applicant-profile.service';
import {Deductible} from '../../../../../shared/types/recommended-plans';

const patchDeductible = (plan: SelectedMedicalPlanResponse, effectiveDate: Moment, deductibles: Deductibles) =>
  ({...plan, deductible: getDeductibleFor256(plan as unknown as MedicalPlansDetails, effectiveDate, deductibles)});

const mapSelectedPlansResponseToSelectedPlans = (selectedPlans: SelectedPlansResponse) => {
  let selectedMedicalPlan: SelectedMedicalPlan;
  let medicalLogoUrl;
  if (selectedPlans.selectedMedicalPlan) {
    medicalLogoUrl = selectedPlans.selectedMedicalPlan.logoUrl;
    if (medicalLogoUrl && !medicalLogoUrl.includes('http')) {
      medicalLogoUrl = `https://content.destinationrx.com/ContentServer/DRxProductContent/PlanLogo/${medicalLogoUrl}`;
    }

    let requiresReferralToSpecialist: { value: boolean; match: boolean };
    let includesCoverageForForeignTravel: {
      value: boolean;
      match: boolean;
    };
    let includesCoverageForExcessCharges: {
      value: boolean;
      match: boolean;
    };
    let includesCoverageForVisionAndDental: {
      value: boolean;
      match: boolean;
    };
    let mayLimit: { value: boolean; match: boolean };

    selectedPlans.selectedMedicalPlan.preferences.forEach((pref) => {
      switch (pref.preferenceName) {
        case PreferenceName.EXCESS_CHARGES: {
          includesCoverageForExcessCharges = {
            value: pref.value,
            match: pref.match,
          };
          break;
        }
        case PreferenceName.INTERNATIONAL_COVERAGE: {
          includesCoverageForForeignTravel = {
            value: pref.value,
            match: pref.match,
          };
          break;
        }
        case PreferenceName.LIMITATIONS_OK: {
          mayLimit = {
            value: pref.value,
            match: pref.match,
          };
          break;
        }
        case PreferenceName.REFERRALS_OK: {
          requiresReferralToSpecialist = {
            value: pref.value,
            match: pref.match,
          };
          break;
        }
        case PreferenceName.VISION_AND_HEARING: {
          includesCoverageForVisionAndDental = {
            value: pref.value,
            match: pref.match,
          };
          break;
        }
      }
    });

    selectedMedicalPlan = {
      ...selectedPlans.selectedMedicalPlan,
      logoUrl: medicalLogoUrl,
      requiresReferralToSpecialist,
      includesCoverageForForeignTravel,
      includesCoverageForExcessCharges,
      includesCoverageForVisionAndDental,
      mayLimit,
    };
  }

  let selectedDrugPlan;
  let drugLogoUrl;
  if (selectedPlans.selectedDrugPlan) {
    drugLogoUrl = selectedPlans.selectedDrugPlan.logoUrl;
    if (drugLogoUrl && !drugLogoUrl.includes('http')) {
      drugLogoUrl = `https://content.destinationrx.com/ContentServer/DRxProductContent/PlanLogo/${drugLogoUrl}`;
    }
    selectedDrugPlan = {
      ...selectedPlans.selectedDrugPlan,
      logoUrl: drugLogoUrl,
    };
  }
  return {
    selectedMedicalPlan,
    selectedDrugPlan,
    plansSummary: selectedPlans.plansSummary,
    lastEnrollment: selectedPlans.lastEnrollment,
  };
};

export type SelectedPlansPartial = {
  medicalPlanId?: string,
  medicalPlanType?: typeof ADVANTAGE_PLAN | typeof SUPPLEMENT_PLAN,
  drugPlanId?: string
};

export type Deductibles = Record<string, Record<string, Deductible>>;

@Injectable()
export class PlansFacade {
  private readonly applicantProfileService = inject(AbstractApplicantProfileService);
  private readonly http = inject(BoomerHttpClient);
  private readonly connectureQuestionsService = inject(ConnectureQuestionsService);

  public pc1SupplementPlansListUrl$ = this.getPC1ListUrl('MEDIGAP');
  public pc1AdvantagePlansListUrl$ = this.getPC1ListUrl('MAPD');
  public pc1DrugPlansListUrl$ = this.getPC1ListUrl('PDP');
  private shouldHideMedicarePlans = new BehaviorSubject<boolean | null>(null);
  public shouldHideMedicarePlans$ =
    this.shouldHideMedicarePlans.asObservable()
      .pipe(
        switchMap(shouldHide => shouldHide === null
          ? this.http.get<boolean>(`/global-information/hideMedicare`)
            .pipe(
              tap(shouldHide => this.shouldHideMedicarePlans.next(shouldHide))
            )
          : of(shouldHide)
        )
      );

  public name$ = this.applicantProfileService.applicantProfile$.pipe(
    map((res) => res.firstName)
  );

  private deductibles = new BehaviorSubject<null | Deductibles>(null);
  public deductibles$ = this.deductibles.asObservable()
    .pipe(
      switchMap(deductibles => deductibles
        ? of(deductibles)
        : this.http.get<Deductibles>(`/deductibles`)
          .pipe(
            tap((v) => this.deductibles.next(v))
          ))
    );

  private selectedPlans = new BehaviorSubject<null | SelectedPlansResponse>(null);
  public selectedPlans$ = this.selectedPlans.asObservable()
    .pipe(
      switchMap(selectedPlans => selectedPlans
        ? of(selectedPlans)
        : this.applicantProfileService.getApplicantId()
          .pipe(
            switchMap(id => this.http.get<SelectedPlansResponse>(`/applicant/${id}/applicant-selected-plan`)),
            catchError(err => {
              return throwError(err);
            }),
            withLatestFrom(this.deductibles$),
            map(([plans, deductibles]) => {
              if (plans.plansSummary) {
                plans.plansSummary.effectiveDate = moment(plans.plansSummary.effectiveDate);
              }
              const effectiveDate = moment(plans.plansSummary?.effectiveDate);

              return {
                ...plans,
                plansSummary: {
                  ...plans.plansSummary,
                  effectiveDate
                },
                selectedMedicalPlan: plans.selectedMedicalPlan
                  ? plans.selectedMedicalPlan.planType === 'Medicare Advantage'
                    ? plans.selectedMedicalPlan
                    : patchDeductible(plans.selectedMedicalPlan, effectiveDate, deductibles)
                  : null
              };
            }),
            tap(plans => this.selectedPlans.next(plans))
          )
      ),
      map(mapSelectedPlansResponseToSelectedPlans),
      catchError(err => {
        if (err?.error?.message === 'No selected plans have been found') {
          this.selectedPlansPartial.next({});
        }

        return throwError(() => err);
      }),
      tap(selectedPlans => {
        this.selectedPlansPartial.next({
          medicalPlanId: selectedPlans.selectedMedicalPlan?.medicalPlanConnectureId,
          drugPlanId: selectedPlans.selectedDrugPlan?.drugPlanConnectureId,
          medicalPlanType: selectedPlans.selectedMedicalPlan?.planType === 'Medigap Supplement' ? SUPPLEMENT_PLAN : ADVANTAGE_PLAN
        });
      })
    );

  public isPlanYear2024$ = this.connectureQuestionsService.getConnectureAnswers().pipe(
    map(answers => {
      return answers.effectiveDate.isAfter(moment('2023-12-31'));
    })
  );

  /**
   * A partial selected plans data used to do optimistic updates,
   * it only contains planIds
   */
  private selectedPlansPartial = new BehaviorSubject<SelectedPlansPartial | null>(null);
  public selectedPlansPartial$ = this.selectedPlansPartial.asObservable()
    .pipe(
      switchMap(selectedPlansPartial => selectedPlansPartial
        ? of(selectedPlansPartial)
        : this.applicantProfileService.getApplicantId()
          .pipe(
            switchMap(id => this.http.get<SelectedPlansResponse>(`/applicant/${id}/applicant-selected-plan`)),
            map(selectedPlans => ({
              medicalPlanId: selectedPlans.selectedMedicalPlan?.medicalPlanConnectureId,
              drugPlanId: selectedPlans.selectedDrugPlan?.drugPlanConnectureId,
              medicalPlanType: selectedPlans.selectedMedicalPlan?.planType === 'Medigap Supplement' ? SUPPLEMENT_PLAN : ADVANTAGE_PLAN
            } as const)),
            catchError(() => {
              return of({});
            }),
            tap(plans => this.selectedPlansPartial.next(plans))
          )
      )
    );

  generatePC1ComparisonUrl(planIds: Array<string>) {
    return this.applicantProfileService.getApplicantId()
      .pipe(
        switchMap((id) =>
          this.http.get(
            `/applicant-user/${id}/pc1-plan-compare`,
            {
              params: {planIds},
              responseType: 'text'
            }
          )
        )
      );
  }

  clearSelectedPlans() {
    this.selectedPlans.next(null);
  }

  notStarted(plans: SelectedPlans) {
    return (
      (!plans.selectedDrugPlan ||
        (plans.selectedDrugPlan && !plans.selectedDrugPlan.enrollmentStatus)) &&
      (!plans.selectedMedicalPlan ||
        (plans.selectedMedicalPlan &&
          !plans.selectedMedicalPlan.enrollmentStatus))
    );
  }

  completed(plans: SelectedPlans) {
    return (
      (!plans.selectedDrugPlan ||
        (plans.selectedDrugPlan &&
          plans.selectedDrugPlan.enrollmentStatus ===
          EnrollmentStatus.FINALIZED)) &&
      (!plans.selectedMedicalPlan ||
        (plans.selectedMedicalPlan &&
          plans.selectedMedicalPlan.enrollmentStatus ===
          EnrollmentStatus.FINALIZED))
    );
  }

  hasAnyError(plans: SelectedPlans) {
    if (plans.selectedDrugPlan) {
      if (
        plans.selectedDrugPlan.enrollmentStatus === EnrollmentStatus.REJECTED ||
        plans.selectedDrugPlan.enrollmentStatus === EnrollmentStatus.ERROR
      ) {
        return true;
      }
    }

    if (plans.selectedMedicalPlan) {
      if (
        plans.selectedMedicalPlan.enrollmentStatus ===
        EnrollmentStatus.REJECTED ||
        plans.selectedMedicalPlan.enrollmentStatus === EnrollmentStatus.ERROR
      ) {
        return true;
      }
    }
    return false;
  }

  selectPlan(planType, connecturePlanId, recommended: boolean = false) {
    return this.applicantProfileService.getApplicantId().pipe(
      switchMap(id =>
        this.http.post(`/applicant/${id}/applicant-selected-plan`, {
          planType,
          connecturePlanId,
          recommended
        })
      ),
      switchMap(() => {
        const optimistic: SelectedPlansPartial = planType === ADVANTAGE_PLAN || planType === SUPPLEMENT_PLAN
          ? {medicalPlanId: connecturePlanId, medicalPlanType: planType}
          : {drugPlanId: connecturePlanId};
        this.selectedPlansPartial.next(optimistic);
        this.clearSelectedPlans();
        this.applicantProfileService.refreshPlans.next(false);
        return of(optimistic);
      })
    );
  }

  removeSelectedPlans() {
    return forkJoin([
      this.removeSelectedPlan(SUPPLEMENT_PLAN),
      this.removeSelectedPlan(DRUG_PLAN),
      this.removeSelectedPlan(ADVANTAGE_PLAN)
    ]);
  }

  removeSelectedPlan(planType: typeof SUPPLEMENT_PLAN | typeof DRUG_PLAN | typeof ADVANTAGE_PLAN) {
    const init = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.applicantProfileService.getApplicantId().pipe(
      switchMap(id =>
        this.http.request(new HttpRequest('delete', `/applicant/${id}/applicant-selected-plan`, planType, init))
      ),
      tap(() => this.clearSelectedPlans())
    );
  }

  getPC1ListUrl(type: 'MAPD' | 'MA' | 'PDP' | 'MEDIGAP') {
    return this.connectureQuestionsService.refreshAnswers$
      .pipe(
        switchMap(() => this.applicantProfileService.getApplicantId()),
        switchMap(id =>
          this.http.get(
            `/applicant-user/${id}/pc1-plan-list`,
            {
              params: {type},
              responseType: 'text'
            }
          )
        )
      );
  }

  getPC1DetailsUrl(planId: string) {
    return this.applicantProfileService.getApplicantId()
      .pipe(
        switchMap(id =>
          this.http.get(
            `/applicant-user/${id}/pc1-plan-detail`,
            {
              params: {planId},
              responseType: 'text'
            }
          )
        )
      );
  }

  getDataForEnrollDialog(planId: string, planType: number, sendToClient: boolean) {
    return this.applicantProfileService.getApplicantId()
      .pipe(
        switchMap(id => this.http.post(`/enrollment/applicant/${id}/plan/${planId}?planType=${planType}&sendToClient=${sendToClient}`, {})),
        catchError(e => of(e.error.message))
      );
  }

  sendPlanDetails(to: string[], cc: string[]) {
    return this.applicantProfileService.getApplicantId().pipe(
      switchMap(id =>
        this.http.post(
          `/applicant-user/${id}/send-plan-detail-email`,
          {
            toList: to,
            ccList: cc
          }
        )
      )
    );
  }
}

// From Backend
export interface SelectedPlansResponse {
  lastEnrollment?: { planType: number };
  plansSummary?: PlansSummary;
  selectedMedicalPlan?: SelectedMedicalPlanResponse;
  selectedDrugPlan?: SelectedDrugPlan;
}

export interface SelectedPlans {
  plansSummary?: PlansSummary;
  selectedMedicalPlan?: SelectedMedicalPlan;
  selectedDrugPlan?: SelectedDrugPlan;
  lastEnrollment?: {
    planType: number;
  };
}

export interface PlansSummary {
  monthlyPremium: number;
  monthlyMedicalPremium: number;
  monthlyDrugPremium: number;
  estimatedMedicalCostPremium: number;
  estimatedMedicalCostOutOfPocket: number;
  estimatedDrugCostPremium: number;
  estimatedDrugCostOutOfPocket: number;
  totalEstimatedCostForCoveragePeriod: number;
  effectiveDate: Moment;
  coverageMonths: number;
}

export interface SelectedMedicalPlanResponse {
  medicalPlanConnectureId: string;
  medicalPlanType: typeof ADVANTAGE_PLAN | typeof SUPPLEMENT_PLAN;
  logoUrl: string;
  name: string;
  planType: string;
  planSubType: string;
  providers: {
    num: number;
    total: number;
    providers: SelectedMedicalPlanProvider[];
  };
  // TODO
  deductible: Deductible;
  maximumOutOfPocket: number;
  planRating: number;
  preferences: {
    preferenceName: PreferenceName;
    value: boolean;
    match: boolean;
  }[];
  id: string;
  recommended: boolean;
  enrollmentStatus: EnrollmentStatus;
  compoundId: string;
  detailsUrl: string;
}

export interface SelectedMedicalPlan {
  medicalPlanConnectureId: string;
  medicalPlanType: number;
  logoUrl: string;
  name: string;
  // Ugh...
  planType: number | string;
  planSubType: string;
  providers: {
    num: number;
    total: number;
    providers: SelectedMedicalPlanProvider[];
  };
  deductible: Deductible;
  maximumOutOfPocket: number;
  planRating: number;
  requiresReferralToSpecialist: { value: boolean; match: boolean };
  includesCoverageForForeignTravel: { value: boolean; match: boolean };
  includesCoverageForExcessCharges: { value: boolean; match: boolean };
  includesCoverageForVisionAndDental: { value: boolean; match: boolean };
  mayLimit: { value: boolean; match: boolean };
  id: string;
  recommended: boolean;
  enrollmentStatus: EnrollmentStatus;
  compoundId: string;
  detailsUrl: string;
}

export interface SelectedMedicalPlanProvider {
  isInPlan: boolean;
  provider: {
    id: string;
    npi: number;
    presentationName: string;
    specialty: string;
  };
}

export interface SelectedDrugPlan {
  drugPlanConnectureId: string;
  includedInMedicalPlan: boolean;
  logoUrl: string;
  name: string;
  planType: number;
  planRating: number;
  monthlyPremium: number;
  deductible: number;
  estimatedOutOfPocketDrugCosts: number;
  selectedDrugs: SelectedDrug[];
  recommended: boolean;
  enrollmentStatus: EnrollmentStatus;
  id;
  compoundId: string;
  detailsUrl: string;
}

export interface SelectedDrug {
  tierDescription: string;
  labelName: string;
  isCovered: boolean;
}

export enum EnrollmentStatus {
  STARTED = 'STARTED',
  ERROR = 'ERROR',
  CONNECTURE_COMPLETED = 'CONNECTURE_COMPLETED',
  CONNECTURE_NOT_COMPLETED = 'CONNECTURE_NOT_COMPLETED',
  REJECTED = 'REJECTED',
  FINALIZED = 'FINALIZED',
}

export enum PreferenceName {
  REFERRALS_OK = 'REFERRALS_OK',
  LIMITATIONS_OK = 'LIMITATIONS_OK',
  INTERNATIONAL_COVERAGE = 'INTERNATIONAL_COVERAGE',
  EXCESS_CHARGES = 'EXCESS_CHARGES',
  VISION_AND_HEARING = 'VISION_AND_HEARING',
}
