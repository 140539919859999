import {HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, finalize, map, switchMap, take} from 'rxjs/operators';
import {ApplicantData} from '../admin-features/components/applicants/applicants.component';
import {ApplicantProfileForBoomer} from '../admin-features/components/applicants/applicants.facade';
import {buildQuery} from 'src/app/helpers';
import {Report, ReportDetails} from '../admin-features/components/reports/reports.facade';
import {BoomerUserFilters} from '../admin-features/components/boomer-users/boomer-users.component';
import {Sort} from '@angular/material/sort';
import {FilterType} from '../../shared/components/boomer-table/filter-menu/filter-menu.component';
import {PageEvent} from '@angular/material/paginator';
import {sortDirectionFromAngularSortDirection} from '../../shared/types/IPagination';
import {BoomerHttpClient} from '../../shared/services/boomer-http-client';
import {ApplicationUser} from '../../shared/services/application-user.service';
import {
  EnrollmentDetails
} from '../admin-features/components/enrollments/enrollment-details/enrollment-details.component';
import {Enrollment} from '../admin-features/components/enrollments/enrollments.component';
import {Carrier, CarrierDto, CxCarrier} from '../admin-features/components/carriers/carriers.facade';
import {County} from '../../shared/components/applicant-profile-forms/address-form/address-form.component';

export interface ReferringCompanyData {
  uuid: string;
  companyName: string;
  companyTaxId: string;
  ssn: string;
  licensed: boolean;
  brokerLicenseNumber: string;
  mainPhoneNumber: string;
  primaryContactFirstName: string;
  primaryContactLastName: string;
  enrollmentNotificationEmail: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  medigapReferralBonusAmount: number;
  advantageReferralBonusAmount: number;
  partdReferralBonusAmount: number;
  createdDate: string;
}

export interface BoomerUser extends ApplicationUser {
  phoneNumber: string;
}

@Injectable({providedIn: 'root'})
export class BoomrAdminService {

  boomrGlobalSettings$: Observable<any>;

  refreshCarriers = new BehaviorSubject(false);

  constructor(private http: BoomerHttpClient) {
    this.boomrGlobalSettings$ = this.getBoomrSettings();
  }

  /**
   * @deprecated we no longer can just query all the companies
   * I'll remove this method once every usage of it gets updated
   */
  getAllRefferingCompanies(): Observable<ReferringCompanyData[]> {
    return this.http.get<ReferringCompanyData[]>(
      `/referring-company?page=0&pageSize=100`
    );
  }

  getReferringCompanies(filters: any): Observable<{ items: ReferringCompanyData[]; totalCount: number }> {
    const params = buildQuery(filters);

    return this.http
      .get<ReferringCompanyData[]>(`/referring-company`, {
        params,
        observe: 'response',
      })
      .pipe(
        map((res) => {
          const totalCount = +res.headers.get('x-total-elements');
          return {items: res.body, totalCount};
        })
      );
  }

  getReferringCompanyDetails(uuid: string): Observable<ReferringCompanyData> {
    return this.http.get<ReferringCompanyData>(
      `/referring-company/${uuid}`
    );
  }

  getBoomrSettings(): Observable<any> {
    return this.http.get(`/global-information`).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 404) {
          return of(false);
        }
      })
    );
  }

  saveBoomrSettings(settingsData: any): Observable<any> {
    return this.http.put(`/global-information`, settingsData);
  }

  getEnrollmentDetails(id: number): Observable<EnrollmentDetails> {
    return this.http.get<EnrollmentDetails>(
      `/enrollment/${id}`
    );
  }

  getEnrollmentsCsV(filters) {
    const params = buildQuery(filters);

    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'text/csv;charset=UTF-8',
    });
    const url = `/enrollment/csv`;
    return this.http.get(url, {headers, responseType: 'text', params});
  }

  getEnrollmentsById(
    id: string,
    pageNumber: number,
    pageSize: number
  ): Observable<{ items: Enrollment[]; totalCount: number }> {
    return this.http
      .get<Enrollment[]>(
        `/enrollment/applicant/${id}?page=${pageNumber}&pageSize=${pageSize}`,
        {observe: 'response'}
      )
      .pipe(
        map((res) => {
          const totalCount = +res.headers.get('x-total-elements');
          return {items: res.body, totalCount};
        })
      );
  }

  updateEnrollmentStatus(
    enrollments: {
      id: string;
      status: string;
      rejectionReasonMessage?: string;
    }[]
  ): Observable<any> {
    return this.http.patch<any>(
      `/enrollment/status`,
      {enrollments},
      {observe: 'response'}
    );
  }

  getApplicants(
    sort: Sort, filters: Map<string, FilterType>, page: PageEvent
  ): Observable<{ items: ApplicantData[]; totalCount: number }> {
    const sortField = (() => {
      switch (sort.active) {
        case 'firstName':
          return 'FIRST_NAME';
        case 'lastName':
          return 'LAST_NAME';
        case 'primaryEmail':
          return 'EMAIL';
        case 'primaryPhoneNumber':
          return 'PHONE';
        case 'birthDate':
          return 'DOB';
        case 'accountStatus':
          return 'ACCOUNT_STATUS';
        case 'referringCompanyName':
          return 'REFERRING_COMPANY';
        case 'createdDateTime':
          return 'CREATED_DATE_TIME';
        case 'servicingAgent':
          return 'AGENT_NAME';
        case 'zipCode':
          return 'HOME_ADDRESS_ZIP';
        case 'state':
          return 'HOME_ADDRESS_STATE';
        case 'soaRefreshDate':
          return 'LAST_SIGNED_SOA_SIGNATURE_DATE';
        case '':
          return '';
        default:
          throw new Error(`Unknown sort field: ${sort.active}`);
      }
    })();

    const filtersQuery = (() => {
      const query = {};

      for (const [rawKey, value] of filters) {
        let key = rawKey;
        if (rawKey === 'birthDate') {
          key = 'birthday';
        } else if (rawKey === 'accountStatus') {
          key = 'status';
        } else if (rawKey === 'primaryEmail') {
          key = 'email';
        } else if (rawKey === 'primaryPhoneNumber') {
          key = 'phone';
        } else if (rawKey === 'servicingAgent') {
          key = 'agentUUID';
        } else if (rawKey === 'soaRefreshDate') {
          key = 'lastSignedSoASignatureDate';
        }
        switch (value.__type) {
          case 'select':
          case 'agent':
          case 'text':
            query[key] = value.value;
            break;
          case 'date':
            query[`${key}From`] = key === 'birthday' || key === 'lastSignedSoASignatureDate'
              ? value.from.format('YYYY-MM-DD')
              : value.from.toISOString();
            query[`${key}To`] = key === 'birthday' || key === 'lastSignedSoASignatureDate'
              ? value.to.format('YYYY-MM-DD')
              : value.to.toISOString();
            break;
        }
      }
      return query;
    })();

    return this.http
      .get<ApplicantData[]>(`/applicant-user`, {
        params: {
          page: String(page.pageIndex),
          pageSize: String(page.pageSize),

          ...filtersQuery,

          sortField,
          sortType: sortDirectionFromAngularSortDirection(sort.direction)
        },
        observe: 'response',
      })
      .pipe(
        map((res) => {
          const totalCount = +res.headers.get('x-total-elements');
          return {items: res.body, totalCount};
        })
      );
  }

  getApplicantById(uuid: string): Observable<ApplicantProfileForBoomer> {
    console.log('get applicant by id', uuid);
    return this.http.get<ApplicantProfileForBoomer>(
      `/applicant-user/${uuid}`
    );
  }

  createApplicant(data: any): Observable<any> {
    return this.http.post(
      `/applicant-user/boomer-managed`,
      data
    );
  }

  getReports(
    pageNumber: number,
    pageSize: number
  ): Observable<{ items: Report[]; totalCount: number }> {
    return this.http
      .get<Report[]>(
        `/connecture-daily-report?page=${pageNumber}&pageSize=${pageSize}`,
        {observe: 'response'}
      )
      .pipe(
        map((res) => {
          const totalCount = +res.headers.get('x-total-elements');
          return {items: res.body, totalCount};
        })
      );
  }

  getReportById(id: string): Observable<ReportDetails> {
    return this.http.get<ReportDetails>(
      `/connecture-daily-report/${id}`
    );
  }

  getApplicantNotesById(uuid: string): Observable<any> {
    return this.http
      .get(`/applicant-note/applicant/${uuid}`)
      .pipe(
        map((res: any) =>
          res && res.content
            ? {code: 'FILLED', data: res.content}
            : {code: 'FILLED', data: null}
        ),
        catchError((err: HttpErrorResponse) => {
          if (err.status === 404) {
            return of({code: 'EMPTY', data: null});
          }
        })
      );
  }

  createApplicantNote(uuid: string, text: string): Observable<any> {
    return this.http
      .put(`/applicant-note/applicant/${uuid}`, {
        content: text,
      })
      .pipe(map((_) => true));
  }

  getCountyByZipCode(zipCode: string): Observable<County[]> {
    return this.http.get<County[]>(`/county/${zipCode}`);
  }

  getCarriers(
    sort: Sort, filters: Map<string, FilterType>, page: PageEvent
  ): Observable<{ items: Carrier[]; totalCount: number }> {
    const sortField = (() => {
      switch (sort.active) {
        case 'name':
          return 'NAME';
        case 'connectureName':
          return 'CONNECTURE_NAME';
        case 'connectureId':
          return 'CONNECTURE_ID';
        case 'website':
          return 'WEBSITE';
        case 'showInNetworkProviders':
          return 'SHOW_IN_NETWOR_DOCTORS';
        case '':
          return '';
        default:
          throw new Error(`Unknown sort field: ${sort.active}`);
      }
    })();

    const filtersQuery = (() => {
      const query = {};

      for (const [key, value] of filters) {
        switch (value.__type) {
          case 'select':
          case 'text':
            query[key] = value.value;
            break;
          case 'date':
            query[`${key}From`] = value.from.toISOString();
            query[`${key}To`] = value.to.toISOString();
            break;
          default:
            throw new Error(`Unknown filter type: ${value.__type}`);
        }
      }

      return query;
    })();

    return this.refreshCarriers.pipe(
      switchMap(() => this.http
        .get<Carrier[]>(
          `/carrier`,
          {
            params: {
              page: String(page.pageIndex),
              pageSize: String(page.pageSize),

              ...filtersQuery,

              sortField,
              sortType: sortDirectionFromAngularSortDirection(sort.direction),
            },
            observe: 'response'
          }
        )),
      map((res) => {
        const totalCount = +res.headers.get('x-total-elements');
        return {items: res.body, totalCount};
      })
    );
  }

  searchCarriers(query: string) {
    const filters = new Map<string, FilterType>();
    filters.set('name', {__type: 'text', value: query});
    return this.getCarriers(
      {active: '', direction: ''},
      filters,
      {pageSize: 10, pageIndex: 0, length: 10}
    )
      .pipe(
        map(data => data.items)
      );
  }

  getCarrier(identifier: string): Observable<Carrier> {
    return this.http.get<Carrier>(
      `/carrier/${identifier}`
    );
  }

  deleteCarrier(carrierId: number) {
    return this.http.delete(`/carrier/${carrierId}`)
      .pipe(
        finalize(() => this.refreshCarriers.next(false))
      );
  }

  addCarrier(carrierDto: CarrierDto) {
    return this.http.post(`/carrier`, carrierDto)
      .pipe(
        finalize(() => this.refreshCarriers.next(false))
      );
  }

  editCarrier(carrierDto: CarrierDto, id: number) {
    return this.http.put(`/carrier/${id}`, carrierDto)
      .pipe(
        finalize(() => this.refreshCarriers.next(false))
      );
  }

  getBoomerUsers(filters: BoomerUserFilters) {
    return this.http
      .get<Array<ApplicationUser>>(`/boomer-user-invitation`, {
        params: buildQuery(filters),
        observe: 'response',
      })
      .pipe(map(
        (res) => {
          const totalCount = +res.headers.get('x-total-elements');
          return {items: res.body, totalCount};
        }
      ));
  }

  searchBoomerUsers(query: string) {
    return this.http
      .get<Array<BoomerUser>>(`/boomer-user/search`, {params: {query}});
  }

  inviteBoomerUser(data) {
    return this.http.post(`/boomer-user-invitation`, data);
  }

  getBoomerUserDetails(id) {
    return this.http.get<BoomerUser>(`/boomer-user/${id}`);
  }

  updateBoomerUser(id, value) {
    return this.http.patch(`/boomer-user/${id}`, value);
  }

  resendBoomerUserInvitation(id: string) {
    return this.http.post(`/boomer-user-invitation/${id}/resend`, {});
  }

  unlockApplicant(id: string) {
    return this.http.post(`/applicant-user/${id}/reset-login-counter`, {}, {})
      .pipe(
        take(1)
      );
  }

  sendEnrollmentToAgencyBloc(id: number) {
    return this.http.post(`/enrollment/${id}/send-to-ab`, {}, {});
  }

  getCxCarriers() {
    return this.http.get<Array<CxCarrier>>(`/carrier/connecture`);
  }
}
