<div *ngIf="error$ | async as error; else loading">
  <ng-container [ngSwitch]="error['error_description']">
    <ng-container *ngSwitchCase="'user is blocked'">
      <mat-card class="entry-card mat-card-transparent">
        <h1>Login Failed!</h1>
        <div>
          <div>Your account is blocked</div>
          <div>Please contact us at <a href="https://boomerbaby.com/contact/">boomerbaby.com/contact</a></div>
        </div>
      </mat-card>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <mat-card class="entry-card mat-card-transparent">
        <h1>An Unknown Error Occured!</h1>
        <pre>
          {{error | json}}
        </pre>
        <div>Please contact us at <a href="https://boomerbaby.com/contact/">boomerbaby.com/contact</a></div>
      </mat-card>
    </ng-container>
  </ng-container>
</div>
<ng-template #loading>
  loading...
</ng-template>
