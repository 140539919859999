import {CdkStepperModule} from '@angular/cdk/stepper';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MAT_DATE_LOCALE, MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ConfirmationDialogComponent} from './dialogs/confirmation-dialog/confirmation-dialog.component';
import {FormDialogComponent} from './dialogs/form-dialog/form-dialog.component';
import {SelectModalComponent} from './dialogs/select-modal/select-modal.component';
import {AuthDirective} from './directives/auth.directive';
import {FilterItemDirective} from './directives/filter-item.directive';
import {CdkDetailRowDirective} from './directives/expand-row.directive';
import {FormatDatePipe} from './pipes/formatDate.pipe';
import {EnrollmentItemComponent} from './components/finish-enrollment/enrollment-item/enrollment-item.component';
import {RouterModule} from '@angular/router';
import {
  AdvantagePlanDetailsDisclaimerComponent
} from './components/advantage-plan-details-disclaimer/advantage-plan-details-disclaimer.component';
import {CreateSoaDialogComponent} from './components/applicant-soas/create-soa-dialog/create-soa-dialog.component';
import {ViewSoaDialogComponent} from './components/applicant-soas/view-soa-dialog/view-soa-dialog.component';
import {AddressFormComponent} from './components/applicant-profile-forms/address-form/address-form.component';
import {
  ContactInformationFormComponent
} from './components/applicant-profile-forms/contact-information-form/contact-information-form.component';
import {
  MailingAddressFormComponent
} from './components/applicant-profile-forms/mailing-address-form/mailing-address-form.component';
import {
  PersonalInformationFormComponent
} from './components/applicant-profile-forms/personal-information-form/personal-information-form.component';
import {BoomerFormsModule} from './components/boomer-forms/boomer-forms.module';
import {
  ActivateUserAccountEmailDialogComponent
} from './components/applicant-profile-forms/activate-user-account-email-dialog/activate-user-account-email-dialog.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {PhonePipe} from './pipes/phone.pipe';
import {AccountLockedBannerComponent} from './components/account-locked-banner/account-locked-banner.component';
import {BoomerTableComponent} from './components/boomer-table/boomer-table.component';
import {FilterMenuComponent} from './components/boomer-table/filter-menu/filter-menu.component';
import {SoaFormComponent} from './components/soa-form/soa-form.component';
import {RecaptchaFormsModule, RecaptchaModule} from 'ng-recaptcha';
import {ObjectMetadataComponent} from './components/object-metadata/object-metadata.component';
import {FooterComponent} from './components/footer/footer.component';
import {LogoFullComponent} from './components/logo-full/logo-full.component';
import {TitlebarUserInfoComponent} from './components/titlebar-user-info/titlebar-user-info.component';
import {BoomerToolbarDirective} from './directives/boomer-toolbar.directive';
import {BoomerTitlebarDirective} from './directives/boomer-titlebar.directive';
import {MatTabsModule} from '@angular/material/tabs';
import {ApplicantEnrollmentsComponent} from './components/applicant-enrollments/applicant-enrollments.component';
import {
  SelectedPlansWillClearWarningDialogComponent
} from './components/selected-plans-will-clear-warning-dialog/selected-plans-will-clear-warning-dialog.component';
import {FiveStarRatingComponent} from './components/five-star-rating/five-star-rating.component';
import {FinishEnrollmentComponent} from './components/finish-enrollment/finish-enrollment.component';
import {ErrorComponent} from './components/error/error.component';
import {
  PlanSubTypeInfoDialogComponent
} from './components/plan-sub-type-info-dialog/plan-sub-type-info-dialog.component';
import {
  CreatePlanAnalysisFormDialogComponent
} from './components/create-plan-analysis-form-dialog/create-plan-analysis-form-dialog.component';
import {ShareProfileDialogComponent} from './components/share-profile-dialog/share-profile-dialog.component';
import {
  ApplicantCoverageStartFieldComponent
} from '../modules/applicant-features/components/todo/applicant-coverage-start-field/applicant-coverage-start-field.component';

@NgModule({
  declarations: [
    AccountLockedBannerComponent,
    ApplicantEnrollmentsComponent,
    SelectModalComponent,
    ConfirmationDialogComponent,
    FormDialogComponent,
    FilterItemDirective,
    CdkDetailRowDirective,
    FormatDatePipe,
    EnrollmentItemComponent,
    AdvantagePlanDetailsDisclaimerComponent,
    CreateSoaDialogComponent,
    ViewSoaDialogComponent,
    ActivateUserAccountEmailDialogComponent,
    AddressFormComponent,
    ContactInformationFormComponent,
    MailingAddressFormComponent,
    PersonalInformationFormComponent,
    PhonePipe,
    BoomerTableComponent,
    FilterMenuComponent,
    SoaFormComponent,
    ObjectMetadataComponent,
    FooterComponent,
    LogoFullComponent,
    TitlebarUserInfoComponent,
    BoomerToolbarDirective,
    BoomerTitlebarDirective,
    SelectedPlansWillClearWarningDialogComponent,
    FinishEnrollmentComponent,
    ErrorComponent,
    PlanSubTypeInfoDialogComponent,
    CreatePlanAnalysisFormDialogComponent,
    ShareProfileDialogComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatCheckboxModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatTableModule,
    MatCardModule,
    MatStepperModule,
    CdkStepperModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRippleModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatSortModule,
    BoomerFormsModule,
    MatProgressBarModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    FiveStarRatingComponent,
    AuthDirective,
    ApplicantCoverageStartFieldComponent,
  ],
  exports: [
    ApplicantEnrollmentsComponent,
    MatButtonModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatDividerModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatCheckboxModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatTableModule,
    MatCardModule,
    MatStepperModule,
    CdkStepperModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRippleModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    MatDialogModule,
    SelectModalComponent,
    MatButtonToggleModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatSortModule,
    FilterItemDirective,
    CdkDetailRowDirective,
    FormatDatePipe,
    EnrollmentItemComponent,
    AdvantagePlanDetailsDisclaimerComponent,
    AddressFormComponent,
    ContactInformationFormComponent,
    MailingAddressFormComponent,
    PersonalInformationFormComponent,
    AccountLockedBannerComponent,
    PhonePipe,
    BoomerTableComponent,
    SoaFormComponent,
    ObjectMetadataComponent,
    FooterComponent,
    LogoFullComponent,
    TitlebarUserInfoComponent,
    BoomerToolbarDirective,
    BoomerTitlebarDirective,
    FinishEnrollmentComponent,
    ErrorComponent,
    CreatePlanAnalysisFormDialogComponent,
    ShareProfileDialogComponent
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'en-US'}]
})
export class SharedModule {
}
