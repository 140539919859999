<section class="get-started">
    <h1>New Refferring Company</h1>

    <form (ngSubmit)="onSubmit()" [formGroup]="companyForm" class="get-started__form">
        <div class="get-started__form-group">
            <p>
                <mat-form-field appearance="outline" color="accent">
                  <mat-label>Company name</mat-label>
                  <input autocomplete="organization" formControlName="companyName" matInput placeholder="Broker name">
                </mat-form-field>
            </p>
            <p>

                <mat-radio-group (change)="checkNumberOptions($event)" aria-label="Select an option">
                  <mat-radio-button checked="true" value="taxID">
                    <mat-form-field appearance="outline" color="accent">
                      <mat-label>Company Tax ID</mat-label>
                      <input formControlName="companyTaxId" matInput placeholder="Agency name">
                    </mat-form-field>
                  </mat-radio-button>
                  <mat-radio-button value="SSN">
                    <mat-form-field appearance="outline" color="accent">
                      <mat-label>SSN</mat-label>
                      <input formControlName="ssn" matInput placeholder="Tax ID">
                    </mat-form-field>
                  </mat-radio-button>
                </mat-radio-group>

            </p>
            <p>
                <span class="form-checkbox-input-group">
                  <mat-checkbox formControlName="licensed" (change)="checkLicenseNumber($event)"></mat-checkbox>
                  <mat-form-field appearance="outline" color="accent">
                    <mat-label>License Number</mat-label>
                    <input formControlName="brokerLicenseNumber" matInput placeholder="Address">
                  </mat-form-field>
                </span>
            </p>
            <p>
                <mat-form-field appearance="outline" color="accent">
                  <mat-label>Email</mat-label>
                  <input autocomplete="email" formControlName="enrollmentNotificationEmail" matInput placeholder="Email">
                </mat-form-field>
            </p>
            <p>
              <mat-form-field appearance="outline" color="accent">
                <mat-label>Phone</mat-label>
                <input autocomplete="tel" formControlName="mainPhoneNumber" matInput placeholder="Phone">
              </mat-form-field>
            </p>
        </div>

        <div class="get-started__form-group">
          <p>
            <mat-form-field appearance="outline" color="accent">
              <mat-label>First Name</mat-label>
              <input autocomplete="name" formControlName="primaryContactFirstName" matInput placeholder="First Name">
            </mat-form-field>
          </p>
          <p>
            <mat-form-field appearance="outline" color="accent">
              <mat-label>Last Name</mat-label>
              <input autocomplete="last-name" formControlName="primaryContactLastName" matInput placeholder="Last Name">
            </mat-form-field>
          </p>
            <p>
                <mat-form-field appearance="outline" color="accent">
                  <mat-label>Billing address</mat-label>
                  <input autocomplete="street-address" formControlName="address" matInput placeholder="Billing address">
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline" color="accent">
                    <mat-label>Billing city</mat-label>
                    <!-- <mat-select [(ngModel)]="selectedValue" name="city"> -->
                    <mat-select formControlName="city" name="city">
                      <mat-option *ngFor="let city of cities" [value]="city.value">
                        {{city.viewValue}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
            </p>

            <div class="get-started__subform">
                <div class="get-started__subform-group">
                    <p>
                        <mat-form-field appearance="outline" color="accent">
                            <mat-label>Billing state</mat-label>
                            <!-- <mat-select [(ngModel)]="selectedValue" name="city"> -->
                            <mat-select formControlName="state" name="state">
                            <mat-option *ngFor="let city of cities" [value]="city.value">
                                {{city.viewValue}}
                            </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </p>
                </div>
                <div class="get-started__subform-group">
                    <p>
                        <mat-form-field appearance="outline" color="accent">
                          <mat-label>Billing zip code</mat-label>
                          <input autocomplete="postal-code" formControlName="zip" matInput placeholder="Billing zip code">
                        </mat-form-field>
                    </p>
                </div>
            </div>

            <!-- <p>
                <mat-form-field appearance="outline" color="accent">
                  <mat-label>Credit card number</mat-label>
                  <input matInput placeholder="Credit card number">
                </mat-form-field>
            </p>

            <div class="get-started__subform">
                <div class="get-started__subform-group">
                    <p>
                        <mat-form-field appearance="outline" color="accent">
                          <mat-label>Expiration date</mat-label>
                          <input matInput placeholder="Expiration date">
                        </mat-form-field>
                    </p>
                </div>
                <div class="get-started__subform-group">
                    <p>
                        <mat-form-field appearance="outline" color="accent">
                          <mat-label>Security code</mat-label>
                          <input matInput placeholder="Security code">
                        </mat-form-field>
                    </p>
                </div>
            </div> -->
        </div>

        <button mat-flat-button color="primary">Create company</button>

    </form>
</section>
