<section class="reset-password card-view">
    <div class="card-view__container">
        <h1>Reset password</h1>
        <form (ngSubmit)="onSubmit()" [formGroup]="resetPasswordForm">
          <mat-error >
            <p>
              <span *ngIf="resetPasswordForm.errors?.serverError">
                {{ resetPasswordForm.errors?.serverError }}
              </span>
            </p><br/>
          </mat-error>

          <p>
            <mat-form-field appearance="outline" color="accent">
              <mat-label>New password</mat-label>
              <input autocomplete="new-password" matInput formControlName="newPassword" type="password" placeholder="Type your new password">
              <mat-error *ngIf="resetPasswordForm.get('newPassword').invalid">
                {{ getErrorMsg(resetPasswordForm.get('newPassword')) }}
              </mat-error>
            </mat-form-field>
          </p>
          <p>
            <mat-form-field appearance="outline" color="accent">
              <mat-label>Confirm password</mat-label>
              <input autocomplete="new-password" matInput formControlName="confirmPassword" type="password" placeholder="Confirm your password">
              <mat-error *ngIf="resetPasswordForm.get('confirmPassword').invalid">
                {{ getErrorMsg(resetPasswordForm.get('confirmPassword')) }}
              </mat-error>
            </mat-form-field>
          </p>
          <br/>
          <button [disabled]="loading" mat-flat-button color="primary">
            <mat-spinner *ngIf="loading" [diameter]="37"></mat-spinner>
            <span *ngIf="!loading">Submit</span>
          </button>

          <a [routerLink]="['/login']">Back to login page</a>
        </form>

    </div>
</section>
