import {inject, Injectable, OnInit} from '@angular/core';
import {BoomerHttpClient} from './boomer-http-client';
import {BehaviorSubject, iif, ReplaySubject, Subject} from 'rxjs';
import {switchMap} from 'rxjs/operators';

export type ProfileAccessLevel = 'OWNER' | 'READ' | 'WRITE';

export type ApplicationUser = {
  uuid: string;
  email: string;
  enabled: boolean;
  firstName: string;
  lastName: string;
  roles: Array<string>;
  agentUUID: string;
  profiles: Array<{
    accessLevel: ProfileAccessLevel,
    firstName: string,
    lastName: string,
    email: string,
    profileUUID: string,
  }>;
};

@Injectable({providedIn: 'root'})
export class ApplicationUserService {
  private readonly boomerHttpClient = inject(BoomerHttpClient);
  private readonly currentUserProfile = new ReplaySubject<ApplicationUser>(1);
  public readonly currentUserProfile$ = this.currentUserProfile.asObservable();

  constructor() {
    this.boomerHttpClient.get<ApplicationUser>(`/application-user/me`)
      .subscribe(profile =>
        this.currentUserProfile.next(profile)
      );
  }
}
