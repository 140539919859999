<ng-container *ngIf="soaIsAlreadySigned">
  <mat-card class="entry-card mat-card-transparent">
    <mat-card-content>This SOA Request has already been signed</mat-card-content>
    <mat-card-actions>
      <button mat-flat-button [routerLink]="['/']">Go Back</button>
    </mat-card-actions>
  </mat-card>
</ng-container>

<ng-container *ngIf="signingSucceeded">
  <mat-card class="entry-card mat-card-transparent">
    <mat-card-content>
      <h2>Thank you!</h2>
      <div>A BoomerBaby representative will contact you shortly.</div>
      <div>You may close this page or go back to <a href="https://boomerbaby.com/">BoomerBaby.com</a>.</div>
    </mat-card-content>
  </mat-card>
</ng-container>

<ng-container *ngIf="isLoading">
  <mat-card class="entry-card mat-card-transparent">
    <mat-card-content>
      <mat-spinner [diameter]="37" color="warn"></mat-spinner>
    </mat-card-content>
  </mat-card>
</ng-container>

<form *ngIf="!soaIsAlreadySigned && !signingSucceeded && !isLoading" [formGroup]="form" (ngSubmit)="onSubmit($event)">
  <mat-card class="entry-card mat-card-transparent">
    <div class="text-lg">
      Medicare regulations require you to sign a Scope of Appointment authorization in order for a BoomerBaby
      representative to speak with you regarding your Medicare needs. <br>
      Please complete this form so that we can provide
      assistance.
    </div>
    <div class="text-lg">
      We look forward to being of service.
    </div>
    <mat-divider></mat-divider>
    <span class="text-lg">I am interested in (select all  that apply):</span>
    <div class="flex flex-col" formGroupName="interestedIn">
      <app-boomer-forms-checkbox [form]="form" formControlName="prescriptionDrugs"
                                 label="Prescription Drug Plans (Part D)"></app-boomer-forms-checkbox>
      <app-boomer-forms-checkbox [form]="form" formControlName="dentalVisionHearingProducts"
                                 label="Dental/Vision/Hearing Products"></app-boomer-forms-checkbox>
      <app-boomer-forms-checkbox [form]="form" formControlName="hospitalIndemnityProducts"
                                 label="Hospital Indemnity Products"></app-boomer-forms-checkbox>
      <app-boomer-forms-checkbox [form]="form" formControlName="medicareAdvantage"
                                 label="Medicare Advantage Plans (Part C) and Cost Plans"></app-boomer-forms-checkbox>
      <app-boomer-forms-checkbox [form]="form" formControlName="medicareSupplement"
                                 label="Medicare Supplement (Medigap) Products"></app-boomer-forms-checkbox>
    </div>
    <mat-divider></mat-divider>
    <span class="text-lg">Click <a href="https://boomerbaby.com/glossary-of-terms">here</a> to see a glossary of plans and products</span>
    <mat-divider></mat-divider>

    <ng-container formGroupName="userInfo" [formGroup]="form.get('userInfo')">
      <h2>Personal Information</h2>
      <div class="flex flex-col gap-x-4 @lg:flex-row [&>*]:flex-1">
        <app-boomer-text-input [form]="form"
                               formControlName="firstName"
                               label="First Name"></app-boomer-text-input>

        <app-boomer-text-input [form]="form"
                               formControlName="lastName"
                               label="Last Name"></app-boomer-text-input>
      </div>
      <div class="flex flex-col gap-x-4 @lg:flex-row [&>*]:flex-1">
        <app-boomer-text-input [form]="form"
                               formControlName="email"
                               label="Email"></app-boomer-text-input>
        <app-boomer-phone-input [form]="form"
                                formControlName="phoneNumber"
                                label="Phone"></app-boomer-phone-input>
      </div>

      <app-address-form [form]="form.get('userInfo.address')"
                        label="Home"></app-address-form>

      <app-boomer-text-input [form]="form"
                             formControlName="medicareId"
                             label="Medicare Beneficiary Identifier (Optional)"></app-boomer-text-input>

      <app-boomer-forms-checkbox [form]="form" formControlName="isRepresentative"
                                 label="I am the Authorized Representative"></app-boomer-forms-checkbox>
      <div *ngIf="form.get('userInfo.isRepresentative').value">
        <div class="flex flex-col gap-x-4 @lg:flex-row [&>*]:flex-1">
          <app-boomer-text-input [form]="form" formControlName="representativeFirstName"
                                 label="Representative First Name"></app-boomer-text-input>
          <app-boomer-text-input [form]="form" formControlName="representativeLastName"
                                 label="Representative Last Name"></app-boomer-text-input>
        </div>
        <app-boomer-text-input [form]="form" formControlName="relationshipStatus"
                               label="Relationship Status"></app-boomer-text-input>
      </div>
    </ng-container>

    <mat-divider></mat-divider>
    <ng-container formGroupName="signature">
      <p>
        Signing this form does NOT obligate you to enroll in a plan, affect your current or future enrollment status, or
        automatically enroll you in a Medicare plan.
      </p>
      <p>
        By entering your information below and clicking Submit, you are giving permission to have a licensed sales agent
        contact you by email, telephone or cell phone to provide additional information about products and services.
        Your
        consent is voluntary and allows us to contact you via email, text messaging, artificial or prerecorded voice
        messages, or automatic dialing for marketing purposes. You may contact us to change your preferences at any
        time.
        Data use charges and rates from your cellular carrier may apply.
      </p>
      <p>
        By submitting this form, you agree to be contacted by a licensed sales agent to discuss the types of products
        you
        selected above. Please note, the person who will discuss the products is either employed or contracted by a
        Medicare
        plan. They do not work directly for the Federal government. This individual may also be paid based on your
        enrollment in a plan.
      </p>
      <p>
        The Centers for Medicare and Medicaid Services requires agents to document the scope of a marketing appointment
        prior to any sales meeting to ensure understanding of what will be discussed between the agent and the Medicare
        beneficiary (or their authorized representative). All information provided on this form is confidential and
        should
        be completed by each person with Medicare or his/her authorized representative.
      </p>
      <h3>To electronically sign this form, please type (and retype) your full name in the fields below.</h3>
      <b>Be sure that the signatures exactly match the name of the beneficiary or authorized representative</b>
      <div class="flex flex-col items-center justify-center mt-2">
        <div class="flex flex-col gap-x-4 @lg:flex-row [&>*]:flex-1 self-stretch @2xl:self-center">
          <app-boomer-text-input [form]="form" formControlName="name"
                                 label="Electronic Signature"></app-boomer-text-input>
          <app-boomer-text-input [form]="form" formControlName="retypeName"
                                 label="Confirm Signature"></app-boomer-text-input>
        </div>

        <re-captcha *ngIf="isAnonymous" formControlName="captcha"></re-captcha>

        <button mat-flat-button color="primary" type="submit" class="mt-2">
          <span *ngIf="!isSigning">Sign</span>
          <mat-spinner [diameter]="27" *ngIf="isSigning"></mat-spinner>
        </button>
      </div>
    </ng-container>
  </mat-card>
</form>
