import {Component, Input} from '@angular/core';
import {FormControl, NgControl} from '@angular/forms';

@Component({
  selector: 'app-boomer-forms-errors',
  templateUrl: './boomer-forms-errors.component.html',
  styleUrls: []
})
export class BoomerFormsErrorsComponent {
  @Input()
  control: NgControl;

  get errorMessage(): string {
    const error = Object.keys(this.control.errors)[0];
    switch (error) {
      case 'required':
        return 'This field is required';
      case 'email':
        return 'This email address is invalid';
      case 'emailTaken':
        return 'This email address is already in use';
      case 'invalidZipCode':
        return 'This zip code is invalid';
      case 'signatureNameMismatch':
        return 'Signature must match your or representative name';
      default:
        return `An error occurred: ${error}`;
    }
  }

  constructor() {
  }
}
