@if (rating !== null) {
  <div class="flex flex-row gap-x-2">
    @for (i of [1, 2, 3, 4, 5];track i) {
      <mat-icon class="colored">
        {{ rating >= i || isHalfStar(rating, i) ? (isHalfStar(rating, i) ? "star_half" : "star") : "star_outline" }}
      </mat-icon>
    }
  </div>
} @else {
  n/a
}
