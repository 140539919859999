import {Component, inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {switchMap, tap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {Observable} from 'rxjs';
import {MustMatch} from '../../helpers/validators/must-match.validator';
import {BoomerHttpClient} from '../../shared/services/boomer-http-client';

@Component({
  selector: 'app-boomer-user-invitation',
  templateUrl: './boomer-user-invitation.component.html',
  styleUrls: ['../entry-forms/entry-forms.scss']
})
export class BoomerUserInvitationComponent implements OnInit {
  private readonly http = inject(BoomerHttpClient);

  invitationForm: UntypedFormGroup;
  invitationFlow$: Observable<any>;
  loading = false;
  id = null;
  email: string;

  readonly formErrors = {
    email: () => 'Invalidate email',
    required: () => 'This field is required',
    mustMatch: () => 'Passwords do not match'
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder
  ) {
  }

  ngOnInit(): void {
    this.invitationForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirmPassword: ['', [Validators.required]],
      termsOfServiceAccepted: ['', Validators.requiredTrue],
      privacyPolicyAccepted: ['', Validators.requiredTrue],
    });

    this.invitationForm.setValidators(MustMatch('password', 'confirmPassword'));

    this.invitationFlow$ = this.route.paramMap
      .pipe(
        switchMap(params => of(params.get('id'))),
        tap(id => this.id = id),
        switchMap(id => this.http.get(
          `/boomer-user-invitation/public/${id}`,
        )),
        tap(details => this.email = details.email)
      );
  }

  onSubmit() {
    if (!this.invitationForm.valid) {
      return;
    }

    this.loading = true;
    this.http.post(
      `/boomer-user/invitation`,
      {
        token: this.id,
        password: this.invitationForm.value.password
      }
    ).subscribe((res) => {
      this.router.navigateByUrl(`/`);
      this.loading = false;
    }, (err) => {
      this.invitationForm.setErrors({
        serverError: err.error.message
      });
      this.loading = false;
    });
  }

  getErrorMsg(confirmPassword: AbstractControl) {
    return '';
  }
}
