import {Directive, Input, ViewContainerRef, TemplateRef, OnInit, OnDestroy, inject} from '@angular/core';
import {AuthService} from '../../auth/auth.service';
import {map} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {applyRule, parseRule} from '../../auth/roleRuleEngine';
import {ApplicationUserService} from '../services/application-user.service';

@Directive({
  selector: '[roleFlag]',
  standalone: true,
})
export class AuthDirective implements OnInit, OnDestroy {
  private readonly applicationUserService = inject(ApplicationUserService);
  @Input()
  roleFlag: string;

  private subscription = new Subscription();

  constructor(
    private vcr: ViewContainerRef,
    private tpl: TemplateRef<any>,
  ) {
  }

  ngOnInit(): void {
    const rule = parseRule(this.roleFlag);
    this.subscription.add(
      this.applicationUserService.currentUserProfile$
        .pipe(
          map(({roles}) => roles)
        )
        .subscribe(userRoles => {
          if (userRoles.length > 1) {
            throw new Error('Multiple roles are not supported');
          }
          if (applyRule(rule, userRoles[0])) {
            this.vcr.createEmbeddedView(this.tpl);
            return;
          }
        })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
