import {AuthRoles, SimpleRoles} from './auth.roles';
import {ApplicationUser} from '../shared/services/application-user.service';

type Rule = {
  type: 'OR',
  rules: Rule[],
} | {
  type: 'NOT',
  rule: Rule,
} | {
  type: 'ROLE',
  role: keyof typeof AuthRoles,
};

type ValueOf<T> = T[keyof T];
type SimpleRule = `${ValueOf<typeof SimpleRoles>}`;
type NotRule = `NOT:${SimpleRule}`;
// TODO: It would be nice to do
//       type OrRule = `${RoleFlagRule}|${RoleFlagRule}`;
type PrimitiveRule = NotRule | SimpleRule;
type OrRule2 = `${PrimitiveRule}||${PrimitiveRule}`;
type OrRule3 = `${PrimitiveRule}||${PrimitiveRule}||${PrimitiveRule}`;

export type RoleFlagRule =
  PrimitiveRule
  | OrRule2
  | OrRule3;

export const parseRule = (rule: string) => {
  if (rule.includes('||')) {
    return {
      type: 'OR',
      rules: rule.split('||').map(parseRule),
    };
  }
  if (rule.includes('NOT:')) {
    return {
      type: 'NOT',
      rule: parseRule(rule.replace('NOT:', '')),
    };
  }
  if (rule.includes('ROLE_')) {
    return {
      type: 'ROLE',
      role: rule.replace('ROLE_', '') as keyof typeof AuthRoles,
    };
  }
};

export const applyRule = (rule: Rule, userRole: string): boolean => {
  switch (rule.type) {
    case 'OR':
      return rule.rules.some(rule => applyRule(rule, userRole));
    case 'NOT':
      return !applyRule(rule.rule, userRole);
    case 'ROLE':
      return userRole.includes(rule.role);
  }
};

export const applyRuleToApplicationUser = (rule: Rule, user: ApplicationUser): boolean => {
  if (user.roles.length > 1) {
    throw new Error('Multiple roles are not supported');
  }
  return applyRule(rule, user.roles[0]);
};
