import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {Observable} from 'rxjs';
import {NavigationService} from './core/services/navigation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('entry') entryLayout: TemplateRef<any>;
  @ViewChild('noLayout') noLayout: TemplateRef<any>;
  @ViewChild('main') mainLayout: TemplateRef<any>;

  isBackgroundVisibile$: Observable<boolean> = this.navigationService
    .isNavigationPending$;

  // TODO(Patryk Romańczuk): we need to refactor the app component to not force the same header/footer on every page
  get getTemplate() {
    const url = location.pathname;
    if (url.startsWith('/anonymous') && !url.startsWith('/anonymous-applicant/soa')) {
      return this.noLayout;
    }

    if (url.includes('/admin') || (url.includes('/applicant') && !url.startsWith('/applicant/soa'))) {
      return this.noLayout;
    }

    const entryRoutes = [
      '/failed-auth',
      '/email-confirmation',
      '/applicant-user-registration',
      '/referring-company-referral',
      '/invitation',
      '/boomer-user-invitation',
      '/account-create-confirmation',
      '/activate',
    ];

    const found = entryRoutes.find(route => url.startsWith(route)) !== undefined;
    if (found) {
      return this.entryLayout;
    } else {
      return this.mainLayout;
    }
  }

  get shouldShowLogo() {
    const entryRoutes = ['/failed-auth', '/email-confirmation', '/referring-company-referral', '/applicant-user-registration/invitation', '/invitation', '/boomer-user-invitation', '/account-create-confirmation', '/activate'];

    return entryRoutes.find(route => location.pathname.startsWith(route)) !== undefined;
  }

  constructor(
    private router: Router,
    private navigationService: NavigationService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    iconRegistry
      .addSvgIcon(
        'avatar',
        sanitizer.bypassSecurityTrustResourceUrl('assets/default-avatar.svg')
      )
      .addSvgIcon(
        'twitter',
        sanitizer.bypassSecurityTrustResourceUrl('assets/twitter.svg')
      )
      .addSvgIcon(
        'linkedIn',
        sanitizer.bypassSecurityTrustResourceUrl('assets/linkedIn.svg')
      );
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      window.scrollTo(0, 0);
    });
  }

  showGradient() {
    return false;
  }
}
