import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ApplicantProfile} from 'src/app/auth/auth.service';
import {BoomrAdminService, ReferringCompanyData} from '../../../boomr-admin/boomr-admin.service';
import {ApplicantData} from './applicants.component';
import * as moment from 'moment/moment';
import {PageEvent} from '@angular/material/paginator';
import {FilterType} from '../../../../shared/components/boomer-table/filter-menu/filter-menu.component';
import {Sort} from '@angular/material/sort';
import {County} from '../../../../shared/components/applicant-profile-forms/address-form/address-form.component';

@Injectable({
  providedIn: 'root'
})
export class ApplicantsFacade {
  private refreshCurrentApplicant = new BehaviorSubject<boolean>(false);

  constructor(private boomrAdminService: BoomrAdminService) {
  }

  getApplicants(
    sort: Sort, filters: Map<string, FilterType>, page: PageEvent
  ): Observable<{ items: ApplicantData[]; totalCount: number }> {
    return this.boomrAdminService.getApplicants(sort, filters, page);
  }

  createApplicant(data: any): Observable<any> {
    if (data.birthDate) {
      data.birthDate = moment(data.birthDate)
        .add(moment(data.birthDate).utcOffset(), 'm');
    }
    return this.boomrAdminService.createApplicant(data);
  }

  getCountyByZipCode(zipCode: string): Observable<County[]> {
    return this.boomrAdminService.getCountyByZipCode(zipCode);
  }

  getApplicantNotesById(uuid: string): Observable<any> {
    return this.boomrAdminService.getApplicantNotesById(uuid);
  }

  createApplicantNote(uuid: string, text: string): Observable<any> {
    return this.boomrAdminService.createApplicantNote(
      uuid,
      text
    );
  }

  getAllRefferingCompanies(): Observable<ReferringCompanyData[]> {
    return this.boomrAdminService.getAllRefferingCompanies();
  }

  refreshCurrent() {
    this.refreshCurrentApplicant.next(true);
  }
}

export interface ConnectureSessionIdResponse {
  connectureSessionId: string;
}

export type AccountStatus = 'BOOMER_MANAGED_NO_EMAIL' |
  'BOOMER_MANAGED_WITH_EMAIL' |
  'ACCOUNT_CREATED' |
  'CONNECTURE_ENROLLMENT_STARTED' |
  'ENROLLED_VIA_CONNECTURE' |
  'ENROLLMENT_FINISHED';

export interface ApplicantProfileForBoomer extends ApplicantProfile {
  loginLocked: boolean;
  agencyBlocIndividualId?: string;
  address: {
    addressLine1: string,
    addressLine2: string,
    city: string,
    countyName: string,
    fipsCode: string,
    state: string,
    zipCode: string,
  };
  birthDate: string;
  city: string;
  countyName: string;
  primaryEmail: string;
  firstName: string;
  interestedInMailOrder: boolean;
  lastName: string;
  middleName: string;
  primaryPhone: string;
  privacyPolicyAccepted: boolean;
  secondaryEmail: string;
  secondaryPhone: string;
  state: string;
  termsOfServiceAccepted: boolean;
  zipCode: string;
  enabled: boolean;
  referringCompanyResponse: any;
  accountStatus: AccountStatus;
  connectureSessionId?: string;
  servicingAgentUuid?: string;
  soaRefreshDate?: string;

  completedStages: {
    drugs: boolean;
  };
}

export interface UpdateAddressDto {
  zipCode: string;
  countyFIPS: string;
}
