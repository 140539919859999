import {RoleFlagRule} from './roleRuleEngine';

export const SimpleRoles = {
  ROLE_BOOMER_ADMIN: 'ROLE_BOOMER_ADMIN' as const,
  ROLE_BOOMER_EMPLOYEE: 'ROLE_BOOMER_EMPLOYEE' as const,
  ROLE_BOOMER_RESTRICTED: 'ROLE_BOOMER_RESTRICTED' as const,
  ROLE_REFERRING_COMPANY_ADMIN: 'ROLE_REFERRING_COMPANY_ADMIN' as const,
  ROLE_REFERRING_COMPANY_GENERAL_USER: 'ROLE_REFERRING_COMPANY_GENERAL_USER' as const,
  ROLE_REFERRING_COMPANY_QUOTING_USER: 'ROLE_REFERRING_COMPANY_QUOTING_USER' as const,
  ROLE_APPLICANT: 'ROLE_APPLICANT' as const,
};

export const AuthRoles: Record<string, RoleFlagRule> = {
  ...SimpleRoles,
  AT_LEAST_BOOMER_EMPLOYEE: `${SimpleRoles.ROLE_BOOMER_ADMIN}||${SimpleRoles.ROLE_BOOMER_EMPLOYEE}`,
  ANY_BOOMER_USER: `${SimpleRoles.ROLE_BOOMER_ADMIN}||${SimpleRoles.ROLE_BOOMER_EMPLOYEE}||${SimpleRoles.ROLE_BOOMER_RESTRICTED}`,

  ANY_REFERRING_COMPANY_USER: `${SimpleRoles.ROLE_REFERRING_COMPANY_ADMIN}||${SimpleRoles.ROLE_REFERRING_COMPANY_GENERAL_USER}||${SimpleRoles.ROLE_REFERRING_COMPANY_QUOTING_USER}`,

  NOT_APPLICANT: `NOT:${SimpleRoles.ROLE_APPLICANT}`,
};

export type AuthRoles = keyof typeof AuthRoles;
