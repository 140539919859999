import { Component, OnInit } from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {MustMatch} from '../../helpers/validators/must-match.validator';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../auth/auth.service';
import { MatSnackBar} from '@angular/material/snack-bar';
import {map, switchMap, tap} from 'rxjs/operators';

@Component({
  selector: 'app-account-create-confirmation',
  templateUrl: './account-create-confirmation.component.html',
  styleUrls: ['../entry-forms/entry-forms.scss']
})
export class AccountCreateConfirmationComponent implements OnInit {
  invitationForm: UntypedFormGroup;
  loading = false;
  invitationId: string = null;
  invitation = null;

  readonly formErrors = {
    email: () => 'Invalidate email',
    required: () => 'This field is required',
    mustMatch: () => 'Passwords do not match'
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    private snackbar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.invitationForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirmPassword: ['', [Validators.required]],
      termsOfServiceAccepted: ['', Validators.requiredTrue],
      privacyPolicyAccepted: ['', Validators.requiredTrue],
    });

    this.invitationForm.setValidators(MustMatch('password', 'confirmPassword'));

    this.route.paramMap
      .pipe(
        map(params => params.get('id')),
        tap(id => this.invitationId = id),
      )
      .subscribe(isTokenValid => {
        if (!isTokenValid) {
          this.router.navigateByUrl('/');
        }
      });
  }

  onSubmit() {
    if (this.invitationForm.invalid) {
      return;
    }

    this.loading = true;
    const formValue = this.invitationForm.value;
    const data = {
      token: this.invitationId,
      password: formValue.password,
      termsOfServiceAccepted: formValue.termsOfServiceAccepted,
      privacyPolicyAccepted: formValue.privacyPolicyAccepted,
    };
    this.auth.confirmAccountCreation(data)
      .subscribe((res) => {
          this.router.navigateByUrl(`/`);
          this.snackbar.open('Your account has been successfully created, you can now log in!', 'OK', { duration: 3000 });
        }, (err) => {
          if (err.error.message === 'Invalid email confirmation token.') {
            this.snackbar.open('You already have an account.', 'Ok', { duration: 3000 });
            this.router.navigateByUrl('/');
          }

          this.invitationForm.setErrors({
            serverError: err.error.message
          });
          this.loading = false;
        }
      );
  }

  getErrorMsg(formField: AbstractControl): string {
    const control: AbstractControl = formField;

    if (control.valid || !control.errors) {
      return;
    }

    const controlErrors: ValidationErrors | null = control.errors;
    const firstKey: string = Object.keys(controlErrors)[0];
    const error = this.formErrors[firstKey];

    return error !== undefined ? error(controlErrors[firstKey]) : '';
  }
}
