import {
  AfterViewInit,
  Component,
  forwardRef,
  Inject,
  Injector,
  INJECTOR,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, NgControl} from '@angular/forms';
import {BehaviorSubject, Observable, of, OperatorFunction} from 'rxjs';
import {filter, startWith, tap} from 'rxjs/operators';
import { MatAutocomplete} from '@angular/material/autocomplete';

@Component({
  selector: 'app-boomer-autocomplete-field',
  templateUrl: './boomer-autocomplete-field.component.html',
  styleUrls: [
    './boomer-autocomplete-field.component.scss',
    '../boomer-text-input/boomer-text-input.component.scss'
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BoomerAutocompleteFieldComponent),
      multi: true
    }
  ]
})
export class BoomerAutocompleteFieldComponent<Identifier, T> implements ControlValueAccessor, OnInit, AfterViewInit {
  @Input()
  required = false;
  @Input()
  label: string;
  @Input()
  readonly: any;
  @Input()
  hint: string;
  @Input()
  icon: string;

  @Input()
  onInputChange: OperatorFunction<string, T[]>;

  @Input()
  findValue: (ident: Identifier) => Observable<T>;

  @Input()
  findValueInOptions: (value: Identifier, options: T[]) => T | null;

  @Input()
  findIdentifierInOption: (option: T) => Identifier;

  @Input()
  displayOption: (option: T) => string;

  value: T;
  displayValue: string;

  options = new BehaviorSubject<T[]>([]);
  options$ = this.options.asObservable();

  isDisabled = false;

  onChange: (value: Identifier) => void;
  onTouched: () => void;

  formControl: NgControl;

  control = new UntypedFormControl();

  loading = false;

  @ViewChild('auto')
  auto: MatAutocomplete;

  constructor(@Inject(INJECTOR) private injector: Injector) {
  }

  ngOnInit() {
    this.formControl = this.injector.get(NgControl);

    if (!this.onInputChange) {
      throw new Error('onInputChange is required');
    }

    // Load initial data
    of('')
      .pipe(
        this.onInputChange
      )
      .subscribe((options) => {
        this.options.next(options);
      });

    this.control.valueChanges
      .pipe(
        filter(v => typeof v === 'string'),
        tap(() => this.loading = true),
        this.onInputChange,
        tap(() => this.loading = false),
      )
      .subscribe((options) => {
        this.options.next(options);
      });
  }

  ngAfterViewInit() {
    this.auto.optionSelected
      .subscribe((event) => {
        const val = this.findIdentifierInOption(event.option.value);
        if (!val) {
          throw new Error('findIdentifierInOption returned null');
        }
        this.control.setValue(this.displayOption(event.option.value), {emitEvent: false});
        this.onChange(val);
      });
  }

  registerOnChange(fn: (value: Identifier) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(ident: Identifier): void {
    if (!ident) {
      return;
    }

    const val = this.findValueInOptions(ident, this.options.value);
    if (val) {
      this.control.setValue(this.displayOption(val), {emitEvent: false});
    } else {
      this.findValue(ident)
        .subscribe((value) => {
          this.control.setValue(this.displayOption(value), {emitEvent: false});
        });
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  onFocus() {
    of('')
      .pipe(
        this.onInputChange
      )
      .subscribe((options) => {
        this.options.next(options);
      });
  }
}
