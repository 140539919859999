import {Component, inject, OnInit} from '@angular/core';
import {AuthService} from '@auth0/auth0-angular';

@Component({
  selector: 'app-auth0-sign-up-redirect',
  standalone: true,
  imports: [],
  templateUrl: './auth0-sign-up-redirect.component.html',
  styleUrl: './auth0-sign-up-redirect.component.scss'
})
export class Auth0SignUpRedirectComponent implements OnInit {
  private auth0AuthService = inject(AuthService);

  ngOnInit(): void {
    this.auth0AuthService.loginWithRedirect({
      authorizationParams: {
        prePopulatedSignup: true,
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
      }
    });
  }
}
