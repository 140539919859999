import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../auth/auth.service';
import {ActivatedRoute} from '@angular/router';
import {AuthService as Auth0AuthService} from '@auth0/auth0-angular';

@Component({
  selector: 'app-applicant-invitation',
  templateUrl: './applicant-invitation.component.html',
  styleUrls: []
})
export class ApplicantInvitationComponent implements OnInit {

  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private auth0AuthService: Auth0AuthService
  ) {
  }

  ngOnInit() {
    const invitationId = this.route.snapshot.params.id;
    this.auth.getApplicantInvitationDetails(invitationId)
      .subscribe((data) => {
        this.auth0AuthService.loginWithRedirect({
          authorizationParams: {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phoneNumber: data.phoneNumber,
            birthdate: data.birthDate,
            invitationId
          }
        });
      });

  }
}
