import {inject, Injectable} from '@angular/core';
import {endOfYear} from 'date-fns';
import {differenceInMonths} from 'date-fns/esm';
import {PharmaciesService} from 'src/app/modules/applicant-features/services/pharmacies.service';
import * as moment from 'moment';
import {Moment} from 'moment';

@Injectable({providedIn: 'root'})
export class PlanSummaryFacade {
  private readonly pharmaciesService = inject(PharmaciesService);

  prepareHeaderData(data: any): HeaderData {
    const {
      logoURL,
      planName,
      planType,
      drugDeductible,
      coverageStart,
      planRating,
      drugPremium,
      id: uuid,
    } = data;

    const parsedCoverageStart = moment(coverageStart);
    const coverageEnd = parsedCoverageStart.clone().endOf('year');
    const months = coverageEnd.diff(parsedCoverageStart, 'months');

    // Map planType to string
    let parsedPlanType;
    if (planType === PlanType.DRUG_PLAN) {
      parsedPlanType = 'Drug Plan (Part D)';
    } else if (planType === PlanType.ADVANTAGE_PLAN) {
      parsedPlanType = 'Advantage Plan';
    } else {
      parsedPlanType = planType;
    }

    const premium = data.drugPremium * (months + 1);
    const pharmaciesCosts = data.pharmacyCosts.map((pharmacy) =>
      pharmacy.monthlyCosts
        .filter(({monthID}) => monthID <= months)
        .map(month => (month.costDetail || []).reduce((pv, {memberCost}) => pv + memberCost, 0))
        .reduce((total, monthCosts) => total + monthCosts, 0)
    );

    const totalCostForCoveragePeriod = premium + Math.min(...pharmaciesCosts);

    // create planID
    const {contractID, planID, segmentID} = data;

    const parsedSegmentID = parseInt(segmentID, 10);

    const id = `${contractID}-${planID}-${parsedSegmentID}`;
    let logoUrl;
    if (logoURL && logoURL.includes('http')) {
      logoUrl = logoURL;
    } else {
      logoUrl = `https://content.destinationrx.com/ContentServer/DRxProductContent/PlanLogo/${logoURL}`;
    }

    const headerData: HeaderData = {
      logoUrl,
      planName,
      id,
      uuid,
      planType: parsedPlanType,
      drugDeductible,
      coverageStart,
      planRating,
      drugPremium,
      coverageEnd,
      totalCostForCoveragePeriod,
    };

    return headerData;
  }

  async prepareCostData(data: any): Promise<CostData> {
    const {planDrugCoverage, coverageStart, drugPremium, id} = data;

    // Choose cheapest pharmacy
    let cheapestPharmacy;
    const parsedCoverageStart = moment(coverageStart).toDate();
    const coverageEnd = endOfYear(parsedCoverageStart);
    const months = differenceInMonths(coverageEnd, parsedCoverageStart);
    let totalCost = 0;
    (data.pharmacyCosts as Array<any>).map((pCost, index) => {
      let pharmacyCost = 0;
      const filtered = pCost.monthlyCosts.filter(
        (month) => month.monthID <= months
      );
      filtered.map((month) => {
        if (month.costDetail) {
          month.costDetail.map((c) => {
            pharmacyCost += c.memberCost;
          });
        }
      });
      if (index === 0) {
        totalCost = pharmacyCost;
        cheapestPharmacy = pCost;
      }

      if (pharmacyCost < totalCost) {
        totalCost = pharmacyCost;
        cheapestPharmacy = pCost;
      }
    });

    const dataSource = planDrugCoverage.map((drug) => {
      const {labelName: cost, tierDescription: tier} = drug;
      const covered = tier ? true : false;

      const filtered = cheapestPharmacy.monthlyCosts.filter(
        (month) => month.monthID <= months
      );

      let estimatedCost = 0;
      filtered.map((f) => {
        f.costDetail.map((eachCost) => {
          if (eachCost.labelName === cost) {
            estimatedCost += eachCost.memberCost;
          }
        });
      });

      return {
        cost,
        tier,
        covered,
        estimatedCost,
      };
    });

    const {isPreferred, isNetwork, pharmacyType} = cheapestPharmacy;

    let parsedPharmacyType;

    if (pharmacyType === PharmacyType.RETAIL) {
      parsedPharmacyType = 'Retail';
    } else if (pharmacyType === PharmacyType.MAIL_ORDER) {
      parsedPharmacyType = 'Mail Order';
    } else {
      parsedPharmacyType = pharmacyType;
    }

    const pharmacies = (await this.pharmaciesService
      .getUserPharmacies()
      .toPromise()) as any[];

    const pharmacyData = pharmacies.filter((pharmacy) => {
      return pharmacy.connectureId === cheapestPharmacy.pharmacyID;
    })[0];

    if (pharmacyData) {
      const {address, state, zipCode, city, name} = pharmacyData;

      return {
        id,
        dataSource,
        totalEstimatedDrugCost: totalCost,
        policyPremium: drugPremium * (months + 1),
        lowCostPharmacy: {
          isPreferred,
          isNetwork,
          pharmacyType: parsedPharmacyType,
          name,
          address,
          state,
          zipCode,
          city,
        },
      };
    } else {
      return {
        id,
        dataSource,
        totalEstimatedDrugCost: totalCost,
        policyPremium: drugPremium * (months + 1),
        lowCostPharmacy: {
          isPreferred,
          isNetwork,
          pharmacyType: parsedPharmacyType,
          name: '',
          address: '',
          state: '',
          zipCode: '',
          city: '',
        },
      };
    }
  }

  preprareBrochuresData(data: any): BrochuresData {
    return {
      documents: (data.documents || []).map((doc) => {
        return {
          url: doc.url,
          name: doc.name,
        };
      }),
    };
  }

  prepareContactInfoData(data: any): ContactInfoData {
    return {website: data.providerURL};
  }
}

export interface HeaderData {
  logoUrl: string;
  planName: string;
  id: string;
  uuid: string;
  planType: string;
  drugDeductible: string;
  coverageStart: Moment;
  coverageEnd: Moment;
  planRating: number;
  drugPremium: number;
  totalCostForCoveragePeriod: number;
}

export interface SummaryPageData {
  headerData: HeaderData;
  brochuresData: BrochuresData;
  contactInfoData: ContactInfoData;
  costData: CostData;
}

export interface CostData {
  id: string;
  dataSource: {
    cost: string;
    covered: boolean;
    tier: string;
    estimatedCost: string;
  }[];
  totalEstimatedDrugCost: number;
  policyPremium: number;
  lowCostPharmacy: {
    isPreferred: boolean;
    isNetwork: boolean;
    pharmacyType: 'Mail Order' | string;
    name: string;
    address: string;
    state: string;
    zipCode: string;
    city: string;
  };
}

export interface BrochuresData {
  documents: {
    url: string;
    name: string;
  };
}

export interface ContactInfoData {
  website: string;
}

export enum PlanType {
  DRUG_PLAN = 1,
  ADVANTAGE_PLAN = 2,
}

export enum PharmacyType {
  RETAIL = 1,
  MAIL_ORDER = 2,
}
