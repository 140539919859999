import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {InvitationComponent} from './components/invitation/invitation.component';
import {ResetPasswordComponent} from './components/reset-password/reset-password.component';
import {ReferralPageComponent} from './components/referral-page/referral-page.component';
import {ApplicantInvitationComponent} from './components/applicant-invitation/applicant-invitation.component';
import {GetStartedComponent} from './components/get-started/get-started.component';
import {BoomerUserInvitationComponent} from './components/boomer-user-invitation/boomer-user-invitation.component';
import {
  AccountCreateConfirmationComponent
} from './components/account-create-confirmation/account-create-confirmation.component';
import {SoaRequestFormComponent} from './components/soa-request-form/soa-request-form.component';
import {ActivateAccountComponent} from './components/entry-forms/activate-account/activate-account.component';
import {SoaPdfRedirectComponent} from './components/soa-pdf-redirect/soa-pdf-redirect.component';
import {AuthGuard} from '@auth0/auth0-angular';
import {roleRedirectGuard} from './auth/roleRedirectGuard';
import {AuthFailedComponent} from './components/auth-failed/auth-failed.component';
import {PreLoginWaitComponent} from './components/pre-login-wait/pre-login-wait.component';
import {
  ApplicantSoaFormComponent
} from './modules/applicant-features/components/applicant-soa-form/applicant-soa-form.component';
import {Auth0SignUpRedirectComponent} from './components/auth0-sign-up-redirect/auth0-sign-up-redirect.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '', canActivate: [AuthGuard, roleRedirectGuard], component: PreLoginWaitComponent, pathMatch: 'full'
      },
      {
        path: 'sign-up', component: Auth0SignUpRedirectComponent, pathMatch: 'full'
      },
      {path: 'failed-auth', component: AuthFailedComponent},
      {path: 'soa/:id/pdf', component: SoaPdfRedirectComponent, data: {availableForEveryone: true}},
      {path: 'soa-request/:id', component: SoaRequestFormComponent, data: {availableForEveryone: true}},
      {path: 'activate', canActivate: [AuthGuard], component: ActivateAccountComponent},
      {path: 'get-started', component: GetStartedComponent},
      {path: 'password-reset/:id', component: ResetPasswordComponent},
      {path: 'invitation/:id', component: InvitationComponent},
      {path: 'boomer-user-invitation/:id', component: BoomerUserInvitationComponent},
      {path: 'account-create-confirmation/:id', component: AccountCreateConfirmationComponent},
      {
        path: 'referring-company-referral/:id',
        component: ReferralPageComponent,
      },
      {
        path: 'applicant-user-registration/invitation/:id',
        component: ApplicantInvitationComponent,
      },
      {
        path: 'admin',
        loadChildren: () =>
          import(`./modules/admin-pages/admin-pages.module`).then(
            (m) => m.AdminPagesModule
          ),
        canActivate: [AuthGuard],
      },
      {path: 'applicant/soa-request/:id', component: ApplicantSoaFormComponent},
      {
        path: 'applicant',
        loadChildren: () =>
          import(`./modules/applicant-pages/applicant-pages.module`).then(
            (m) => m.ApplicantPagesModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'anonymous-applicant',
        loadChildren: () =>
          import('./modules/anonymous-applicant/anonymous-applicant.module').then(
            (m) => m.AnonymousApplicantModule
          ),
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
