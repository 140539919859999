import {inject, Injectable} from '@angular/core';
import {ApplicantProfile} from 'src/app/auth/auth.service';
import {BehaviorSubject, combineLatest, Observable, of, Subject} from 'rxjs';
import {map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {ApplicantProfileForBoomer} from '../../admin-features/components/applicants/applicants.facade';
import {BoomerHttpClient} from '../../../shared/services/boomer-http-client';
import {AbstractApplicantProfileService} from './abstract-applicant-profile.service';
import {PharmacyCosts} from '../components/todo/plan-details/plan-details.facade';

export interface PharmacyItem {
  uuid?: string;
  connectureId?: string;
  name: string;
  address?: string;
  city?: string;
  zipCode?: string;
  distance?: number;
  state?: string;
  phoneNumber?: string;
}

const isInterestedInMailOrderPharmacy = (applicant: ApplicantProfileForBoomer | ApplicantProfile) => applicant.interestedInMailOrder;

const appendMailOrderPharmacyIfNeeded = ([pharmacies, interestedInMailOrder]: [PharmacyItem[], boolean]) => {
  if (interestedInMailOrder) {
    return {
      list: [...pharmacies, {name: 'Mail order pharmacy', connectureId: 'MAIL_ORDER_PHARMACY'} as PharmacyItem],
      interestedInMailOrder
    };
  }

  return {list: pharmacies, interestedInMailOrder};
};

@Injectable()
export class PharmaciesService {
  private readonly http = inject(BoomerHttpClient);
  private readonly applicantProfileService = inject(AbstractApplicantProfileService);

  loadPharmacies: BehaviorSubject<boolean> = new BehaviorSubject(false);
  userPharmacies$ = this.loadPharmacies.pipe(
    switchMap(() =>
      combineLatest([
        this.applicantProfileService.applicantId$.pipe(switchMap(this.getUserPharmacies.bind(this))),
        this.applicantProfileService.applicantProfile$.pipe(map(isInterestedInMailOrderPharmacy))
      ])
    ),
    map(appendMailOrderPharmacyIfNeeded),
    shareReplay(1)
  );

  onAdd = new Subject();

  searchPharmacy(
    name: string,
    zipCode: string,
    radius = 4
  ): Observable<PharmacyItem[]> {
    return this.http.get<PharmacyItem[]>(`/pharmacy/search?name=${name}&zipCode=${zipCode}&radius=${radius * 1.60}`);
  }

  getUserPharmacies(id: string = null): Observable<PharmacyItem[]> {
    return (id ? of(id) : this.applicantProfileService.getApplicantId())
      .pipe(
        switchMap(applicantId =>
          this.http.get<PharmacyItem[]>(`/pharmacy/applicant/${applicantId}`)
        ),
      );
  }

  addPharmacyToUser(connecturePharmacyId: string) {
    return this.applicantProfileService.getApplicantId()
      .pipe(
        switchMap(id =>
          this.http.post(`/pharmacy/applicant/${id}`, {connecturePharmacyId})
            .pipe(
              tap(() => this.loadPharmacies.next(true)),
              tap(() => this.onAdd.next())
            )
        ),
      );
  }

  deletePharmacyFromUser(pharmacyUuid: string) {
    if (pharmacyUuid === 'MAIL_ORDER_PHARMACY' || pharmacyUuid === undefined) {
      return this.updateMailOrderState(false);
    } else {
      return this.applicantProfileService.getApplicantId()
        .pipe(
          switchMap(id => this.http.delete(`/pharmacy/${pharmacyUuid}/applicant/${id}`)
            .pipe(
              tap(() => this.loadPharmacies.next(true)),
            )
          )
        );
    }
  }

  updateMailOrderState(enabled: boolean) {
    return this.applicantProfileService.getApplicantId().pipe(
      switchMap(id => this.http
        .patch(
          `/applicant-user/${id}/interested-in-mail-order`,
          {isInterestedInMailOrder: enabled}
        )
      ),
      // tap(() => this.auth.loadApplicantProfile.next(true)),
      tap(() => this.loadPharmacies.next(true))
    );
  }
}
