import {Component} from '@angular/core';
import {AuthService} from '@auth0/auth0-angular';
import {catchError, take, tap, timeout} from 'rxjs/operators';
import {Router} from '@angular/router';
import {throwError, TimeoutError} from 'rxjs';

@Component({
  selector: 'app-auth-failed',
  templateUrl: './auth-failed.component.html',
  styleUrls: ['./auth-failed.component.scss', '../entry-forms/entry-forms.scss']
})
export class AuthFailedComponent {
  // Since `AuthService::error$` is not populated after a refresh
  // we redirect to / if auth fails again `error$` will be populated
  error$ = this.auth0AuthService.error$
    .pipe(
      timeout(100),
      take(1),
      catchError(error => {
        if (error instanceof TimeoutError) {
          return this.auth0AuthService.logout({
            logoutParams: {
              returnTo: window.location.origin
            }
          });
        }
        return throwError(error);
      })
    );

  constructor(private auth0AuthService: AuthService, private router: Router) {
  }
}
