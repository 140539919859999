import {HTTP_INTERCEPTORS, HttpBackend, HttpClient, HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ApplicantInvitationComponent} from './components/applicant-invitation/applicant-invitation.component';
import {GetStartedComponent} from './components/get-started/get-started.component';
import {InvitationComponent} from './components/invitation/invitation.component';
import {LandingPageComponent} from './components/landing-page/landing-page.component';
import {ReferralPageComponent} from './components/referral-page/referral-page.component';
import {ResetPasswordComponent} from './components/reset-password/reset-password.component';
import {SharedModule} from './shared/shared.module';
import {BoomerUserInvitationComponent} from './components/boomer-user-invitation/boomer-user-invitation.component';
import {
  AccountCreateConfirmationComponent
} from './components/account-create-confirmation/account-create-confirmation.component';
import {SoaRequestFormComponent} from './components/soa-request-form/soa-request-form.component';
import {ActivateAccountComponent} from './components/entry-forms/activate-account/activate-account.component';
import {SoaPdfRedirectComponent} from './components/soa-pdf-redirect/soa-pdf-redirect.component';
import {AuthClientConfig, AuthConfig, AuthHttpInterceptor, AuthModule} from '@auth0/auth0-angular';
import {AuthModule as BoomerAuthModule} from './auth/auth.module';
import {AuthFailedComponent} from './components/auth-failed/auth-failed.component';
import {NgxMaskDirective, provideNgxMask} from 'ngx-mask';
import {PreLoginWaitComponent} from './components/pre-login-wait/pre-login-wait.component';
import {tap} from 'rxjs/operators';
import {BoomerHttpClient} from './shared/services/boomer-http-client';

type Env = {
  auth0domain: string;
  auth0ClientId: string;
  auth0audience: string;
  apiUrl: string;
};

const ENV_FILE_PATH = 'assets/env.json';

const constructAuth0Config = (env: Env): AuthConfig => {
  console.log('constructAuth0Config');
  return {
    domain: env.auth0domain,
    clientId: env.auth0ClientId,
    errorPath: '/failed-auth',
    authorizationParams: {
      redirect_uri: window.location.origin,
      scope: 'openid'
    },
    httpInterceptor: {
      allowedList: [
        {
          uriMatcher: uri => {
            console.log('uriMatcher', uri);
            if (uri === ENV_FILE_PATH) {
              return false;
            }
            if (uri.startsWith(`${env.apiUrl}/api/boomer-user/invitation`) || uri.startsWith(`${env.apiUrl}/api/boomer-user-invitation/public`)) {
              return false;
            }
            if (uri.startsWith(`${env.apiUrl}/api/county/`)) {
              return false;
            }
            const uuidRegex = '[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}';
            if (uri.match(new RegExp(`${env.apiUrl}/api/soa/${uuidRegex}/view`)) ||
              uri.match(new RegExp(`${env.apiUrl}/api/soa/${uuidRegex}/complete`)) ||
              uri.match(new RegExp(`${env.apiUrl}/api/soa/${uuidRegex}/resend`)) ||
              uri.match(new RegExp(`${env.apiUrl}/api/soa/${uuidRegex}/pdf`)) ||
              uri.match(new RegExp(`${env.apiUrl}/api/soa/${uuidRegex}/transfer`)) ||
              uri.match(new RegExp(`${env.apiUrl}/api/soa/${uuidRegex}/cancel`))
            ) {
              return true;
            }
            if (
              uri.startsWith(`${env.apiUrl}/api/soa/anonymous`) ||
              uri.match(new RegExp(`${env.apiUrl}/api/soa/${uuidRegex}`)) ||
              uri.match(new RegExp(`${env.apiUrl}/api/soa/${uuidRegex}/sign/applicant`))
            ) {
              return false;
            }
            if (uri.includes('public')) {
              return false;
            }
            return uri.startsWith(`${env.apiUrl}/api/`);
          },
          tokenOptions: {
            authorizationParams: {
              audience: env.auth0audience,
              scope: 'openid'
            }
          }
        }
      ]
    }
  };
};

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    InvitationComponent,
    GetStartedComponent,
    ResetPasswordComponent,
    ReferralPageComponent,
    ApplicantInvitationComponent,
    BoomerUserInvitationComponent,
    AccountCreateConfirmationComponent,
    SoaRequestFormComponent,
    ActivateAccountComponent,
    SoaPdfRedirectComponent,
    AuthFailedComponent,
    PreLoginWaitComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AuthModule.forRoot(),
    BoomerAuthModule.forRoot(),
    NgxMaskDirective
  ],
  providers: [
    // {provide: ErrorHandler, useClass: BoomerAdminErrorHandler},
    {
      provide: APP_INITIALIZER,
      useFactory: (handler: HttpBackend, httpClient: BoomerHttpClient, config: AuthClientConfig) =>
        () =>
          new HttpClient(handler)
            .get(ENV_FILE_PATH)
            .pipe(
              tap((env: Env) => {
                config.set(constructAuth0Config(env));
                httpClient.setApiUrl(env.apiUrl);
              })
            ),
      deps: [HttpBackend, BoomerHttpClient, AuthClientConfig],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    BoomerHttpClient,
    provideNgxMask(),
    /*
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpErrorInterceptor,
          multi: true,
        },
    */
  ],
  bootstrap: [AppComponent],
  exports: [
    SoaRequestFormComponent
  ]
})
export class AppModule {
}
