import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidationErrors, AbstractControl } from '@angular/forms';
import { AuthService } from '../../auth/auth.service';
import { AuthState } from '../../auth/auth.state';
import { Router, ActivatedRoute } from '@angular/router';
import { MustMatch } from '../../helpers/validators/must-match.validator';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm: UntypedFormGroup;
  passwordResetToken: string = null;
  loading = false;
  snackSub: Subscription;

  readonly formErrors = {
    email: () => 'Invalidate email',
    required: () => 'This field is required',
    mustMatch: () => 'Passwords do not match'
};

  constructor(private auth: AuthService,
              private formBuilder: UntypedFormBuilder,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.resetPasswordForm = this.formBuilder.group({
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    });

    this.resetPasswordForm.setValidators(MustMatch('newPassword', 'confirmPassword'));
    this.snackSub = this.route.paramMap.subscribe((paramMap) => {
      this.passwordResetToken = paramMap.get('id');
    });
  }

  ngOnDestroy() {
    this.snackSub && this.snackSub.unsubscribe();
  }

  onSubmit(): void {
    if (this.resetPasswordForm.valid) {

      const resetPasswordData: any = {
        newPassword: this.resetPasswordForm.get('newPassword').value,
        passwordResetToken: this.passwordResetToken
      };

      this.loading = true;

      this.auth.submitResetPassword(resetPasswordData).subscribe((res) => {
        this.router.navigate(['/login']);
      }, (err) => {
        this.resetPasswordForm.setErrors({
          serverError: err.error.message
        });
        this.loading = false;
      });

    }
  }

  getErrorMsg(formField: AbstractControl): string {
    const control: AbstractControl = formField;

    if (control.valid || !control.errors) {
        return;
    }

    const controlErrors: ValidationErrors | null = control.errors;
    const firstKey: string = Object.keys(controlErrors)[0];
    const error = this.formErrors[firstKey];

    return error !== undefined ? error(controlErrors[firstKey]) : '';
  }
}
