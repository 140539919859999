import {Injectable} from '@angular/core';
import {AuthRoles} from './auth.roles';

export interface AuthToken {
  access_token: string;
  refresh_token: string;
  token_type: string;
  expires_in: number;
  scope: string;
  jti: string;
  authorities: AuthRoles[];
  referring_company_uuid?: string;
  user_name?: string;
  user_uuid?: string;
}

@Injectable()
export class AuthState {
  get decodedToken(): AuthToken {
    throw new Error('deprecated');
  }
}
