import {Moment} from 'moment';
import {MedicalPlansDetails} from './medical-plans-details/medical-plans-details.facade';
import {Deductibles} from './selected-plans/plans.facade';
import {Deductible} from '../../../../shared/types/recommended-plans';

export const getDeductibleFor256 = (plan: MedicalPlansDetails, coverageStart: Moment, deductibles: Deductibles): Deductible => {
  const planYear = coverageStart.year().toString();
  let deductiblesForPlanYear = deductibles[planYear];
  if (!deductiblesForPlanYear) {
    // If the backend didn't return any deductibles for the particular year, use the newest ones
    // eg. 2025 deductibles are not available yet, use 2024 ones
    const sortedByYear = Object.entries(deductibles).toSorted(([yearA], [yearB]) => parseInt(yearB, 10) - parseInt(yearA, 10));
    const [newestYear, newestDeductibles] = sortedByYear[0];
    // tslint:disable-next-line:no-console
    console.info(`Backend didn't return deductibles for ${planYear} using ${newestYear} values`);
    deductiblesForPlanYear = newestDeductibles;
  }

  const planType = plan.planSubType
    .replace('High Deductible', 'HD')
    .replaceAll(' ', '_')
    .toUpperCase();

  const deductible = deductiblesForPlanYear[planType];
  if (!deductible) {
    console.error(deductibles);
    throw new Error(`No deductible for ${planYear} ${planType}`);
  }

  return deductible;
};
