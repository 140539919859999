import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BoomerTextInputComponent} from './boomer-text-input/boomer-text-input.component';
import { MatFormFieldModule} from '@angular/material/form-field';
import {ReactiveFormsModule} from '@angular/forms';
import { MatInputModule} from '@angular/material/input';
import {BoomerFormsErrorsComponent} from './boomer-forms-errors/boomer-forms-errors.component';
import {BoomerFormsCheckboxComponent} from './boomer-forms-checkbox/boomer-forms-checkbox.component';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { MatButtonModule} from '@angular/material/button';
import {BoomerDateInputComponent} from './boomer-date-input/boomer-date-input.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatIconModule} from '@angular/material/icon';
import {BoomerPhoneInputComponent} from './boomer-phone-input/boomer-phone-input.component';
import {BoomerNumberInputComponent} from './boomer-number-input/boomer-number-input.component';
import {FormPhoneInputComponent} from './boomer-phone-input/form-phone-input/form-phone-input.component';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule} from '@angular/material-moment-adapter';
import {BoomerAgencyFieldComponent} from './boomer-agency-field/boomer-agency-field.component';
import {BoomerAutocompleteFieldComponent} from './boomer-autocomplete-field/boomer-autocomplete-field.component';
import { MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatProgressBarModule} from '@angular/material/progress-bar';
import {BoomerSelectInputComponent} from './boomer-select-input/boomer-select-input.component';
import { MatSelectModule} from '@angular/material/select';
import { BoomerBoomerUserFieldComponent } from './boomer-boomer-user-field/boomer-boomer-user-field.component';
import { BoomerAgentFieldComponent } from './boomer-agent-field/boomer-agent-field.component';
import { BoomerCarrierFieldComponent } from './boomer-carrier-field/boomer-carrier-field.component';
import { BoomerStateFieldComponent } from './boomer-state-field/boomer-state-field.component';
import { BoomerCxCarrierFieldComponent } from './boomer-cx-carrier-field/boomer-cx-carrier-field.component';
import { BoomerReferringCompanyFieldComponent } from './boomer-referring-company-field/boomer-referring-company-field.component';
import {NgxMaskDirective} from 'ngx-mask';
import {BoomerApplicantUserFieldComponent} from './boomer-applicant-user-field/boomer-applicant-user-field.component';
import {
  BoomerProfilePictureFieldComponent
} from './boomer-profile-picture-field/boomer-profile-picture-field.component';


@NgModule({
  declarations: [
    BoomerTextInputComponent,
    BoomerFormsErrorsComponent,
    BoomerFormsCheckboxComponent,
    BoomerDateInputComponent,
    BoomerNumberInputComponent,
    BoomerPhoneInputComponent,
    FormPhoneInputComponent,
    BoomerAgencyFieldComponent,
    BoomerAutocompleteFieldComponent,
    BoomerSelectInputComponent,
    BoomerBoomerUserFieldComponent,
    BoomerApplicantUserFieldComponent,
    BoomerAgentFieldComponent,
    BoomerCarrierFieldComponent,
    BoomerStateFieldComponent,
    BoomerCxCarrierFieldComponent,
    BoomerReferringCompanyFieldComponent,
    BoomerProfilePictureFieldComponent,
  ],
  exports: [
    BoomerTextInputComponent,
    BoomerFormsCheckboxComponent,
    BoomerDateInputComponent,
    BoomerNumberInputComponent,
    BoomerPhoneInputComponent,
    BoomerAutocompleteFieldComponent,
    BoomerAgencyFieldComponent,
    BoomerSelectInputComponent,
    BoomerBoomerUserFieldComponent,
    BoomerApplicantUserFieldComponent,
    BoomerAgentFieldComponent,
    BoomerCarrierFieldComponent,
    BoomerStateFieldComponent,
    BoomerCxCarrierFieldComponent,
    BoomerFormsErrorsComponent,
    BoomerReferringCompanyFieldComponent,
    BoomerProfilePictureFieldComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatButtonModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatIconModule,
    NgxMaskDirective,
    MatAutocompleteModule,
    MatProgressBarModule,
    MatSelectModule
  ],
  providers: [
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true, strict: false}}
  ]
})
export class BoomerFormsModule {
}
