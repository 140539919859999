<ng-template #entry>
  <main class="entry">
    <svg class="absolute w-full top-0" viewBox="0 0 1920 1018" preserveAspectRatio="none">
      <g xmlns="http://www.w3.org/2000/svg">
        <path xmlns="http://www.w3.org/2000/svg"
              d="M1033.7,86.573c0-11.718-13.133-21.218-29.333-21.218H531.85C618.043,158.912,790.88,194.237,1033.7,182.53Z"
              transform="translate(892.203 -72.815)" fill="#ffad3d"/>
      </g>
    </svg>
    <svg class="absolute w-full bottom-0" viewBox="0 0 1920 1018" preserveAspectRatio="none">
      <defs>
        <linearGradient xmlns="http://www.w3.org/2000/svg" id="linear-gradient" x1="0.572" y1="0.398" x2="-0.072"
                        y2="1.376" gradientUnits="objectBoundingBox">
          <stop offset="0" stop-color="#00154a"/>
          <stop offset="0.576" stop-color="#2c67fe"/>
          <stop offset="1" stop-color="#2e3192"/>
        </linearGradient>
      </defs>
      <g xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1002.591,491.939C894.191,19.239,421.559,94.385,273,110.539V1074H1660.5C1510.819,758.964,1112.866,972.8,1002.591,491.939Z"
          transform="translate(-273 -56.399)" fill="url(#linear-gradient)"/>
      </g>
    </svg>

    <div class="main-logo" *ngIf="shouldShowLogo">
      <a [routerLink]="['/']">
        <ng-container *ngTemplateOutlet="logo"></ng-container>
      </a>
    </div>

    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </main>
  <ng-container *ngTemplateOutlet="footer"></ng-container>
</ng-template>

<ng-template #noLayout>
  <router-outlet></router-outlet>
</ng-template>
<ng-template #main>
  <div>
    <div *ngIf="showGradient()" class="background__gradient"></div>
  </div>

  <header class="top">
    <div class="main-logo">
      <a [routerLink]="['/']">
        <img src="assets/boomer-baby-logomark.svg"/>
      </a>
    </div>
  </header>

  <div class="main-container no-margin">
    <router-outlet></router-outlet>
  </div>

  <ng-container *ngTemplateOutlet="footer"></ng-container>
</ng-template>

<ng-template #footer>
  <footer *ngIf="!(isBackgroundVisibile$ | async)" class="footer">
    <div class="footer__content">
      <div class="footer__support">
        <b>Support Hours</b>
        <div>Monday - Friday: 8am - 5pm</div>
        <div>Weekends: 10am - 4pm</div>
      </div>

      <div class="footer__disclaimer">
        <p>
          BoomerBaby Insurance Services is a licensed health insurance agency. The purpose of this site is the
          solicitation of insurance. Contact may be made by an insurance agent/producer or insurance company. BoomerBaby
          and the Medicare insurance plans shown are not connected with or endorsed by the U.S. government or the
          federal Medicare program. We offer plans from a number of insurance companies.
        </p>
        <p>
          BoomerBaby Insurance Services does not discriminate based on race, ethnicity, national origin, religion,
          gender, sex, age, mental or physical disability, health status, receipt of health care, claims experience,
          medical history, genetic information, evidence of insurability, or geographic location.
        </p>
      </div>

      <div class="footer__contact">
        <b>Contact Licensed Sales Agent</b>
        <div>email: <a href="mailto:enroll@boomerbaby.com">enroll&#64;boomerbaby.com</a></div>
        <div>Call: 800-903-8044</div>
        <div>TTY users 711</div>
      </div>
      <div class="footer__copyright">
        Copyright ©2021 boomerbaby | ALL RIGHTS RESERVED
      </div>
    </div>
  </footer>
</ng-template>

<ng-template #logo>
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 710.38 437.39">
    <path
      d="M337.65,17.22a89.38,89.38,0,0,0-25.54,3A20.07,20.07,0,0,0,297.23,39.6v94c0,20.95,16.64,38.51,37.59,38.72a38,38,0,1,0-24.21-66.89V39.69a4.46,4.46,0,0,1,3.15-4.27,74.41,74.41,0,1,1-49.94,50.31,69.4,69.4,0,0,0,3-19.56V49.09a89.09,89.09,0,0,0-21,60.71c1.63,47.21,40.32,85.33,87.55,86.29a89.57,89.57,0,0,0,91.31-88.86C425,58.77,386.09,18.52,337.65,17.22Zm-2.44,92.48a24.6,24.6,0,1,1-24.6,24.6A24.63,24.63,0,0,1,335.21,109.7Z"
      fill="#00154a"/>
    <g>
      <path
        d="M185.75,376.22h14a17.84,17.84,0,0,1,4.85.62,11.23,11.23,0,0,1,3.86,1.83,8.66,8.66,0,0,1,2.56,3.11,9.93,9.93,0,0,1,.93,4.43,8.13,8.13,0,0,1-1.93,5.62,11.15,11.15,0,0,1-5,3.19v.12a8.34,8.34,0,0,1,3.4.93,9.32,9.32,0,0,1,2.76,2.14,10.36,10.36,0,0,1,2.52,6.86,10.06,10.06,0,0,1-1.1,4.78,10.31,10.31,0,0,1-3,3.47,14.17,14.17,0,0,1-4.49,2.15,19.77,19.77,0,0,1-5.5.73H185.75Zm5.45,16.6h7.43a12.89,12.89,0,0,0,2.9-.31,6.58,6.58,0,0,0,2.38-1,5.41,5.41,0,0,0,1.62-1.87,5.82,5.82,0,0,0,.59-2.77,5.67,5.67,0,0,0-6.35-5.87H191.2Zm0,18.64h8a16.58,16.58,0,0,0,2.75-.26,8.25,8.25,0,0,0,2.81-1,6.77,6.77,0,0,0,2.21-2.06,5.84,5.84,0,0,0,.91-3.44,6.29,6.29,0,0,0-2.33-5.34,10.15,10.15,0,0,0-6.29-1.78H191.2Z"
        fill="#ffad3d"/>
      <path d="M307.32,376.22h4.94l17.19,40h-6.36l-4-9.88H299.94l-4,9.88h-6.36Zm9.76,25.35-7.49-18.35h-.12l-7.6,18.35Z"
            fill="#ffad3d"/>
      <path
        d="M407.89,376.22h14a17.84,17.84,0,0,1,4.85.62,11.41,11.41,0,0,1,3.86,1.83,8.53,8.53,0,0,1,2.55,3.11,9.8,9.8,0,0,1,.94,4.43,8.13,8.13,0,0,1-1.93,5.62,11.21,11.21,0,0,1-5,3.19v.12a8.34,8.34,0,0,1,3.4.93,9.15,9.15,0,0,1,2.75,2.14,10.28,10.28,0,0,1,1.85,3.08,10.44,10.44,0,0,1,.68,3.78,10,10,0,0,1-1.11,4.78,10.28,10.28,0,0,1-3,3.47,14.17,14.17,0,0,1-4.49,2.15,19.77,19.77,0,0,1-5.5.73H407.89Zm5.44,16.6h7.44a12.89,12.89,0,0,0,2.9-.31,6.58,6.58,0,0,0,2.38-1,5.41,5.41,0,0,0,1.62-1.87,5.94,5.94,0,0,0,.59-2.77A5.67,5.67,0,0,0,421.9,381h-8.57Zm0,18.64h8a16.58,16.58,0,0,0,2.75-.26,8.25,8.25,0,0,0,2.81-1,6.77,6.77,0,0,0,2.21-2.06,5.84,5.84,0,0,0,.91-3.44,6.29,6.29,0,0,0-2.33-5.34,10.16,10.16,0,0,0-6.3-1.78h-8.06Z"
        fill="#ffad3d"/>
      <path d="M526.33,399.15l-15.21-22.93h7l10.95,17.56,11.29-17.56H547l-15.21,22.93V416.2h-5.45Z" fill="#ffad3d"/>
    </g>
    <g>
      <path
        d="M144.21,292.18a48.42,48.42,0,0,1,3.38-18.3A43.64,43.64,0,0,1,157,259.42,41.77,41.77,0,0,1,171.43,250a49,49,0,0,1,18.4-3.39A48.32,48.32,0,0,1,208.14,250a42.42,42.42,0,0,1,23.93,23.93,51.26,51.26,0,0,1,0,36.61,42.47,42.47,0,0,1-23.93,23.94,48.5,48.5,0,0,1-18.31,3.37,49.2,49.2,0,0,1-18.4-3.37A41.94,41.94,0,0,1,157,325a43.64,43.64,0,0,1-9.39-14.46A48.43,48.43,0,0,1,144.21,292.18Zm11.27,0a40.24,40.24,0,0,0,2.34,13.8,32.48,32.48,0,0,0,6.85,11.27,33.28,33.28,0,0,0,10.89,7.6,37.52,37.52,0,0,0,28.54,0,33.28,33.28,0,0,0,10.89-7.6A32.33,32.33,0,0,0,221.84,306a41.66,41.66,0,0,0,0-27.59A32.24,32.24,0,0,0,215,267.12a33.28,33.28,0,0,0-10.89-7.6,37.52,37.52,0,0,0-28.54,0,33.28,33.28,0,0,0-10.89,7.6,32.39,32.39,0,0,0-6.85,11.27A40.22,40.22,0,0,0,155.48,292.18Z"
        fill="#00154a"/>
      <path
        d="M255.73,292.18a48.41,48.41,0,0,1,3.37-18.3,43.79,43.79,0,0,1,9.39-14.46A41.81,41.81,0,0,1,283,250a48.93,48.93,0,0,1,18.4-3.39,48.23,48.23,0,0,1,18.3,3.39,42.47,42.47,0,0,1,23.94,23.93,51.4,51.4,0,0,1,0,36.61,42.53,42.53,0,0,1-23.94,23.94,48.41,48.41,0,0,1-18.3,3.37,49.12,49.12,0,0,1-18.4-3.37A42,42,0,0,1,268.49,325a43.79,43.79,0,0,1-9.39-14.46A48.43,48.43,0,0,1,255.73,292.18Zm11.26,0a40,40,0,0,0,2.35,13.8,32.18,32.18,0,0,0,6.85,11.27,33.07,33.07,0,0,0,10.89,7.6,37.49,37.49,0,0,0,28.53,0,33.07,33.07,0,0,0,10.89-7.6A32.18,32.18,0,0,0,333.35,306a41.66,41.66,0,0,0,0-27.59,32.09,32.09,0,0,0-6.85-11.27,33.07,33.07,0,0,0-10.89-7.6,37.49,37.49,0,0,0-28.53,0,33.07,33.07,0,0,0-10.89,7.6,32.09,32.09,0,0,0-6.85,11.27A40,40,0,0,0,267,292.18Z"
        fill="#00154a"/>
      <path
        d="M372.12,273.22q0-2.43-.19-5.91c-.12-2.31-.22-4.66-.28-7s-.16-4.6-.28-6.67-.19-3.65-.19-4.78h11.27q.18,4.88.28,9.39a29.35,29.35,0,0,0,.47,5.82h.56A30.38,30.38,0,0,1,395,251.73a32.85,32.85,0,0,1,18.4-5.17A29.41,29.41,0,0,1,429.19,251q7.14,4.41,10.33,14.36,4.13-9.76,12.3-14.27A35.05,35.05,0,0,1,469,246.56q10.14,0,16.52,3.2a26.67,26.67,0,0,1,10.05,8.35A31.31,31.31,0,0,1,500.62,270,67.9,67.9,0,0,1,502,283.92v51.63H490.77V284.49a54.68,54.68,0,0,0-.94-10.23,27.08,27.08,0,0,0-3.29-8.92,17.15,17.15,0,0,0-6.57-6.29q-4.22-2.34-11-2.35c-5.26,0-9.57,1-12.95,2.91a22.85,22.85,0,0,0-8,7.42,30.53,30.53,0,0,0-4.13,9.76,43.72,43.72,0,0,0-1.22,9.95v48.81H431.44V283.36q0-12-4.41-19.33t-13.61-7.33a32.18,32.18,0,0,0-11.73,2.16,25.18,25.18,0,0,0-9.57,6.57,31.67,31.67,0,0,0-6.39,11.08,47.34,47.34,0,0,0-2.34,15.67v43.37H372.12Z"
        fill="#00154a"/>
      <path
        d="M608.66,319.22a37.92,37.92,0,0,1-16.24,14.27,51.74,51.74,0,0,1-21.3,4.31,46.31,46.31,0,0,1-18-3.37A40.85,40.85,0,0,1,539.2,325a41.76,41.76,0,0,1-8.92-14.46A53,53,0,0,1,527.19,292a49.59,49.59,0,0,1,3.19-18,43.13,43.13,0,0,1,9-14.36,42.08,42.08,0,0,1,31.16-13.05A41.79,41.79,0,0,1,588.11,250a38.4,38.4,0,0,1,12.76,9.2,38.88,38.88,0,0,1,7.79,13.05,43.59,43.59,0,0,1,2.63,14.83V295H538.45a38.56,38.56,0,0,0,1.41,9.11,32.53,32.53,0,0,0,15.21,19.8q6.28,3.76,16.23,3.76A36.28,36.28,0,0,0,588,323.54a30.09,30.09,0,0,0,12.39-11.27ZM600,284.86A25.71,25.71,0,0,0,597.68,274a29.22,29.22,0,0,0-6.38-8.91,30.24,30.24,0,0,0-9.58-6.1A30.67,30.67,0,0,0,570,256.7q-9.19,0-15.3,3.76a33.49,33.49,0,0,0-9.67,8.63,34.42,34.42,0,0,0-5.07,9.48,25.26,25.26,0,0,0-1.5,6.29Z"
        fill="#00154a"/>
      <path
        d="M636.45,273.22q0-2.43-.19-5.91c-.12-2.31-.22-4.66-.28-7s-.16-4.6-.28-6.67-.19-3.65-.19-4.78h11.26c.13,3.25.22,6.38.29,9.39a29.63,29.63,0,0,0,.46,5.82,38.46,38.46,0,0,1,11.08-12.58q6.76-4.89,16.52-4.89a18.73,18.73,0,0,1,3.29.29c1.06.18,2.15.4,3.28.65l-1.31,11.08a13.65,13.65,0,0,0-4.32-.75,31.28,31.28,0,0,0-12.67,2.35,23.7,23.7,0,0,0-8.82,6.47,27.21,27.21,0,0,0-5.17,9.67,40.93,40.93,0,0,0-1.69,11.92v47.31H636.45Z"
        fill="#00154a"/>
      <path
        d="M119.55,273.88A42.42,42.42,0,0,0,95.62,250a48.32,48.32,0,0,0-18.31-3.39A49,49,0,0,0,58.91,250a41.77,41.77,0,0,0-14.45,9.47c-.53.54-1,1.13-1.5,1.69V193.62H31.74v97.46c0,.37,0,.73,0,1.1s0,.74,0,1.11v1.1l.05-.05a47.64,47.64,0,0,0,3.28,16.15A43.64,43.64,0,0,0,44.46,325a41.94,41.94,0,0,0,14.45,9.48,49.2,49.2,0,0,0,18.4,3.37,48.5,48.5,0,0,0,18.31-3.37,42.47,42.47,0,0,0,23.93-23.94,51.26,51.26,0,0,0,0-36.61ZM109.32,306a32.33,32.33,0,0,1-6.85,11.27,33.28,33.28,0,0,1-10.89,7.6,35,35,0,0,1-14.27,2.82A35,35,0,0,1,63,324.85a33.28,33.28,0,0,1-10.89-7.6A32.48,32.48,0,0,1,45.3,306a41.83,41.83,0,0,1,0-27.59,32.39,32.39,0,0,1,6.85-11.27A33.28,33.28,0,0,1,63,259.52a35,35,0,0,1,14.27-2.82,35,35,0,0,1,14.27,2.82,33.28,33.28,0,0,1,10.89,7.6,32.24,32.24,0,0,1,6.85,11.27,41.66,41.66,0,0,1,0,27.59Z"
        fill="#00154a"/>
    </g>
  </svg>
</ng-template>

@defer (on immediate) {
  <ng-container [ngTemplateOutlet]="getTemplate"></ng-container>
}
