<mat-card class="entry-card mat-card-transparent" *ngIf="!finished">
  <h1>Activate Account</h1>
  <form (ngSubmit)="onSubmit()" [formGroup]="activateForm">
    <mat-error>
      <span *ngIf="activateForm.errors?.serverError">
        {{ activateForm.errors?.serverError }}
      </span>
    </mat-error>

    <div>
      <mat-label>Your email</mat-label>
      <mat-form-field appearance="outline" color="accent">
        <input autocomplete="email" matInput formControlName="email" placeholder="Type your email">
        <mat-error *ngIf="activateForm.get('email').invalid">
          {{ getErrorMsg(activateForm.get('email')) }}
        </mat-error>
      </mat-form-field>
    </div>

    <div>
      <mat-label>Password</mat-label>
      <mat-form-field appearance="outline" color="accent">
        <input autocomplete="off" matInput type="password" formControlName="password" placeholder="Type your password">
        <mat-error *ngIf="activateForm.get('password').invalid">
          {{ getErrorMsg(activateForm.get('password')) }}
        </mat-error>
      </mat-form-field>
    </div>

    <div>
      <mat-label>Repeat Password</mat-label>
      <mat-form-field appearance="outline" color="accent">
        <input autocomplete="off" matInput type="password" formControlName="repeatPassword" placeholder="Repeat your password">
        <mat-error *ngIf="activateForm.get('repeatPassword').invalid">
          {{ getErrorMsg(activateForm.get('repeatPassword')) }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="text-center bottom-margin">
      <button mat-flat-button color="primary">
        <mat-spinner *ngIf="loading" [diameter]="37"></mat-spinner>
        <span *ngIf="!loading">Activate</span>
      </button>
    </div>
  </form>
</mat-card>
