import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FilterType} from './filter-menu/filter-menu.component';
import BoomerDataSource from './BoomerDataSource';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {BehaviorSubject, combineLatest, Subscription} from 'rxjs';
import * as moment from 'moment';
import {SelectionChange, SelectionModel} from '@angular/cdk/collections';
import {AuthRoles} from '../../../auth/auth.roles';


type NestedKeyOf<ObjectType extends object> =
  {
    [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
    ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
    : `${Key}`
  }[keyof ObjectType & (string | number)];

export type ColumnDef<T extends object, ClickEvent extends string = undefined> = {
  name: string;
  displayFn?: (row: T) => string;
  isSortable?: boolean;
  isFilterable?: boolean;
} & (
  ({ key: NestedKeyOf<T>; } & ({
    type: 'text' | 'date' | 'agent' | 'boomer_user' | 'exact_datetime' | 'number';
  } | {
    type: 'select' | 'multi-select';
    options: Array<{ value: string, label: string }>;
  })) |
  {
    type: 'compound',
    columns: Array<ColumnDef<T, ClickEvent>>,
    displayFn: (row: T) => string;
  }
  ) &
  (
    { link?: (row: T) => string; } |
    { clickEvent: ClickEvent }
    );

type FilterEvent = Map<string, FilterType>;

export type Action<T> = {
  name: string;
  icon: string;
  action: (row: T) => void;
  disabled?: (row: T) => boolean;
};

@Component({
  selector: 'app-boomer-table',
  templateUrl: './boomer-table.component.html',
  styleUrls: ['./boomer-table.component.scss']
})
export class BoomerTableComponent<T extends object, ClickEvents extends string> implements AfterViewInit, OnInit, OnDestroy {
  protected readonly AuthRoles = AuthRoles;

  @Input()
  rowColorProvider: (row: T) => string = () => '';

  @Input({required: true})
  dataSource: BoomerDataSource<T>;

  @Input()
  actions: Array<Action<T>>;

  @Input({required: true})
  columns: Array<ColumnDef<T, ClickEvents>>;
  displayedColumns: string[];

  @Input()
  allowSelection: boolean;

  @Input()
  pageSize = 25;

  @Output()
  selectionChange = new EventEmitter<SelectionChange<T>>();

  @Output()
  clickEvent = new EventEmitter<{ event: ClickEvents, row: T }>();

  private pageSubject: BehaviorSubject<PageEvent>;

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  private sortSubject = new BehaviorSubject<Sort>({active: '', direction: ''});
  @ViewChild(MatSort)
  sort: MatSort;

  selection = new SelectionModel<T>(true, []);

  private filtersSubject = new BehaviorSubject<FilterEvent>(new Map<string, FilterType>());
  filters: Map<string, FilterType> = new Map();

  private subscription = new Subscription();

  ngOnInit() {
    this.pageSubject = new BehaviorSubject<PageEvent>({pageIndex: 0, pageSize: this.pageSize, length: 0});
    this.displayedColumns = this.columns
      .map(column =>
        column.type === 'compound'
          ? this.compoundColumnId(column)
          : column.key
      );

    if (this.allowSelection) {
      this.displayedColumns = ['select', ...this.displayedColumns];
    }

    if (this.actions) {
      this.displayedColumns = [...this.displayedColumns, 'actions'];
    }

    if (this.selectionChange) {
      this.subscription.add(
        this.selection.changed.subscribe(event =>
          this.selectionChange.emit(event)
        )
      );
    }
  }

  ngAfterViewInit(): void {
    this.subscription.add(this.sort.sortChange.subscribe(this.sortSubject));
    this.subscription.add(this.paginator.page.subscribe(this.pageSubject));
    this.subscription.add(
      combineLatest([this.sortSubject, this.filtersSubject, this.pageSubject])
        .subscribe(([sort, filters, page]) => {
            this.dataSource.onChange(sort, filters, page);
          }
        )
    );
    this.subscription.add(
      this.dataSource.totalCountSubject.subscribe(totalCount => {
        this.paginator.length = totalCount;
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  filter(key: string, $event: FilterType | null) {
    if ($event === null) {
      this.filters.delete(key);
    } else {
      this.filters.set(key, $event);
    }
    this.filtersSubject.next(this.filters);
  }

  resolveCellValue(column: ColumnDef<T, ClickEvents>, row: T) {
    if (column.type === 'compound') {
      throw new Error('Compound columns are not supported');
    }

    if (column.key.includes('.')) {
      const keys = column.key.split('.');
      return keys.reduce((acc, curr) => acc ? acc[curr] : undefined, row) ?? '-';
    }
    return row[column.key as string];
  }

  displayColumn(column: ColumnDef<T, ClickEvents>, row: T) {
    if (column.displayFn) {
      return column.displayFn(row);
    }

    const value = this.resolveCellValue(column, row);

    switch (column.type) {
      case 'select':
      case 'multi-select':
        const option =
          column.options.find(option => option.value === String(value));
        return option?.label ?? `INVALID VALUE: ${value}`;
      case 'number':
      case 'text':
        return value;
      case 'date':
        return value ? moment(value).format('L') : '';
      case 'exact_datetime':
        return value ? moment(value).format('L h:mm:ss.SSS A') : '';
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.dataSubject.value.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.dataSubject.value.forEach(row => this.selection.select(row));
  }

  onClick(column: ColumnDef<T, ClickEvents>, row: T) {
    if (!('clickEvent' in column)) {
      return;
    }

    this.clickEvent.emit({event: column.clickEvent, row});
  }

  compoundColumnId(column: ColumnDef<T, ClickEvents>) {
    if (column.type !== 'compound') {
      throw new Error('Column is not compound');
    }
    const name = column.columns.map((c) => {
      if (c.type === 'compound') {
        throw new Error('Deep compound columns are not supported');
      }

      return c.key;
    })
      .join('_');
    return name;
  }
}
