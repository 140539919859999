import {Component, forwardRef, Input} from '@angular/core';
import {AbstractControl, ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DateFilterFn} from '@angular/material/datepicker';

@Component({
  selector: 'app-boomer-date-input[form]',
  templateUrl: './boomer-date-input.component.html',
  styleUrls: ['../boomer-text-input/boomer-text-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(
        () => BoomerDateInputComponent
      ),
      multi: true
    }
  ]
})
export class BoomerDateInputComponent implements ControlValueAccessor {

  @Input()
  form: UntypedFormGroup;

  @Input()
  label: string;

  @Input()
  filter: DateFilterFn<any>;

  get required() {
    if (this.formControl && this.formControl.validator) {
      const validator = this.formControl.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }

    return false;
  }

  @Input()
  formControlName: string;

  get formControl(): UntypedFormControl {
    return this.form.get(this.formControlName) as UntypedFormControl;
  }

  public value: Date;

  public changed: (value: Date) => void;

  public touched: () => void;

  public isDisabled: boolean;

  constructor() {
  }

  public writeValue(value: Date): void {
    this.value = value;
  }

  public onChange(event: Event): void {
    const value = new Date((event.target as HTMLInputElement).value);

    this.changed(value);
  }

  public registerOnChange(fn: any): void {
    this.changed = fn;
  }

  public registerOnTouched(fn: any): void {
    this.touched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

}
