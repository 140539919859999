<mat-card class="entry-card mat-card-transparent" *ngIf="(invitationFlow$ | async) as invitation">
  <h1>Create account</h1>
  <mat-card-subtitle class="text-center">
    <div>Welcome {{invitation.firstName}}!</div>
    <div>
      You has just been added as a Boomer employee.
    </div>
    <div>
      Choose a password and click "Sign Up" to continue.
    </div>
  </mat-card-subtitle>

  <mat-card-content>
    <form (ngSubmit)="onSubmit()" #formElement="ngForm" [formGroup]="invitationForm">
      <mat-error >
        <p>
              <span *ngIf="invitationForm.errors?.serverError">
                {{ invitationForm.errors?.serverError }}
              </span>
        </p><br/>
      </mat-error>
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Password</mat-label>
        <input autocomplete="new-password" matInput type="password" formControlName="password" placeholder="Type your password">
        <mat-error *ngIf="invitationForm.get('password').invalid">
          {{ getErrorMsg(invitationForm.get('password')) }}
        </mat-error>
      </mat-form-field>
      <p>
        <mat-form-field appearance="outline" color="accent">
          <mat-label>Confirm Password</mat-label>
          <input autocomplete="new-password" matInput type="password" formControlName="confirmPassword" placeholder="Confirm your password">
          <mat-error *ngIf="invitationForm.get('confirmPassword').invalid">
            {{ getErrorMsg(invitationForm.get('confirmPassword')) }}
          </mat-error>
        </mat-form-field>
      </p>
      <p>
        <mat-checkbox formControlName="termsOfServiceAccepted" [ngClass]="{'mat-error': invitationForm.get('termsOfServiceAccepted').errors?.required && formElement.submitted }">
          <mat-label>I agree to the <a target="_blank" [routerLink]="['/terms-of-service']">Terms of Service</a></mat-label>
        </mat-checkbox>
      </p>
      <p>
        <mat-checkbox formControlName="privacyPolicyAccepted" [ngClass]="{'mat-error': invitationForm.get('privacyPolicyAccepted').errors?.required && formElement.submitted }">
          <mat-label>I agree to the <a target="_blank" [routerLink]="['/privacy-policy']">Privacy Policy</a></mat-label>
        </mat-checkbox>
      </p>
      <br/>
      <button mat-flat-button color="primary">
        <mat-spinner *ngIf="loading" [diameter]="37"></mat-spinner>
        <span *ngIf="!loading">Sign up</span>
      </button>
    </form>
  </mat-card-content>
</mat-card>
