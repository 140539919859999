<mat-card class="entry-card mat-card-transparent" *ngIf="referralCompanyName$ | async as company">
  <h1 class="text-center">Welcome!</h1>
  <mat-card-subtitle>You've been invited by {{company?.tokenOwnerName}} to join BoomerBaby!</mat-card-subtitle>

  <mat-card-content>
    <div class="bottom-margin text-center">
      <button [routerLink]="['/applicant-user-registration/generic', referralToken]" mat-flat-button color="primary">
        Register
      </button>
    </div>
    <div>
      Or if you already have an account you can <a [routerLink]="['/login']">Sign in</a>!
    </div>
  </mat-card-content>
</mat-card>
