<mat-card class="entry-card mat-card-transparent">
  <h1>Complete invitation</h1>
  <form (ngSubmit)="onSubmit()" [formGroup]="invitationForm">
    <mat-error>
      <span *ngIf="invitationForm.errors?.serverError">
        {{ invitationForm.errors?.serverError }}
      </span>
    </mat-error>

    <div class="mat-inline-form-fields">
      <div>
        <mat-label>First name</mat-label>
        <mat-form-field appearance="outline" color="accent">
          <input autocomplete="name" matInput formControlName="firstName" placeholder="Type your first name">
          <mat-error *ngIf="invitationForm.get('firstName').invalid">
            {{ getErrorMsg(invitationForm.get('firstName')) }}
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-label>Last name</mat-label>
        <mat-form-field appearance="outline" color="accent">
          <input autocomplete="family-name" matInput formControlName="lastName" placeholder="Type your last name">
          <mat-error *ngIf="invitationForm.get('lastName').invalid">
            {{ getErrorMsg(invitationForm.get('lastName')) }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div>
      <mat-label>Phone number</mat-label>
      <mat-form-field appearance="outline" color="accent">
        <input autocomplete="tel" matInput type="tel" formControlName="phoneNumber" placeholder="Type your phone number">
        <mat-error *ngIf="invitationForm.get('phoneNumber').invalid">
          {{ getErrorMsg(invitationForm.get('phoneNumber')) }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="mat-inline-form-fields">
      <div>
        <mat-label>Password</mat-label>
        <mat-form-field appearance="outline" color="accent">
          <input autocomplete="new-password" matInput type="password" formControlName="password" placeholder="Type your password">
          <mat-error *ngIf="invitationForm.get('password').invalid">
            {{ getErrorMsg(invitationForm.get('password')) }}
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-label>Confirm password</mat-label>
        <mat-form-field appearance="outline" color="accent">
          <input autocomplete="new-password" matInput type="password" formControlName="confirmPassword" placeholder="Confirm your password">
          <mat-error *ngIf="invitationForm.get('confirmPassword').invalid">
            {{ getErrorMsg(invitationForm.get('confirmPassword')) }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="text-center">
      <button mat-flat-button color="primary">
        <mat-spinner *ngIf="loading" [diameter]="37"></mat-spinner>
        <span *ngIf="!loading">Submit</span>
      </button>
    </div>
  </form>
</mat-card>
