<h2>{{label}} Address</h2>
<div [formGroup]="form" class="grid grid-cols-1 grid-rows-4 gap-4 @2xl:grid-cols-4 @2xl:grid-rows-2">
  <app-boomer-text-input [form]="form" formControlName="addressLine1"
                         [label]="label + ' Address Line 1 (No PO Box)'"
                         class="col-span-1 @2xl:col-span-2"></app-boomer-text-input>
  <app-boomer-text-input [form]="form" formControlName="addressLine2"
                         [label]="label + ' Address Line 2'"
                         class="col-span-1 @2xl:col-span-2"></app-boomer-text-input>
  <app-boomer-text-input [form]="form" formControlName="city"
                         label="City"
                         class="col-span-1 @2xl:col-span-1"></app-boomer-text-input>
  <app-boomer-text-input [form]="form" formControlName="zipCode" label="Zip"
                         class="col-span-1 @2xl:col-span-1"></app-boomer-text-input>
  <div class="col-span-1 @2xl:col-span-1">
    <input type="hidden" formControlName="fipsCode">
    <ng-container *ngIf="!(loadingCountiesAndStatesEmitter$ | async)">
          <span *ngIf="counties.length === 0 || counties.length === 1">
            <app-boomer-text-input [form]="form" formControlName="countyName"
                                   label="County" readonly></app-boomer-text-input>
          </span>
      <span *ngIf="counties.length > 1">
            <mat-form-field appearance="outline">
              <mat-label class="required" [class.disabled]="form.disabled">County</mat-label>
              <mat-select placeholder="Please Select" formControlName="countyName">
                <mat-option *ngFor="let county of counties" [value]="county.countyName">
                  {{county.countyName}}
                </mat-option>
              </mat-select>
              <mat-hint>Please select your county from the list above</mat-hint>
            </mat-form-field>
          </span>
    </ng-container>
    <mat-progress-bar *ngIf="loadingCountiesAndStatesEmitter$ | async" mode="indeterminate"></mat-progress-bar>
  </div>
  <div class="col-span-1 @2xl:col-span-1">
    <ng-container *ngIf="!(loadingCountiesAndStatesEmitter$ | async)">
          <span *ngIf="states.length === 0 || states.length === 1">
            <app-boomer-text-input [form]="form" formControlName="state"
                                   label="State" readonly></app-boomer-text-input>
          </span>
      <span *ngIf="states.length > 1">
            <mat-form-field appearance="outline">
              <mat-label class="required" [class.disabled]="form.disabled">State</mat-label>
              <mat-select placeholder="Please Select" formControlName="state">
                <mat-option *ngFor="let state of states" [value]="state.stateName">
                  {{state.stateName}}
                </mat-option>
              </mat-select>
              <mat-hint>Please select your county state the list above</mat-hint>
            </mat-form-field>
          </span>
    </ng-container>
    <mat-progress-bar *ngIf="loadingCountiesAndStatesEmitter$ | async" mode="indeterminate"></mat-progress-bar>
  </div>
</div>
